import { moyoOnlyApi } from '../../../common/http_util';
import { ApiResponse } from '../../../entity/api';
import { GetMoyoOnlyPlanTypes } from '../../../layout/moyoonly-plan/type';
import { ToMoyoOnlyPlanTypes } from '../../../layout/moyoonly/type';
import { getMoyoOnlyTypes } from '../../../service/moyo-only';

export const getMoyoOnlyList = async ({
  page,
  size,
}: {
  page: number;
  size: number;
}) => {
  return await moyoOnlyApi.get<getMoyoOnlyTypes>(
    `/request/list?page=${page}&size=${size}`,
  );
};

export const getMoyoOnlyPlanInfo = async ({
  requestId,
}: {
  requestId: number;
}) => {
  return await moyoOnlyApi.get<ApiResponse<GetMoyoOnlyPlanTypes>>(
    `/request/${requestId}`,
  );
};

export const modifyMoyoOnlyPlan = async ({
  requestId,
  planMetaJson,
}: {
  requestId: number;
  planMetaJson: string;
}) => {
  return await moyoOnlyApi.put(`/request/${requestId}`, { planMetaJson });
};

export const createMoyoOnlyPlan = async ({
  requestId,
}: {
  requestId: number;
}) => {
  return await moyoOnlyApi.post(`/create/${requestId}`);
};

export const changeMoyoOnlyPlan = async ({
  requestId,
  planId,
  data,
}: {
  requestId: number;
  planId: number;
  data: ToMoyoOnlyPlanTypes;
}) => {
  return await moyoOnlyApi.post(`/create/${requestId}`, { ...data });
};
