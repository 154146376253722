import { EventParameterType, eventParameterTypeBySubCategories } from '../../../entity/settlement';
import { SettlementSubRoutes } from '../routes';

export function useEventParameter(subCategory: SettlementSubRoutes) {
  const { type, key } = eventParameterTypeBySubCategories[subCategory];

  if (type === EventParameterType.eventType) {
    return {
      eventType: key
    }
  } else {
    return {
      eventName: key
    }
  }
}
