import React, { useEffect, useState } from 'react';

import SeoKeywordSection from './SeoKeywordSection';
import SeoUrlReport from './SeoUrlReport';

const SeoKeyword = () => {
  return (
    <div>
      <div className="mt-3 px-4">
        <div className="pb-16">
          <SeoKeywordSection />
        </div>
        <SeoUrlReport />
      </div>
    </div>
  );
};

export default SeoKeyword;
