import { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import apiService from '../../service/api.service';

export default function AddDetailsPopup({ event, show, onHide, onSaved }) {
  const descInput = useRef(null);
  const conditionInput = useRef(null);
  const optionValue = useRef(null);
  const receiptDateValue = useRef(null);
  const [useLink, setUseLink] = useState(true);

  const onClickAdd = async () => {
    const description = descInput?.current?.value;
    const selectValue = optionValue?.current?.value;
    let condition = conditionInput?.current?.value;
    let receiptDate = receiptDateValue?.current?.value;

    if (!description) {
      return;
    }

    if (selectValue === '') {
      alert('카테고리 선택해주세요');
      return;
    }

    if (condition === '') {
      condition = null;
    }

    if (receiptDate === '') {
      receiptDate = null;
    }

    var order = 0;

    if (selectValue === 'gift') {
      order = 10;
    }

    var param = {
      description: description,
      condition: condition,
      category: selectValue,
      receiptDate: receiptDate,
      useLink: useLink,
      order: order,
    };

    try {
      const response = await apiService.addEventDetail(event.id, param);
      if (response.data.result) {
        onSaved();
      }
    } catch (err) {
      console.debug(err);
    }
  };

  return (
    <>
      <h4>이벤트 상품 추가</h4>
      <div className="row">
        <div className="col-8">
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Control
                    ref={descInput}
                    placeholder="상품 설명을 입력하세요"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Select ref={conditionInput}>
                    <option value={''}>선택(받는 조건)</option>
                    <option value={'register'}>개통 완료 시</option>
                    <option value={'onchip_register'}>원칩으로 개통 시</option>
                    <option value={'baro_delivery_usim_register'}>
                      바로배송유심으로 개통 시
                    </option>
                    <option value={'baro_usim_register'}>
                      바로유심으로 개통 시
                    </option>
                    <option value={'etc'}>기타</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Select ref={optionValue}>
                    <option value={''}>선택(카테고리)</option>
                    <option value={'emart'}>이마트</option>
                    <option value={'naver'}>네이버</option>
                    <option value={'ssg'}>신세계</option>
                    <option value={'gs'}>GS</option>
                    <option value={'youtube'}>유튜브</option>
                    <option value={'cu'}>cu</option>
                    <option value={'genie'}>지니</option>
                    <option value={'starbucks'}>스타벅스</option>
                    <option value={'homeplus'}>홈플러스</option>
                    <option value={'melon'}>멜론</option>
                    <option value={'gift'}>기타</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Control
                    ref={receiptDateValue}
                    placeholder="수령일 ex ) 6월 수령"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Check
                  type={'switch'}
                  defaultChecked={useLink}
                  label={'이벤트 링크 사용'}
                />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="col-3">
          <Button variant="primary" onClick={onClickAdd}>
            사은품 추가
          </Button>
        </div>
      </div>
    </>
  );
}
