import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import apiService from '../../service/api.service';

const bannerColumns = [
  {
    name: 'id',
    selector: (row) => row.id,
    sortable: true,
    sortField: 'id',
    maxWidth: '60px',
  },
  {
    name: '제목',
    selector: (row) => row.title,
    sortable: true,
    sortField: 'title',
    maxWidth: '200px',
  },
  {
    name: '상세설명',
    selector: (row) => row.description,
    sortable: true,
    sortField: 'description',
    maxWidth: '200px',
  },
  {
    name: '캠페인',
    selector: (row) => row.campaign,
    sortable: true,
    sortField: 'campaign',
    maxWidth: '200px',
  },
  {
    name: 'PC 배너',
    selector: (row) => row.pcImgUrl,
    cell: (row, index, column, id) => (
      <img
        src={row.pcImgUrl}
        style={{ maxWidth: '200px', maxHeight: '60px' }}
      />
    ),
    maxWidth: '250px',
  },
  {
    name: '모바일 배너',
    selector: (row) => row.mobileImgUrl,
    cell: (row, index, column, id) => (
      <img
        src={row.mobileImgUrl}
        style={{ maxWidth: '200px', maxHeight: '60px' }}
      />
    ),
    maxWidth: '250px',
  },
  {
    name: '액션',
    selector: (row) => row.action,
    sortable: true,
    sortField: 'action',
  },
];

export default function BannerList() {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);

  const fetchBanners = async () => {
    try {
      const res = await apiService.getAdBanners();
      if (res && res.data) {
        setBanners(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const onRowClick = (row, event) => {
    navigate(`/ads/banners/${row.id}`);
  };

  const createButton = (
    <Button size="sm" onClick={() => navigate('/ads/banners/add')}>
      배너 만들기
    </Button>
  );

  return (
    <>
      <DataTable
        title="배너"
        columns={bannerColumns}
        data={banners}
        pagination
        sortable
        onRowClicked={onRowClick}
        actions={[createButton]}
      />
    </>
  );
}
