import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MailStorage, { MailStorageData } from './MailStorage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MailStorageDialogProps {
  open?: boolean;
  onLoad?: (data: MailStorageData) => void;
  onClose?: () => void;
}

const MailStorageDialog: FC<MailStorageDialogProps> = (({
  open = false,
  onLoad,
  onClose,
}) => {
  const [mailList, setMailList] = useState<MailStorageData[]>(MailStorage.getAll());

  useEffect(() => {
    if(!open) return;
    setMailList(MailStorage.getAll())
  }, [open]);

  const onClickDelete = (index: number) => {
    const newMailList = [...mailList.slice(0, index), ...mailList.slice(index + 1)];
    MailStorage.update(newMailList);
    setMailList(newMailList);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          저장된 메일
        </Box>
      </DialogTitle>
      <DialogContent>
        {
          mailList.map((mail, index) => (
            <Accordion key={mail.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {mail.title}
              </AccordionSummary>
              <AccordionDetails>
                {
                  mail.tracking && (
                    <Box>
                      <Grid container gap={1} marginBottom={1}>
                        <Grid container gap={1}>
                          <TextField disabled label='utm_medium' size='small' value={mail.utmMedium} />
                          <TextField disabled label='utm_source' size='small' value={mail.utmSource} />
                          <TextField disabled label='utm_description' size='small' value={mail.utmDescription} />
                        </Grid>
                        <Grid container gap={1}>
                          <TextField disabled label='utm_campaign' size='small' value={mail.utmCampaign} />
                          <TextField disabled label='utm_content' size='small' value={mail.utmContent} />
                          <TextField disabled label='utm_term' size='small' value={mail.utmTerm} />
                        </Grid>
                      </Grid>
                    </Box>
                  )
                }
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="8px"
                  alignItems="flex-end"
                  gap={2}
                >
                  <Grid container gap={1}>
                    <TextField
                      disabled
                      value={mail.sender}
                      placeholder='발신자'
                      label='발신자'
                      fullWidth
                    />
                  </Grid>
                </Box>
                <Card>
                  <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: mail.content || '' }}></div>
                  </CardContent>
                </Card>
              </AccordionDetails>
              <AccordionActions>
                <Button onClick={() => onClickDelete(index)} variant='outlined' color='error'>삭제</Button>
                <Button onClick={() => onLoad?.(mail)} variant='outlined'>적용</Button>
              </AccordionActions>
            </Accordion>
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  )
});

export default MailStorageDialog;
