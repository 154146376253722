import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid"
import { FC, useEffect, useState } from "react"
import { NpsResult, NpsTemplateItemType, SurveyTemplate } from "../../../service/npsService"

const DATA_GRID_SX = {
  mt: 3,
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
};

interface GridData {
  id: number;
  [key: string]: string | number;
}

const NpsCalculateTable: FC<{ result?: NpsResult }> = (({ result }) => {
  const [colums, setColums] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<GridData>>([]);

  useEffect(() => {
    if (!result) return;
    const npsMap: Record<string, number[]> = {};
    result.surveys.forEach((survey) => {
      if (!survey.contentMeta) return;
      const surveyResult = JSON.parse(survey.contentMeta) as SurveyTemplate;
      surveyResult.content.forEach((item) => {
        if (item.type !== NpsTemplateItemType.LINEAR) return;
        if (!npsMap.hasOwnProperty(item.template.title)) {
          Object.assign(npsMap, { [item.template.title]: [] });
        }
        npsMap[item.template.title].push(Number(item.template.value ?? 0));
      })
    })

    generateColumns(Object.keys(npsMap));
    generateDatas(npsMap)
    console.log(npsMap);
  }, [result]);

  const generateColumns = (titles: string[]) => {
    const _columns = titles.map((title): GridColDef => {
      return {
        field: title,
        headerName: title,
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      }
    });

    setColums([{
      field: 'name',
      headerName: 'name',
      headerAlign: 'center',
      align: 'center',
      width: 120,
    }, ..._columns]);
  }

  const generateDatas = (npsMap: Record<string, number[]>) => {
    const npsScoreData = { id: 1, name: 'nps'};
    const promotersData = { id: 2, name: 'promoters' };
    const passivesData = { id: 3, name: 'passives' };
    const detractorsData = { id: 4, name: 'detractors' };
    Object.entries(npsMap).forEach(([title, npsValues], index) => {
      const nps = calculateNps(npsValues);
      Object.assign(npsScoreData, {
        [title]: nps.npsScore,
      });
      Object.assign(promotersData, {
        [title]: nps.promoters,
      });
      Object.assign(passivesData, {
        [title]: nps.passives,
      });
      Object.assign(detractorsData, {
        [title]: nps.detractors,
      });
    })

    setData([
      npsScoreData,
      promotersData,
      passivesData,
      detractorsData,
    ]);
  }

  const calculateNps = (npsList: number[]) => {
    let promoters = 0;
    let passives = 0;
    let detractors = 0;

    npsList.forEach((value) => {
      if (9 <= value) {
        promoters += 1;
      } else if (7 <= value && value < 9) {
        passives += 1;
      } else if (value < 7) {
        detractors += 1
      }
    });

    const npsScore = (promoters / npsList.length) - (detractors / npsList.length);

    return {
      npsScore,
      promoters,
      passives,
      detractors,
    };
  }
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>NPS 점수</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          hideFooterPagination
          sx={DATA_GRID_SX}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          columns={colums}
          rows={data}
        />
      </AccordionDetails>
    </Accordion>
  )
});

export default NpsCalculateTable;
