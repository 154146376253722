import {
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { commaizeNumber } from '../../common/number-utils';
import moment from 'moment';
import { eventTypeLabelByType } from '../../entity/settlement';

export const showFallback = ({ value }: GridValueGetterParams) => {
  return value ?? '-';
};
export const formatNumber = ({ value }: GridValueFormatterParams<string>) =>
  commaizeNumber(value ?? 0);

export const formatDate = ({ value }: GridValueFormatterParams<number[]>) =>
  moment(value.slice(0, 3).join('-')).format('yyyy/MM/DD');

export const formatPhoneNumber = ({
  value,
}: GridValueFormatterParams<string>) =>
  value?.replace(/\+82 /g, '0').replace(/-/g, '') ?? '-';

export const formatEventType = ({ value }: GridValueFormatterParams<string>) =>
  eventTypeLabelByType[value];

const labelByPaymentType = {
  moyo: '모요 지급',
  partner: '파트너 지급',
  mix: 'MIX 지급',
};

export const formatPaymentType = ({
  value,
}: GridValueFormatterParams<keyof typeof labelByPaymentType>) =>
  labelByPaymentType[value];

const labelByRewardType = {
  activation: '간편 신청시 지급',
  without_activation: '간편신청 무관 지급 (회원가입은 해야 함)',
  draw: '추첨 지급',
};

export const formatRewardType = ({
  value,
}: GridValueFormatterParams<keyof typeof labelByRewardType>) =>
  labelByRewardType[value];

const labelByRewardItem = {
  gift: '기프티콘',
  cash: '현금',
  goods: '물품이나 상품',
};

export const formatRewardItem = ({
  value,
}: GridValueFormatterParams<keyof typeof labelByRewardItem>) =>
  labelByRewardItem[value];

const labelByGetType = {
  inviter: '초대함',
  invitee: '초대받음',
  mix: 'MIX',
};

export const formatGetType = ({
  value,
}: GridValueFormatterParams<keyof typeof labelByGetType>) =>
  labelByGetType[value];
