import { TextField } from '@mui/material';
import { FC } from 'react';

interface NameFilterProps {
  searchName: string;
  setSearchName: React.Dispatch<React.SetStateAction<string>>;
}

export const NameFilter: FC<NameFilterProps> = ({
  searchName,
  setSearchName,
}) => {
  const handleChange = (value: string) => {
    setSearchName(value);
  };
  return (
    <TextField
      label="요금제 검색"
      fullWidth
      onChange={(event) => handleChange(event.target.value)}
      value={searchName}
    />
  );
};
