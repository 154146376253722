import qs from 'query-string';

import api, {
  adminApi,
  authApi,
  downloadApi,
  internalAdminApi,
} from '../common/http_util';

class ApiService {
  login(data) {
    return authApi.post('/user/login', data);
  }

  getUsers(page, perPage, sortField, direction, searchText) {
    let sortQuery = '';
    if (sortField && direction) {
      sortQuery = `&sort=${sortField},${direction}`;
    }

    let searchQuery = '';
    if (searchText) {
      searchQuery = `&q=${searchText}`;
    }

    return api.get(
      `users?page=${page}&size=${perPage}${sortQuery}${searchQuery}`,
    );
  }

  getPlanById(planId) {
    return internalAdminApi.get(`mobile-plan-meta/${planId}`);
  }

  getPlanDetailById(planId) {
    return internalAdminApi.get(
      `mobile-plan-meta/detail/${planId}?isCorporate=false`,
    );
  }

  getPlanHistoryListById(planId) {
    return internalAdminApi.get(`mobile-plan-meta/${planId}/history`);
  }

  getPlanReserveEditHistoryById(planId) {
    return internalAdminApi.get(`mobile-plan-meta/${planId}/reserve`);
  }

  deletePlanReserveEdit(planReservedEditId) {
    return internalAdminApi.delete(
      `mobile-plan-meta/reserve/${planReservedEditId}`,
    );
  }

  editPlanById(planId, data) {
    return internalAdminApi.put(`/mobile-plan-meta/${planId}`, data);
  }

  editPlanDetailById(planId, data) {
    return internalAdminApi.put(`/mobile-plan-meta/detail/${planId}`, data);
  }

  reserveEditPlanById(planId, data) {
    return internalAdminApi.put(
      `/mobile-plan-meta/${planId}/reserve-edit`,
      data,
    );
  }

  deletePlan(planId) {
    return internalAdminApi.delete(`/mobile-plan-meta/${planId}`);
  }

  getPlanReserveDeleteDate(planId) {
    return internalAdminApi.get(`/mobile-plan-meta/${planId}/reserve-delete`);
  }

  restorePlan(planId) {
    return internalAdminApi.put(`/mobile-plan-meta/${planId}/restore`);
  }

  reservePlanDelete(planId, applyDate) {
    const year = applyDate.getFullYear();
    const month = String(applyDate.getMonth() + 1).padStart(2, '0');
    const date = String(applyDate.getDate()).padStart(2, '0');

    return internalAdminApi.post(
      `/mobile-plan-meta/${planId}/reserve-delete?applyDate=${year}-${month}-${date}T`,
    );
  }

  cancelReservePlanDelete(planId) {
    return internalAdminApi.delete(
      `/mobile-plan-meta/${planId}/reserve-delete`,
    );
  }

  getPlans() {
    return api.get('mobile-plan-meta/summary');
  }

  getPlansByMvno(mvno, searchText, isDeleted) {
    let url = `mobile-plan-meta/v2/list?mvno=${mvno}&q=${searchText}`;

    if (isDeleted) {
      url += `&isDeleted=${isDeleted}`;
    }

    return internalAdminApi.get(url);
  }

  getPlansByMnoAndMvno(mvno, mno, searchText, isDeleted) {
    var url = `mobile-plan-meta/v2/list?q=${searchText}`;

    if (mno !== '' && mno !== 'null' && mno !== undefined) {
      url += `&mno=${mno}`;
    }

    if (mvno !== '' && mvno !== 'null' && mvno !== undefined) {
      url += `&mvno=${mvno}`;
    }

    if (isDeleted != null) {
      url += `&isDeleted=${isDeleted}`;
    }

    console.log(url);

    return internalAdminApi.get(url);
  }

  getPlansGroupByMvno(mvno) {
    return internalAdminApi.get(`mobile-plan-meta/list/group?mvno=${mvno}`);
  }

  createPlansGroup(data) {
    return internalAdminApi.post(`mobile-plan-meta/list/group`, data);
  }

  getEvents(data) {
    if (!data.mvno) return null;
    return api.get(`event/summary?mvno=${encodeURIComponent(data.mvno)}`);
  }

  getEventList(data) {
    return internalAdminApi.get(`/event/v2/list?${qs.stringify(data)}`);
  }

  getReservedEvent(reservedEventId) {
    return internalAdminApi.get(`/event/list/reserved/${reservedEventId}`);
  }

  getReservedEvents() {
    return internalAdminApi.get(`/event/list/reserved`);
  }

  removeReservedEvent(reservedEventId) {
    return internalAdminApi.delete(`/event/reserved/${reservedEventId}`);
  }

  getEvent(eventId) {
    return internalAdminApi.get(`/event/${eventId}`);
  }

  editEvent(eventId, data) {
    return internalAdminApi.put(`/event/${eventId}`, data);
  }

  deleteEvent(eventId) {
    return internalAdminApi.delete(`/event/${eventId}`);
  }

  deleteEventRelation(planId, detailId) {
    return internalAdminApi.delete(
      `/event/relations/${planId}?detailId=${detailId}`,
    );
  }

  createEvent(data) {
    return internalAdminApi.post(`/event`, data);
  }

  reserveAddEvent(data, effectiveAt) {
    return internalAdminApi.post(
      `/event/reserve?effectiveAt=${effectiveAt}`,
      data,
    );
  }

  getCustomEventList(data) {
    return internalAdminApi.get(`/event/custom/list?${qs.stringify(data)}`);
  }

  getCustomEvent(eventId) {
    return internalAdminApi.get(`/event/custom/${eventId}`);
  }

  createCustomEvent(data) {
    return internalAdminApi.post(`/event/custom`, data);
  }

  editCustomEvent(eventId, data) {
    return internalAdminApi.put(`/event/custom/${eventId}`, data);
  }

  addCustomEventImage(eventId, param) {
    return internalAdminApi.post(`/event/custom/${eventId}/image`, param);
  }

  getMoyoEventList(page) {
    return internalAdminApi.get(`/moyo-event?page=${page}`);
  }

  getContractMvnos() {
    return internalAdminApi.get('/contract/mvno');
  }

  getContractPlans() {
    return internalAdminApi.get(`/contract/plan`);
  }

  addContractPlan(planIds) {
    return internalAdminApi.post('/contract/plan', { planIds: planIds });
  }

  removeContractPlan(planId) {
    return internalAdminApi.delete(`/contract/plan/${planId}`);
  }

  /**
   * 사은품(이벤트 상세)와 요금제 매핑 정보 저장
   * @param detailId 이벤트 상세 entity id
   * @param planIds 요금제 id 목록
   */
  saveEventPlansRelation(detailId, planIds) {
    return internalAdminApi.post(
      `/event/detail/${detailId}/relations`,
      planIds,
    );
  }

  addEventDetail(eventId, param) {
    return internalAdminApi.post(`/event/addDetail/${eventId}`, param);
  }

  // 이벤트 상세 수정 ( ex: 순서, 내용 )
  editEventDetail(eventId, detail) {
    return internalAdminApi.put(`/event/detail/${eventId}`, detail);
  }

  editEventExposed(eventId, data) {
    return internalAdminApi.patch(`/event/${eventId}/exposure`, data);
  }

  removeEventDetail(eventId, detailId) {
    return internalAdminApi.post(`/event/removeDetail/${eventId}`, {
      detailId,
    });
  }

  getGifts(data) {
    if (!data.planId) {
      return null;
    }
    return api.get(`mobile-plan-gift/${data.planId}`);
  }

  updateGifts(data) {
    if (!data.planId || !data.gifts) return null;
    return api.post(`mobile-plan-gift/${data.planId}`, data.gifts);
  }

  getSurveys(startDate, endDate) {
    let queryString = '';
    if (startDate) {
      queryString += `startDate=${startDate}&`;
    }
    if (endDate) {
      queryString += `endDate=${endDate}`;
    }
    return api.get(`survey?${queryString}`);
  }

  downloadSurveyExcel(startDate, endDate) {
    let queryString = '';
    if (startDate) {
      queryString += `startDate=${startDate}&`;
    }
    if (endDate) {
      queryString += `endDate=${endDate}`;
    }
    return downloadApi.get(`survey/excel?${queryString}`);
  }

  getAdBanners() {
    return adminApi.get(`ad/banners`);
  }

  getAdBanner(bannerId) {
    return adminApi.get(`ad/banners/${bannerId}`);
  }

  createAdBanner(data) {
    return adminApi.post(`ad/banners`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  editAdBanner(bannerId, data) {
    return adminApi.put(`ad/banners/${bannerId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteAdBanner(bannerId) {
    return adminApi.delete(`ad/banners/${bannerId}`);
  }

  getAdSchedules() {
    return adminApi.get(`ad/schedules`);
  }

  createAdSchedule(data) {
    return adminApi.post(`ad/schedules`, data);
  }

  getAdSchedule(scheduleId) {
    return adminApi.get(`ad/schedules/${scheduleId}`);
  }

  editAdSchdule(scheduleId, data) {
    return adminApi.put(`ad/schedules/${scheduleId}`, data);
  }

  deleteAdSchdule(scheduleId) {
    return adminApi.delete(`ad/schedules/${scheduleId}`);
  }

  getConstraints() {
    return adminApi.get(`constraints`);
  }

  getConstraint(constraintId) {
    return adminApi.get(`constraints/${constraintId}`);
  }

  editConstraint(constraintId, data) {
    return adminApi.put(`constraints/${constraintId}`, data);
  }

  createConstraint(data) {
    return adminApi.post(`constraints`, data);
  }

  getConstraintReserveEdits(constraintId) {
    return adminApi.get(`constraints/${constraintId}/reserve`);
  }

  removeReserveEdit(reserveId) {
    return adminApi.delete(`constraints/reserve/${reserveId}`);
  }

  reserveEditConstraint(constraintId, reserveTime, data) {
    const applyDate = reserveTime.toISOString().split('T')[0];
    return adminApi.put(
      `constraints/${constraintId}/reserve?applyDate=${applyDate}`,
      data,
    );
  }

  async deleteConstraint(constraintId) {
    return adminApi.delete(`constraints/${constraintId}`);
  }

  getConstraintsForPlan(mvno, mno, planId) {
    return adminApi.get(`constraints/plan/${planId}?mvno=${mvno}&mno=${mno}`);
  }

  getReferralStatusList(data) {
    return internalAdminApi.get(`/referral?${qs.stringify(data)}`);
  }

  addKeyword(data) {
    console.log(data);
    return internalAdminApi.post('seo', data);
  }

  getAllKeyword() {
    return internalAdminApi.get('seo');
  }

  getKeyword(id) {
    return internalAdminApi.get(`seo/${id}/ranking`);
  }

  getKeywordReport(url) {
    return internalAdminApi.get(`seo/keywords/${url}/report`);
  }

  getNoResultKeyword() {
    return internalAdminApi.get('seo/no-ranking');
  }

  getAllKeywordWithRanking() {
    return internalAdminApi.get('seo/all/ranking');
  }

  requestScraping(mvno) {
    return internalAdminApi.get(`mobile-plan-meta/scraping?mvno=${mvno}`);
  }

  requestEventScraping(mvno) {
    return internalAdminApi.post(`event/scraping?mvno=${mvno}`);
  }

  async removeEventCache() {
    return internalAdminApi.get(`event/cache-remove`);
  }

  async getEventPlanList(id) {
    return internalAdminApi.get(`event/scrap-plan/${id}`);
  }

  saveScrapEventPlansRelation(id, planIds) {
    return internalAdminApi.post(`/event/scrap-plan/${id}/relations`, planIds);
  }

  delScrapEventPlansRelation(id) {
    return internalAdminApi.delete(`/event/scrap-plan/${id}/relations`);
  }

  copyEvent(targetId, sourceId) {
    return internalAdminApi.post(`/event/${targetId}/copy`, {
      eventId: sourceId,
    });
  }

  async fileUpload(file, path) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('path', path);

    return api.post('/aws/s3/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new ApiService();
