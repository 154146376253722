import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Grid } from '@mui/material';
import { Section } from '../components/Section';
import { SectionHeader } from '../components/SectionHeader';
import { SettlementCard } from '../components/SettlementCard';
import { SETTLEMENT_MAIN_PATH, SettlementRoutes } from '../routes';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';
import { useQuery } from '@tanstack/react-query';
import { settlementApiService } from '../../../service/settlementSevice';
import PeopleIcon from '@mui/icons-material/People';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import StarIcon from '@mui/icons-material/Star';
import InboxIcon from '@mui/icons-material/Inbox';
import moment from 'moment';
import { useSettlementEventType } from '../hooks/useSettlementEventType';
import { EventType } from '../../../entity/settlement';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useSettlementEvents } from '../hooks/useSettlementEvents';

export function TotalAmountsSection() {
  const { endDate, startDate } = useSettlementPeriod();
  const { eventType } = useSettlementEventType();

  const { data } = useQuery(
    ['settlement-event-rewards', endDate, startDate, eventType],
    () =>
      settlementApiService.getRewardsByEvent({
        endDate,
        startDate,
        eventType: eventType === 'all' ? undefined : (eventType as EventType),
      }),
  );

  const list = data?.data.result ?? [];

  const { eventNamesByCategory } = useSettlementEvents();

  const totalAmount =
    list
      ?.map((item: any) => item.totalMoyoRewardValue)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;

  const totalOpenCount =
    list
      ?.map((item: any) => item.totalCount)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;

  const referrerAmount =
    list
      ?.filter((item: any) =>
        eventNamesByCategory.referrals.includes(item.eventName),
      )
      ?.map((item: any) => item.totalMoyoRewardValue)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;
  const affiliateAmount =
    list
      ?.filter((item: any) =>
        eventNamesByCategory.alliances.includes(item.eventName),
      )
      ?.map((item: any) => item.totalMoyoRewardValue)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;
  const influencerAmount =
    list
      ?.filter((item: any) =>
        eventNamesByCategory.influencers.includes(item.eventName),
      )
      ?.map((item: any) => item.totalMoyoRewardValue)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;
  const couponAmount =
    list
      ?.filter((item: any) =>
        eventNamesByCategory.coupons.includes(item.eventName),
      )
      ?.map((item: any) => item.totalMoyoRewardValue)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;

  return (
    <Section>
      <SectionHeader title="정산 정보" />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SettlementCard
            title={`${moment(startDate).get('month') + 1}월 총 개통 리워드 금액`}
            amount={totalAmount}
            iconComponent={CalendarTodayIcon}
          />
        </Grid>
        <Grid item xs={6}>
          <SettlementCard
            title={`${moment(startDate)
              .get('month') + 1}월 총 개통 건수`}
            amount={totalOpenCount}
            unit="건"
            iconComponent={LocalAtmIcon}
          />
        </Grid>
        <Grid item xs={6}>
          <SettlementCard
            title="레퍼러 총 개통 리워드 금액"
            amount={referrerAmount}
            iconComponent={PeopleIcon}
            link={`/${SETTLEMENT_MAIN_PATH}/${SettlementRoutes.레퍼러}`}
          />
        </Grid>
        <Grid item xs={6}>
          <SettlementCard
            title="제휴채널 총 개통 리워드 금액"
            amount={affiliateAmount}
            iconComponent={ViewModuleIcon}
            link={`/${SETTLEMENT_MAIN_PATH}/${SettlementRoutes.제휴채널}`}
          />
        </Grid>
        <Grid item xs={6}>
          <SettlementCard
            title="인플루언서 총 개통 리워드 금액"
            amount={influencerAmount}
            iconComponent={StarIcon}
            link={`/${SETTLEMENT_MAIN_PATH}/${SettlementRoutes.인플루언서}`}
          />
        </Grid>
        <Grid item xs={6}>
          <SettlementCard
            title="쿠폰 총 개통 리워드 금액"
            amount={couponAmount}
            iconComponent={InboxIcon}
            link={`/${SETTLEMENT_MAIN_PATH}/${SettlementRoutes.쿠폰}`}
          />
        </Grid>
      </Grid>
    </Section>
  );
}
