import axios, { AxiosResponse } from "axios";

interface QueryAnswerData {
  response: string;
}
class GPTApiService {
  private readonly http;
  private readonly token;
  constructor(token?: string) {
    this.token = token;
    this.http = axios.create({
      baseURL: new URL('/api/v1', process.env.REACT_APP_TALK_GPT_API_BASE_URL).href,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getQuery(query: string): Promise<AxiosResponse<QueryAnswerData>> {
    return this.http.post(`/query`, { query });
  }

  getUserAnswerQuery(query: string): Promise<AxiosResponse<QueryAnswerData>> {
    return this.http.post(`/user-answer/query`, { query });
  }

  getMetaTitle(query: string): Promise<AxiosResponse<QueryAnswerData>> {
    return this.http.post(`/meta-title/query`, { query });
  }
}

export const gptApiService = new GPTApiService();