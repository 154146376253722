import moment, { Moment } from "moment";

export function getMonthsBetween(start: Date | Moment, end: Date | Moment, format: string = 'YYYY.MM') {
  const startDate = start instanceof Date ? moment(start) : start;
  const endDate = end instanceof Date ? moment(end) : end;
  const betweenMonths = [];

  if (startDate < endDate){
    const date = startDate.startOf('month');

    while (date < endDate.endOf('month')) {
        betweenMonths.push(date.format(format));
        date.add(1,'month');
    }
  }

  return betweenMonths;
}