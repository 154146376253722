export function makeThumbnailSrc(str) {
  switch (str) {
    case '프리티':
      return '/static/img/clientcards/freet.svg';
    case 'ktmmobile':
      return '/static/img/clientcards/ktmobile.svg';
    case 'U+알뜰모바일':
      return '/static/img/clientcards/uplussave.svg';
    case '마이월드':
      return '/static/img/clientcards/myworld.svg';
    case 'insmobile':
      return '/static/img/clientcards/ins.svg';
    case 'amobile':
      return '/static/img/clientcards/amobile.svg';
    case '이야기모바일':
      return '/static/img/clientcards/eyagi.svg';
    case 'KTskylife':
      return '/static/img/clientcards/ktskylife.svg';
    case 'liivm':
      return '/static/img/clientcards/liivm.svg';
    case 'sk7mobile':
      return '/static/img/clientcards/sk7mobile.svg';
    case 'tplus':
      return '/static/img/clientcards/tplus.svg';
    case '헬로모바일':
      return '/static/img/clientcards/hellomobile.svg';
    case '핀다이렉트':
      return '/static/img/clientcards/pindirect.svg';
    case 'Pinplay':
      return '/static/img/clientcards/pindirect.svg';
    case 'smartel':
      return '/static/img/clientcards/smartel.svg';
    case 'snowman':
      return '/static/img/clientcards/snowman.svg';
    case '아이즈모바일':
      return '/static/img/clientcards/eyesmobile.svg';
    case '에스원안심모바일':
      return '/static/img/clientcards/sone.svg';
    case 'mobing':
      return '/static/img/clientcards/mobing.svg';
    case '서경모바일':
      return '/static/img/clientcards/seogyung.svg';
    case '여유알뜰폰':
      return '/static/img/clientcards/yeoyou.svg';
    case '이지모바일':
      return '/static/img/clientcards/egmobile.svg';
    case 'KCTV':
      return '/static/img/clientcards/kctv.svg';
    case '드림모바일':
      return '/static/img/clientcards/dreammobile.svg';
    case '위너스텔':
      return '/static/img/clientcards/winnerstel.svg';
  }
  return `https://d8gageze1i5x5.cloudfront.net/img/clientcards/${str}.svg`;
}
