import { Spacing } from '../../component/Spacing';
import { EventParameterType, eventParameterTypeBySubCategories } from '../../entity/settlement';
import { BizmSection } from './components/BizmSection';
import { SettlementSubCategoryRawDataSection } from './components/SettlementSubCategoryRawDataSection';
import { SettlementSubRoutes } from './routes';

export function SettlementCoupons({
  subCategory,
}: {
  category: string;
  subCategory: SettlementSubRoutes;
}) {
  if (eventParameterTypeBySubCategories[subCategory].type == EventParameterType.none) {
    return null;
  }

  return (
    <>
      <Spacing size={56} />
      <BizmSection subCategory={subCategory} />
      <Spacing size={64} />
      <SettlementSubCategoryRawDataSection subCategory={subCategory} />
    </>
  );
}
