import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router';

import { MvnosWithAll } from '../../common/constants';
import apiService from '../../service/api.service';

export default function ConstraintDetail() {
  const { constraintId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const mno = params.get('mno') ?? null;
  const mvno = params.get('mvno') ?? null;
  const planId = params.get('planId') ?? null;
  const constraintParam = params.get('constraintId') ?? null;
  const [constraint, setConstraint] = useState({
    mno: mno,
    mvno: mvno,
    type: 'CONSTRAINT',
    detailType: 'planDetail',
    typeId: planId,
    description: '',
    id: constraintId,
  });
  const [reserveTime, setReserveTime] = useState(new Date());
  const [reserveEditList, setReserveEditList] = useState([]);
  const [dangerConstraintList, setDangerConstraintList] = useState([]);
  const [infoConstraintList, setInfoConstraintList] = useState([]);
  const constraintContent = {
    danger: [],
    info: [],
  };
  const navigate = useNavigate();
  const { register, getValues, reset, watch, handleSubmit } = useForm({});
  const dataTableColumns = [
    {
      name: '적용 예정일',
      center: true,
      selector: (row) => row.effectiveAt,
      maxWidth: '16px',
    },
    {
      name: '통신사',
      center: true,
      selector: (row) => row.updateValue.mvno,
      maxWidth: '16px',
    },
    {
      name: '통신사망',
      center: true,
      selector: (row) => row.updateValue.mno,
      maxWidth: '16px',
    },
    {
      name: '타입',
      center: true,
      selector: (row) => row.updateValue.type,
      maxWidth: '24px',
    },
    {
      name: '디테일 타입',
      center: true,
      selector: (row) => row.updateValue.detailType,
      maxWidth: '24px',
    },
    {
      name: '타입 ID',
      center: true,
      selector: (row) => row.updateValue.typeId,
      maxWidth: '24px',
    },
    {
      name: '내용',
      center: true,
      selector: (row) => row.updateValue.description,
    },
    {
      name: '삭제',
      center: true,
      maxWidth: '24px',
      cell: (row) => (
        <Button
          variant="danger"
          size="sm"
          onClick={() => removeReserveEdit(row.id)}
        >
          삭제
        </Button>
      ),
    },
  ];

  const fetchConstraint = async () => {
    if (!constraintId && constraintParam === 'undefined') {
      setConstraint({
        mno: mno,
        mvno: mvno,
        type: 'CONSTRAINT',
        detailType: 'planDetail',
        typeId: planId,
        description: '',
      });
      return;
    }

    try {
      const res = await apiService.getConstraint(
        constraintId || constraintParam,
      );
      if (res && res.data && res.data.result) {
        setConstraint(res.data.result);
        if (
          res.data.result.content != null &&
          res.data.result.content != undefined
        ) {
          const parsedData = JSON.parse(res.data.result.content);
          const dangerData = parsedData.danger?.map((item) => ({
            description: item.description,
          }));
          const infoData = parsedData.info?.map((item) => ({
            description: item.description,
          }));
          setInfoConstraintList(infoData ?? []);
          setDangerConstraintList(dangerData ?? []);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReserveList = async () => {
    if (!constraintId || constraintParam) {
      return [];
    }

    try {
      const res = await apiService.getConstraintReserveEdits(
        constraintId || constraintParam,
      );
      if (res && res.data && res.data.result) {
        setReserveEditList(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeReserveEdit = async (editId) => {
    if (window.confirm('예약 수정을 삭제하시겠습니까?')) {
      await apiService.removeReserveEdit(editId);
      await fetchReserveList();
      window.alert('예약 수정이 삭제되었습니다.');
    }
  };

  useEffect(() => {
    fetchConstraint();
    fetchReserveList();
  }, []);

  useEffect(() => {
    if (!constraint) return;

    reset({
      mvno: constraint.mvno,
      mno: constraint.mno,
      type: constraint.type,
      detailType: constraint.detailType,
      typeId: constraint.typeId,
      description: constraint.description,
    });
  }, [constraint]);

  const onSubmit = async () => {
    const requestBody = {
      ...getValues(),
    };

    Object.keys(requestBody).forEach(function (key) {
      if (requestBody[key] === '') {
        requestBody[key] = null;
      }
    });

    if (requestBody['mno'] == null || requestBody['mvno'] == null) {
      alert('통신사와 통신사망은 필수 선택사항 입니다');
      return;
    }

    requestBody['type'] = 'CONSTRAINT';
    constraintContent.danger = dangerConstraintList.filter(
      (it) => it.description !== '',
    );
    constraintContent.info = infoConstraintList.filter(
      (it) => it.description !== '',
    );
    const json = JSON.stringify(constraintContent);
    requestBody['content'] = json;

    try {
      if (constraintId !== undefined) {
        const res = await apiService.editConstraint(
          constraintId || constraintParam,
          requestBody,
        );
        setConstraint(res.data.result);
        if (
          res.data.result.content != null &&
          res.data.result.content != undefined
        ) {
          const parsedData = JSON.parse(res.data.result.content);
          const dangerData = parsedData.danger.map((item) => ({
            description: item.description,
          }));
          const infoData = parsedData.info.map((item) => ({
            description: item.description,
          }));
          setInfoConstraintList(infoData);
          setDangerConstraintList(dangerData);
        }
        alert('저장되었습니다');
      } else {
        const res = await apiService.createConstraint(requestBody);

        if (res.data.resultType == 'FAIL') {
          alert(res.data.error);
          return;
        } else {
          const newConstraint = res.data.result;
          alert('저장되었습니다');
          navigate(`/constraint/${newConstraint.id}`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CheckConstraintMoyo = (planId) => {
    const alphaMoyo = 'https://alpha.moyoplan.com/plans/';
    const prodMoyo = 'https://moyoplan.com/plans/';
    const moyoUrl = process.env.REACT_APP_API_BASE_URL.includes('alpha')
      ? alphaMoyo
      : prodMoyo;
    window.open(`${moyoUrl}${planId}`, '_blank');
  };

  const submitReservation = async () => {
    const requestBody = {
      ...getValues(),
    };

    Object.keys(requestBody).forEach(function (key) {
      if (requestBody[key] === '') {
        requestBody[key] = null;
      }
    });

    if (requestBody['mno'] == null || requestBody['mvno'] == null) {
      alert('통신사와 통신사망은 필수 선택사항 입니다');
      return;
    }

    try {
      await apiService.reserveEditConstraint(
        constraintId || constraintParam,
        reserveTime,
        requestBody,
      );
      await fetchReserveList();
      alert('예약 저장이 추가되었습니다');
    } catch (err) {
      console.log(err);
    }
  };

  const deleteConstraint = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      try {
        await apiService.deleteConstraint(constraintId || constraintParam);
        alert('삭제되었습니다');
        navigate('/constraint');
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addDangerConstraint = () => {
    const newConstraint = { description: '' };
    setDangerConstraintList([...dangerConstraintList, newConstraint]);
  };
  const addInfoConstraint = () => {
    const newConstraint = { description: '' };
    setInfoConstraintList([...infoConstraintList, newConstraint]);
  };

  const handleDangerInputChange = (index, value) => {
    // 입력 필드의 값을 업데이트
    const updatedConstraints = [...dangerConstraintList];
    updatedConstraints[index].description = value;
    setDangerConstraintList(updatedConstraints);
  };

  const handleInfoInputChange = (index, value) => {
    // 입력 필드의 값을 업데이트
    const updatedConstraints = [...infoConstraintList];
    updatedConstraints[index].description = value;
    setInfoConstraintList(updatedConstraints);
  };

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <Container className="w-75">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>제약조건 상세</h3>
              <div style={{ display: 'inline' }}>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  onClick={onSubmit}
                >
                  저장
                </Button>
                {(constraintId || constraintParam) && (
                  <>
                    <DatePicker
                      className="me-3"
                      selected={reserveTime}
                      onChange={(date) => {
                        setReserveTime(date);
                      }}
                      dateFormat="yyyy/M/d"
                    />
                    <Button
                      className="me-3"
                      variant="outline-primary"
                      onClick={submitReservation}
                    >
                      예약 변경
                    </Button>
                    <Button
                      className="me-3"
                      variant="outline-primary"
                      onClick={() => CheckConstraintMoyo(watch('typeId'))}
                    >
                      모요로 확인하기
                    </Button>
                    <Button variant="outline-danger" onClick={deleteConstraint}>
                      삭제
                    </Button>
                  </>
                )}
              </div>
            </div>
            <hr />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>제약조건 Id</Form.Label>
                <Form.Control
                  defaultValue={constraintId || constraintParam}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>통신사 ( 필수 )</Form.Label>
                <Form.Select
                  {...register('mvno', {
                    required: true,
                  })}
                >
                  <option value={''}>선택</option>
                  {Object.entries(MvnosWithAll).map(([key, item]) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>통신사망 ( 필수 )</Form.Label>
                <Form.Select
                  {...register('mno', {
                    required: true,
                  })}
                >
                  <option value={''}>선택</option>
                  <option value={'SKT'}>SKT</option>
                  <option value={'KT'}>KT</option>
                  <option value={'LGU'}>LGU</option>
                </Form.Select>
              </Form.Group>
              <Row className="g-2">
                <Col md>
                  <Form.Group className="mb-3">
                    <Form.Label>디테일 타입</Form.Label>
                    <Form.Select
                      {...register('detailType', {
                        required: true,
                      })}
                    >
                      <option value={''}>선택 안함</option>
                      <option value={'event'}>event</option>
                      <option value={'planDetail'}>planDetail</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md>
                  <Form.Group className="mb-3">
                    <Form.Label>디테일 타입 Id</Form.Label>
                    <Form.Control
                      {...register('typeId', {
                        required: true,
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3 mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Form.Label>경고성 정보 내용</Form.Label>
                  <Button onClick={addDangerConstraint}>내용 추가</Button>
                </div>
                {dangerConstraintList.map((constraint, index) => (
                  <Form.Control
                    className="mb-3"
                    key={index}
                    value={constraint.description}
                    onChange={(e) =>
                      handleDangerInputChange(index, e.target.value)
                    }
                  />
                ))}
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Form.Label>안내성 정보 내용</Form.Label>
                  <Button onClick={addInfoConstraint}>내용 추가</Button>
                </div>
                {infoConstraintList.map((constraint, index) => (
                  <Form.Control
                    className="mb-3"
                    key={index}
                    value={constraint.description}
                    onChange={(e) =>
                      handleInfoInputChange(index, e.target.value)
                    }
                  />
                ))}
              </Form.Group>
            </Form>
          </div>
        </Container>
        <DataTable
          title="예약 수정 목록"
          columns={dataTableColumns}
          data={reserveEditList}
        />
      </div>
    </>
  );
}
