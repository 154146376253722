import { AxiosResponse } from "axios";

export const getFileName = (contentDisposition: any) => {
  let fileName = contentDisposition
    .split(';')
    .filter((el: any) => el.indexOf('filename') > -1)
    .map((ele: any) => ele.replace(/"/g, '').split('=')[1]);
  return fileName[0];
};

export const downloadFile = (file: AxiosResponse, fileName?: string) => {
  const blob = new Blob([file.data], {
    type: file.headers['content-type'],
  });
  const name = decodeURIComponent(file.headers['content-disposition']); //파일명 디코딩
  fileName = fileName ?? getFileName(name);
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob) {
    // IE 10+는 해당 함수 제공
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_self';
    if (fileName) link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link as any); //메모리 누수 방지
  }
};


export const getFileNameFromUUIDPath = (path: string) => {
  const splitedFileUrl = path.split('/');
  const splitUuid = splitedFileUrl[splitedFileUrl.length - 1].split(
    /[\d,a-z]{8}(?:-[\d,a-z]{4}){3}-[\d,a-z]{12}/,
  );
  const fileName = splitUuid?.[1];
  return fileName || splitedFileUrl[splitedFileUrl.length - 1];
}