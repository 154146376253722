import { CircularProgress, Paper } from '@mui/material';

export default function Spinner({ show }: { show: boolean }) {
  return (
    <Paper
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: show ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Paper>
  );
}
