import { Form } from 'react-bootstrap';

export default function FilterSelectComponent({ className, style, value, selectList, onChange }) {
  return (
    <>
      <Form.Select className={ className || 'w-25'} style={style} size='sm' aria-label='mvno' onChange={onChange} value={value}>
        {selectList.map((select) => {
          return (
            <option value={select.value}>
              {select.name}
            </option>
          );
        })}
      </Form.Select>
    </>
  );
};
