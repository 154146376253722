import React, { useState, useMemo, useEffect } from 'react';
import { Button, Chip, Container, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { FilterForm, MoyoOnlyPopup, NameFilter, Title } from './components';

import { useGetMoyoOnlyList } from '../../hooks/queries/moyo-only/useGetMoyoOnlyList';
import getStatus from './hook/getStatus';
import moment from 'moment';
import { useCreateMoyoOnlyPlan } from '../../hooks/queries/moyo-only/useCraeteMoyoOnlyPlan';
import { MoyoOnlyListTypes, ToMoyoOnlyPlanTypes } from './type';
import { useChangeMoyoOnlyPlan } from '../../hooks/queries/moyo-only/useChangeMoyoOnlyPlan';
import { validatePlan } from './hook/validatePlan';
import { useNavigate } from 'react-router-dom';

export const MoyoOnlyListPage = () => {
  const MoyoOnlyColumn: GridColDef[] = [
    {
      field: 'status',
      headerAlign: 'center',
      headerName: '상태',
      align: 'center',
      renderCell: (param: GridRenderCellParams) => {
        let data = param.row.status;
        const { text, color } = getStatus(data);
        return <Chip color={color} label={text} size="small"></Chip>;
      },
      width: 100,
    },
    {
      field: 'mvno',
      headerAlign: 'center',
      headerName: 'MVNO',
      align: 'center',
      width: 100,
      renderCell: (param: GridRenderCellParams) => {
        return param.row.mvno;
      },
    },
    {
      field: 'fee',
      headerAlign: 'center',
      headerName: '요금제 이름',
      align: 'center',
      width: 350,
      renderCell: (param: GridRenderCellParams) => {
        return param.row.planMetaJson.name;
      },
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: '요청 날짜',
      align: 'center',
      width: 180,
      renderCell: (param: GridRenderCellParams) => {
        return moment(param.row.createdAt).format('YYYY년 MM월 DD일');
      },
    },
    {
      field: 'updatedAt',
      headerAlign: 'center',
      headerName: '처리 날짜',
      align: 'center',
      width: 180,
      renderCell: (param: GridRenderCellParams) => {
        return moment(param.row.updatedAt).format('YYYY년 MM월 DD일');
      },
    },
    {
      field: 'stabling',
      headerAlign: 'center',
      headerName: '요청유형',
      align: 'center',
      width: 80,
      renderCell: (param: GridRenderCellParams) => {
        let requestState;
        let color;
        if (param.row.planMetaJson.name.includes('모요only')) {
          requestState = '수정';
          color = '#faad14';
        } else if (param.row.planMetaJson.planId === undefined) {
          requestState = '신규생성';
          color = '#566FEE';
        } else {
          requestState = '변경생성';
          color = '#EE5656';
        }
        return (
          <span
            style={{
              color: color,
            }}
          >
            {requestState}
          </span>
        );
      },
    },
    {
      field: 'retch',
      headerAlign: 'center',
      headerName: '승인',
      align: 'center',
      width: 80,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Button
            disabled={param.row.status === 'STABLE'}
            onClick={() => handleMoyoOnlyCreatePopup()}
          >
            승인
          </Button>
        );
      },
    },
    {
      field: 'modifying',
      headerAlign: 'center',
      headerName: '상세 보기',
      align: 'center',
      width: 100,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Button
            color="primary"
            disabled={param.row.status === 'STABLE' ? true : false}
            onClick={() => handleOpenPlan(param.row.id)}
          >
            상세 보기
          </Button>
        );
      },
    },
  ];
  const [mvnoState, setMvnoState] = useState<string>('ALL');
  const [state, setState] = useState<string>('ALL');
  const [searchName, setSearchName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const { plans, totalSize, isLoading } = useGetMoyoOnlyList(
    paginationModel.page + 1,
    paginationModel.pageSize,
  );

  const navigate = useNavigate();

  const responseCreateMoyoOnlyPlan = (alertText: string) => {
    alert(alertText);
  };

  const changeMoyoOnlyPlanMutation = useChangeMoyoOnlyPlan();
  const changeMoyoOnlyPlanClick = async (
    requestId: number,
    planId: number,
    data: ToMoyoOnlyPlanTypes,
  ) => {
    try {
      changeMoyoOnlyPlanMutation.mutate({
        requestId: requestId,
        planId: planId,
        data: data,
      });
      responseCreateMoyoOnlyPlan('변경이 완료되었어요.');
      onClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const createMoyoOnlyPlanMutation = useCreateMoyoOnlyPlan();
  const createMoyoOnlyPlanClick = (id: number) => {
    try {
      createMoyoOnlyPlanMutation.mutate({ requestId: id });
      responseCreateMoyoOnlyPlan('생성이 완료되었어요.');
      onClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  // 검증 결과
  const filteredData =
    plans !== undefined
      ? plans.filter((item) => validatePlan(item, searchName, mvnoState, state))
      : [];

  //
  /**
   * MVNO 필터에 현재 요청이 들어와있는 mvno만 노출시키기 위한 로직
   */
  const uniqueMvnoSet = new Set();
  const filteredPlans = plans?.reduce((acc: MoyoOnlyListTypes[], item) => {
    if (!uniqueMvnoSet.has(item.mvno)) {
      uniqueMvnoSet.add(item.mvno);
      acc.push(item);
    }
    return acc;
  }, []);
  //

  //요금제 생성 팝업 열기 닫기
  function handleMoyoOnlyCreatePopup() {
    setOpen(true);
  }
  function onClose() {
    setOpen(false);
  }
  function handleOpenPlan(id: number) {
    navigate(`/moyo-only/plan/${id}`);
  }

  //생성하고자 하는 요금제 아이디 데이터 저장
  const selectedData = filteredData.filter((plan) =>
    selectedId.includes(plan.id),
  )[0];

  return (
    <Container sx={{ display: 'grid', gap: '1rem', mt: 3 }} maxWidth="xl">
      <Title />
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}
      >
        <FilterForm
          filteredPlans={filteredPlans}
          mvnoState={mvnoState}
          state={state}
          setMvnoState={setMvnoState}
          setState={setState}
        />
        <NameFilter setSearchName={setSearchName} searchName={searchName} />
      </div>
      <Tooltip title="사용시 주의사항">
        <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
          * 생성 및 변경하기 전에 요금제 정보가 올바른지 꼭 확인해주세요! *
        </p>
      </Tooltip>
      <DataGrid
        columns={MoyoOnlyColumn}
        rows={filteredData}
        rowCount={totalSize}
        loading={isLoading}
        sortingOrder={['asc', 'desc']}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={(id) => {
          setSelectedId(id);
        }}
        paginationMode="server"
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
      <MoyoOnlyPopup
        open={open}
        onClose={onClose}
        plans={selectedData}
        onCreateClick={(id: number) => createMoyoOnlyPlanClick(id)}
        onChangeMoyoOnlyClick={(
          requestId: number,
          planId: number,
          data: ToMoyoOnlyPlanTypes,
        ) => changeMoyoOnlyPlanClick(requestId, planId, data)}
      />
    </Container>
  );
};
