import React from 'react';
import { ListItem } from './ListItem';
import { PlanDetailItem } from '../type';

interface PopupContentProps {
  groupedPlanDetails: Record<string, PlanDetailItem[]>;
}

export const PopupContent: React.FC<PopupContentProps> = ({
  groupedPlanDetails,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginTop: 8,
      }}
    >
      <ul>
        {Object.entries(groupedPlanDetails).map(([title, items]) => {
          const validItems = items.filter((item) => Boolean(item.value));

          if (validItems.length === 0) {
            return null;
          }
          return (
            <div key={title}>
              <ListItem title={title}>
                {validItems.map((item, index) => (
                  <div key={index}>
                    {item.content && <>{item.content}:</>} {item.value}
                    <br />
                  </div>
                ))}
              </ListItem>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
