import ConstraintList from './ConstraintList';

export default function Constraint() {
  return (
    <>
      <div
        className="mt-3 pb-5"
        style={{
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      >
        <h4>요금제 제한사항 관리</h4>
        <ConstraintList />
      </div>
    </>
  );
}
