import { useMemo } from 'react';
import { EventType, Reward, eventTypesByCategory } from '../../../entity/settlement';
import { useQuery } from '@tanstack/react-query';
import { settlementApiService } from '../../../service/settlementSevice';
import { groupBy } from '../../../common/object';


export function useSettlementEvents() {
  const { data: events } = useQuery(
    ['settlement-all-reward-list'],
    async () => {
      const response = await settlementApiService.getAllRewardEvents();
      return response.data.data;
    },
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, cacheTime: 600000, suspense: true }
  );

  return useMemo(() => {
    const eventsByEventTypes = groupBy(events ?? [], (item => item.eventType)) as Record<EventType, Reward[]>;
    const eventNamesByEventTypes = Object.fromEntries(Object.entries(eventsByEventTypes).map(([eventType, events]) => ([eventType, events.map(event => event.eventName)]))) as Record<EventType, string[]>;
    const eventTypes = Object.keys(eventsByEventTypes) as EventType[];

    const eventNamesByCategory = Object.fromEntries(
      Object.entries(eventTypesByCategory).map(([category, eventTypes]) => ([
        category,
        eventTypes.map(eventType => eventNamesByEventTypes[eventType as EventType]).flat()
      ]))
    ) as Record<keyof typeof eventTypesByCategory, string[]>;
  
    return {
      events,
      eventsByEventTypes,
      eventNamesByEventTypes,
      eventNamesByCategory,
      eventTypes,
    };
  }, [events])
}
