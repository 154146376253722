export const RocketMvnos = [
  'kTskylife',
  'insmobile',
  'smartel',
  '이지모바일',
  'valuecomm',
  'sugarmobile',
  '이야기모바일',
  '마이월드',
  'pointplus',
  'marvelring',
  '사람과연결',
  '위너스텔',
  '에르엘',
  '미니게이트',
  '고고모바일',
  '핀다이렉트',
];
