export const moyoColors = {
  transparent: 'transparent',
  current: 'currentColor',
  inherit: 'inherit',
  white: '#ffffff',
  black: '#000000',
  ring: '#e4eaff',
  dim: 'rgba(73, 80, 87, 0.3)',

  gray100: '#f8f9fa',
  gray200: '#f1f3f5',
  gray300: '#dee2e6',
  gray400: '#adb5bd',
  gray500: '#868e96',
  gray600: '#495057',
  gray700: '#2a3037',

  indigo100: '#f3f6ff',
  indigo200: '#e5eaff',
  indigo300: '#aebeff',
  indigo400: '#8195ff',
  indigo500: '#566fee',
  indigo600: '#425ad5',

  red100: '#fff5f5',
  red200: '#ffe3e3',
  red300: '#ffa8a8',
  red400: '#ff6b6b',
  red500: '#fa5252',
  red600: '#f03e3e',

  yellow100: '#fff9db',
  yellow200: '#fff3bf',
  yellow300: '#ffe066',
  yellow400: '#ffd43b',
  yellow500: '#fcc419',
  yellow600: '#fab005',

  green100: '#ebfbee',
  green200: '#d3f9d8',
  green300: '#8ce99a',
  green400: '#69db7c',
  green500: '#51cf66',
  green600: '#40c057',
} as const;