import { Button, Grid, Switch } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Main } from '../../../component/Main';
import { OrderStatusOptionList } from '../../../entity/order';
import { npsService, SurveyTarget } from '../../../service/npsService';

const ActiveSwitch: FC<{ surveyTarget: SurveyTarget }> = ({ surveyTarget }) => {
  const [active, setActive] = useState(!!surveyTarget.active);

  const onChangeSwitchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onActiveHandler(e.currentTarget.checked);
  };

  const onActiveHandler = async (active: boolean) => {
    try {
      await npsService.putSurveyTarget(
        surveyTarget.id,
        surveyTarget.targetStatus,
        surveyTarget.surveyTemplateId,
        surveyTarget.scheduledSendDate,
        active,
      );

      setActive(active);
    } catch (e) {
      window.alert(
        `${active ? '활성화' : '비활성화'}를 하는데 문제가 생겼습니다.`,
      );
    }
  };

  return <Switch checked={active} onChange={onChangeSwitchHandler} />;
};

const NpsTargetTableColumns: TableColumn<SurveyTarget>[] = [
  {
    name: '순번',
    selector: 'id',
    maxWidth: '30px',
  },
  {
    name: '상태값',
    selector: 'name',
    cell: (row) => (
      <Link to={`/nps/${row.id}`}>
        {
          OrderStatusOptionList.find((item) => item.key === row.targetStatus)
            ?.name
        }
      </Link>
    ),
  },
  {
    name: '템플릿 보기',
    selector: 'name',
    cell: (row) => (
      <a target="__blank__" href={`/nps/template/${row.surveyTemplateId}`}>
        템플릿 보기
      </a>
    ),
  },
  {
    name: '활성화',
    selector: 'active',
    cell: (row) => <ActiveSwitch surveyTarget={row} />,
  },
  {
    name: '생성일',
    selector: 'regTs',
    maxWidth: '180px',
  },
  {
    name: '수정일',
    selector: 'updTs',
    maxWidth: '180px',
  },
];

const NpsListPage: FC = () => {
  const [templateList, setTemplateList] = useState<SurveyTarget[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    npsService
      .getSurveyTargetList()
      .then((rs) => {
        setTemplateList(rs.data.result ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Main>
        <DataTable
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>NPS 리스트</div>
              <Button href="/nps/create" variant="outlined">
                생성하기
              </Button>
            </Grid>
          }
          columns={NpsTargetTableColumns}
          data={templateList}
          pagination
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          progressPending={loading}
        />
      </Main>
    </>
  );
};

export default NpsListPage;
