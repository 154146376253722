import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getMoyoOnlyList } from '../../../apis/service/moyo-only';
import { getMoyoOnlyTypes } from '../../../service/moyo-only';
import { handleApi, moyoOnlyApi } from '../../../common/http_util';
import { useNavigate } from 'react-router-dom';

export const useGetMoyoOnlyList = (
  page: number,
  size: number,
  options?: UseQueryOptions<getMoyoOnlyTypes>,
) => {
  const navigate = useNavigate();

  const { data = { plans: [], totalSize: 0 }, isFetching } = useQuery({
    queryKey: ['moyo', 'only', 'plan', page, size],
    queryFn: () => {
      const response = handleApi(
        () =>
          getMoyoOnlyList({
            page: page - 1, // 주의: 페이지 인덱스는 0부터 시작합니다.
            size: size,
          }),
        navigate,
      );
      const result = response.then((res) => {
        const parseData = res?.list.map((item) => ({
          ...item,
          planMetaJson: JSON.parse(item.planMetaJson),
        }));

        return {
          plans: parseData,
          totalSize: res?.totalSize,
        };
      });

      return result;
    },
  });

  return {
    plans: data.plans,
    totalSize: data.totalSize,
    isLoading: isFetching,
  };
};
