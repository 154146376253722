import { FC, ReactNode } from 'react';

type ListItemProps = {
  title: string;
  children: ReactNode;
};

export const ListItem: FC<ListItemProps> = ({ title, children }) => (
  <li style={{ margin: '1.5rem 0' }}>
    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>{title}</p>
    <span>{children}</span>
  </li>
);
