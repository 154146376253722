import { Button, Form } from 'react-bootstrap';

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <Form.Control
      size="sm"
      id="search"
      type="text"
      placeholder="요금제 이름 검색"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      style={{ marginLeft: 'auto', width: '200px' }}
    />
    <Button
      variant="outline-secondary"
      className="mr-0"
      size="sm"
      onClick={onClear}
      style={{ marginRight: '0', marginLeft: '0.5rem' }}
    >
      X
    </Button>
  </>
);
