export const SETTLEMENT_MAIN_PATH = 'settlement';

export enum SettlementRoutes {
  Summary = 'summary',
  레퍼러 = 'referrals',
  제휴채널 = 'alliances',
  인플루언서 = 'influencers',
  쿠폰 = 'coupons',
}

export const settlementTabs = Object.entries(SettlementRoutes).map(
  ([key, value]) => ({
    label: key,
    value,
  }),
);

export enum SettlementSubRoutes {
  친구초대 = 'invite',
  애드링크 = 'adlink',
  페이북 = 'paybooc',
  핀크 = 'finnq',
  에이블리 = 'ably',
  뱅크샐러드 = 'banksalad',
  정가거부 = 'refusal-of-price',
  모요쿠폰 = 'moyo',
  브랜드쿠폰 = 'brand',
  믹스쿠폰 = 'mixed',
}

export const settlementSubRoutKeyByValue = Object.fromEntries(
  Object.entries(SettlementSubRoutes).map(([key, value]) => [value, key]),
);

const settlementSubRouteByPath = Object.fromEntries(
  Object.entries(SettlementSubRoutes).map(([key, value]) => [
    value,
    {
      label: key,
      value,
    },
  ]),
);

export const settlementSubCategoriesByRoutes = {
  [SettlementRoutes.Summary]: [],
  [SettlementRoutes.레퍼러]: [
    SettlementSubRoutes.친구초대,
    SettlementSubRoutes.애드링크,
  ],
  [SettlementRoutes.제휴채널]: [
    SettlementSubRoutes.페이북,
    SettlementSubRoutes.핀크,
    SettlementSubRoutes.에이블리,
    SettlementSubRoutes.뱅크샐러드,
  ],
  [SettlementRoutes.인플루언서]: [SettlementSubRoutes.정가거부],
  [SettlementRoutes.쿠폰]: [
    SettlementSubRoutes.모요쿠폰,
    SettlementSubRoutes.브랜드쿠폰,
    SettlementSubRoutes.믹스쿠폰,
  ],
};

export const settlementSubTabsByCategory = Object.fromEntries(
  Object.entries(settlementSubCategoriesByRoutes).map(([key, value]) => [
    key,
    value.map((path) => settlementSubRouteByPath[path]),
  ]),
);
