import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const STORAGE_ADMIN_USER = 'admin_user';
  const initialUser = localStorage.getItem(STORAGE_ADMIN_USER);
  const location = useLocation();
  if (!initialUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
