import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import apiService from '../../service/api.service';

export default function ScheduleList() {
  const scheduleColumns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
      sortField: 'id',
      maxWidth: '60px',
    },
    {
      name: '제목',
      selector: (row) => row.title,
      sortable: true,
      sortField: 'title',
      maxWidth: '300px',
    },
    {
      name: '타입',
      selector: (row) => row.type,
      sortable: true,
      sortField: 'type',
      maxWidth: '120px',
    },
    {
      name: '캠페인',
      selector: (row) => row.campaign,
      sortable: true,
      sortField: 'campaign',
      maxWidth: '200px',
    },
    {
      name: 'Active',
      selector: (row) => row.isActive,
      cell: (row, index, column, id) => {
        return (
          <Form>
            <Form.Check
              type="switch"
              defaultChecked={row.isActive}
              onChange={(e) =>
                onActiveCheckChanged(index, row, e.target.checked)
              }
            />
          </Form>
        );
      },
      maxWidth: '100px',
      center: true,
    },
    {
      name: '시작일',
      selector: (row) => {
        const date = row.startDate;
        return date && date.length === 3
          ? `${date[0]}년 ${date[1]}월 ${date[2]}일`
          : 'null';
      },
      sortable: true,
      sortField: 'startDate',
      maxWidth: '180px',
    },
    {
      name: '종료일',
      selector: (row) => {
        const date = row.endDate;
        return date && date.length === 3
          ? `${date[0]}년 ${date[1]}월 ${date[2]}일`
          : 'null';
      },
      sortable: true,
      sortField: 'endDate',
      maxWidth: '180px',
    },
  ];

  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);

  const fetchSchedules = async () => {
    try {
      const res = await apiService.getAdSchedules();
      console.log(res);
      if (res && res.data) {
        setSchedules(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const onActiveCheckChanged = async (index, schedule, checked) => {
    console.log(schedule, checked);

    if (
      window.confirm(
        checked
          ? '스케줄을 활성화하시겠습니까?'
          : '스케줄을 비활성화하시겠습니까?',
      )
    ) {
      try {
        const res = await apiService.editAdSchdule(schedule.id, {
          isActive: checked,
        });

        const updatedSchedule = res.data.result;
        setSchedules([
          ...schedules.slice(0, index),
          updatedSchedule,
          ...schedules.slice(index + 1),
        ]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onRowClick = (row, event) => {
    navigate(`/ads/schedules/${row.id}`);
  };

  const createButton = (
    <Button size="sm" onClick={() => navigate('/ads/schedules/add')}>
      스케줄 만들기
    </Button>
  );

  return (
    <>
      <DataTable
        title="배너"
        columns={scheduleColumns}
        data={schedules}
        pagination
        sortable
        onRowClicked={onRowClick}
        actions={[createButton]}
      ></DataTable>
    </>
  );
}
