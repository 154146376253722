import { Spacing } from '../../component/Spacing';
import { BizmSection } from './components/BizmSection';
import { InternetUsersSection } from './components/InternetUsersSection';
import { SettlementSubCategoryRawDataSection } from './components/SettlementSubCategoryRawDataSection';
import { SettlementSubCategoryUserPerformanceSection } from './components/SettlementSubCategoryUserPerformanceSection';
import { SettlementSubRoutes } from './routes';

export function SettlementReferrals({
  subCategory,
}: {
  category: string;
  subCategory: SettlementSubRoutes;
}) {
  return (
    <>
      <Spacing size={56} />
      <SettlementSubCategoryUserPerformanceSection subCategory={subCategory} />
      {subCategory === SettlementSubRoutes.친구초대 && (
        <>
          <Spacing size={64} />
          <BizmSection subCategory={subCategory} />
          <Spacing size={64} />
          <InternetUsersSection />
        </>
      )}
      <Spacing size={64} />
      <SettlementSubCategoryRawDataSection subCategory={subCategory} />
    </>
  );
}
