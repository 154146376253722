import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { GenerateCommunityPost } from '../../../entity/generateCommunityEntity';
import { generateCommunityApiService } from '../../../service/generateCommunityService';
import GenerateCommunityEditDialog from './GenerateCommunityEditDialog';

const CommunityTableColumns: TableColumn<GenerateCommunityPost>[] = [
  {
    name: '순번',
    selector: 'id',
    maxWidth: '30px',
  },
  {
    name: '제목',
    selector: 'title',
    cell: (row) => <div>{row.title}</div>,
  },
  {
    name: '출처',
    selector: 'source',
    maxWidth: '60px',
  },
  {
    name: '답변',
    selector: 'answerList',
    maxWidth: '60px',
    cell: (row) => `${row.answerList.length}`,
  },
  {
    name: '질답 링크',
    selector: 'realPostId',
    maxWidth: '120px',
    cell: (row) =>
      row.realPostUuid && (
        <Grid container gap={2}>
          <Link to={`/community/${row.realPostUuid}`}>수정/삭제</Link>
          <a
            href={`${process.env.REACT_APP_API_BASE_URL}/community/questions/${row.realPostUuid}`}
            target="_black"
          >
            질답
          </a>
        </Grid>
      ),
  },
];

const EMPTY_COLUMN = {
  id: 99999,
  source: '',
  title: '',
  content: '',
  answerList: [],
};

const GenerateCommunityPage: FC = () => {
  const [communityData, setCommunityData] = useState<GenerateCommunityPost[]>(
    [],
  );
  const [editCommunityData, setEditCommunityData] =
    useState<GenerateCommunityPost>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get('page') || '0'),
  );
  const [perPage, setPerPage] = useState<number>(
    Number(searchParams.get('perPage') || '10'),
  );
  const [totalSize, setTotalSize] = useState<number>(0);

  useMemo(() => {
    const onClickEdit = (row: GenerateCommunityPost) => {
      const onClickHandler = () => {
        setEditCommunityData(row);
      };
      return <div onClick={onClickHandler}>{row.title}</div>;
    };

    CommunityTableColumns[1].cell = onClickEdit;
  }, []);

  useEffect(() => {
    const page = Number(searchParams.get('page') || '0');
    const perPage = Number(searchParams.get('perPage') || '10');
    console.log(page, perPage);
    setPage(page);
    setPerPage(perPage);
    onLoadPostList(page, perPage);
  }, [searchParams]);

  const onLoadPostList = async (page: number, perPage: number) => {
    try {
      setLoading(true);

      const rs = await generateCommunityApiService.getPostList(page, perPage);
      const communityData = rs.data.result;
      setCommunityData(
        communityData.length > 0 ? communityData : [EMPTY_COLUMN],
      );
      setTotalSize(rs.data.totalSize);
    } catch (_: any) {
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page - 1);
    searchParams.set('page', (page - 1).toString());
    setSearchParams(searchParams);
  };

  const onChangeRowsPerPageHandler = (
    currentRowsPerPage: number,
    currentPage: number,
  ) => {
    setPerPage(currentRowsPerPage);
    setPage(currentPage - 1);
    searchParams.set('page', (currentPage - 1).toString());
    searchParams.set('perPage', currentRowsPerPage.toString());
    setSearchParams(searchParams);
  };

  const onCloseHandler = () => {
    setEditCommunityData(undefined);
  };

  const onSubmitHandler = async () => {
    onLoadPostList(page, perPage);
    onCloseHandler();
  };

  return (
    <div>
      <div className="container-md mt-3 pb-5">
        <DataTable
          title="질문/답변 목록"
          columns={CommunityTableColumns}
          data={communityData}
          pagination
          paginationServer
          paginationDefaultPage={page + 1}
          paginationTotalRows={totalSize}
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          paginationPerPage={perPage}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          progressPending={loading}
          onChangePage={handlePageChange}
        />
        <GenerateCommunityEditDialog
          key={editCommunityData?.id}
          generateCommunityPost={editCommunityData}
          onClose={onCloseHandler}
          onSubmit={onSubmitHandler}
        />
      </div>
    </div>
  );
};

export default GenerateCommunityPage;
