import { TextField } from '@mui/material';
import { FC } from 'react';

interface ConstraintMvnoNameFilterProps {
  nameData: string;
  setNameData: React.Dispatch<React.SetStateAction<string>>;
}

export const ConstraintMvnoNameFilter: FC<ConstraintMvnoNameFilterProps> = ({
  nameData,
  setNameData,
}) => {
  const handleChange = (value: string) => {
    setNameData(value);
  };
  return (
    <TextField
      label="통신사 검색"
      fullWidth
      onChange={(event) => handleChange(event.target.value)}
      value={nameData}
    />
  );
};
