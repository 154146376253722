import { Typography } from "@mui/material";
import { ElementType, PropsWithChildren } from "react";

export function PageTitle({ children, component = 'h4' } : PropsWithChildren<{ component?: ElementType<any> }>) {
  return (
    <Typography 
      variant="h5"
      sx={{ padding: '16px 0', fontWeight: 500 }}
      component={component}
    >
      {children}
    </Typography>
  )
}