import React, { useEffect, useState } from 'react';
import apiService from '../../service/api.service';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import AddPlansPopup from './AddPlansPopup';

export default function EventDetailGift({ detail, mvno }) {
  const [plans, setPlans] = useState(detail.plans);
  const [planGroups, setPlanGroups] = useState([]);

  const planColumns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '60px',
    },
    {
      name: 'MVNO',
      selector: (row) => row.mvno,
      sortable: true,
    },
    {
      name: '요금제 이름',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '350px',
    },
    {
      name: 'MNO',
      selector: (row) => row.mno,
      sortable: true,
    },
    {
      name: '망',
      selector: (row) => row.net,
      sortable: true,
    },
    {
      name: '가격',
      selector: (row) => row.fee,
      sortable: true,
    },
    {
      name: '삭제',
      cell: (row, index, column, id) => (
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => removePlanEventRelation(row.id, detail.id)}
        >
          삭제
        </Button>
      ),
      center: true,
    },
  ];
  const onAddPlans = (newPlans) => {
    setPlans(removeDuplicate(plans.concat(newPlans)));
  };

  const onAddPlansGroup = (newPlans) => {
    setPlanGroups(removeDuplicate(planGroups.concat(newPlans)));
  };

  const [showAddPlansPopup, setShowAddPlansPopup] = useState(false);
  const [initCategoryRender, setInitCategoryRender] = useState(true);
  const [initCategoryGroupRender, setInitCategoryGroupRender] = useState(true);
  const [initCategoryGroupApplyRender, setInitCategoryGroupApplyRender] =
    useState(true);

  useEffect(() => {
    if (initCategoryRender) {
      setInitCategoryRender(false);
      return;
    }
    savePlans();
  }, [plans]);

  useEffect(() => {
    if (initCategoryGroupRender) {
      setInitCategoryGroupRender(false);
      return;
    }
    savePlanGroups();
  }, [planGroups]);

  const closeAddPlansPopup = () => {
    setShowAddPlansPopup(false);
  };

  const addPlansButton = (
    <Button
      key="addPlans"
      variant="outline-primary"
      size="sm"
      onClick={() => setShowAddPlansPopup(true)}
    >
      요금제 추가
    </Button>
  );

  const convertStrObject = (str) => {
    let obj = [];

    str.split(',').forEach((value) => obj.push(value));

    return obj;
  };

  const removeDuplicate = (arr) => {
    let temp = {};

    arr.forEach((plan) => {
      temp[plan.id] = plan;
    });

    return Object.keys(temp).map((k) => temp[k]);
  };

  const savePlans = async () => {
    try {
      await apiService.saveEventPlansRelation(
        detail.id,
        plans.map((plan) => plan.id),
      );

      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };

  const savePlanGroups = async () => {
    try {
      await apiService.createPlansGroup({
        mvno: planGroups[0].mvno,
        title: `그룹 ${planGroups.length} ${planGroups[0].name}`,
        planGroups: Array.from(planGroups.map((plan) => plan.id)).join(','),
      });

      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };

  const removePlanEventRelation = async (id, detailId) => {
    alert(detailId);

    if (window.confirm('삭제하시겠습니까?')) {
      try {
        const response = await apiService.deleteEventRelation(id, detailId);
        if (response.data.result) {
          alert('삭제되었습니다');
        }
        setPlans(plans.filter((plan) => plan.id !== id));
      } catch (err) {
        console.debug(err);
      }
    }
  };

  return (
    <>
      <DataTable
        title={detail.description + ' 주는 요금제 목록'}
        columns={planColumns}
        data={detail.plans}
        pagination
        actions={[addPlansButton]}
      />
      {showAddPlansPopup && (
        <AddPlansPopup
          detail={detail}
          show={showAddPlansPopup}
          onHide={closeAddPlansPopup}
          mvnoList={Array(mvno)}
          onAddPlans={onAddPlans}
          onAddPlansGroup={onAddPlansGroup}
          mvno={mvno}
        />
      )}
    </>
  );
}
