import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { EventExposedArea, Mnos, Mvnos } from '../../common/constants';
import apiService from '../../service/api.service';

export default function EventEdit() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const { register, reset, watch, handleSubmit, getValues } = useForm({});
  const watchImage = watch('thumbnailImageUrl');
  const watchManualImage = watch('manualThumbnailImageUrl');

  const [reserveAddDate, setReserveAddDate] = useState(new Date());

  const fetchEvent = async () => {
    if (!eventId) {
      setEvent(null);
      return;
    }

    try {
      const response = await apiService.getEvent(eventId);

      if (response && response.data) {
        setEvent(response.data);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  const [startDate, setStartDate] = useState(
    DateTime.local({ zone: 'Asia/Seoul' }),
  );
  const [endDate, setEndDate] = useState(
    DateTime.local({ zone: 'Asia/Seoul' }),
  );

  useEffect(() => {
    console.log('event', event);
    if (!event) return;

    if (event.startDate) {
      setStartDate(
        DateTime.local(
          event.startDate[0],
          event.startDate[1],
          event.startDate[2],
          0,
          0,
          { zone: 'Asia/Seoul' },
        ),
      );
    }
    if (event.endDate) {
      setEndDate(
        DateTime.local(
          event.endDate[0],
          event.endDate[1],
          event.endDate[2],
          0,
          0,
          { zone: 'Asia/Seoul' },
        ),
      );
    }

    reset({
      title: event.title,
      mvno: event.mvno,
      link: event.link,
      exposedArea: event.exposedArea,
      manualLink: event.manualLink,
      thumbnailImageUrl: event.thumbnailImageUrl,
      manualThumbnailImageUrl: event.manualThumbnailImageUrl,
    });
  }, [event]);

  const onSubmit = async (data) => {
    const requestBody = {
      ...data,
      startDay: startDate.toFormat('yyyy-LL-dd'),
      endDay: endDate.toFormat('yyyy-LL-dd'),
    };

    try {
      let response;
      if (eventId) {
        response = await apiService.editEvent(eventId, requestBody);
      } else {
        // manual link 들이 '' 으로 들어가서 저장되는 문제
        if (requestBody.manualLink === '') {
          requestBody.manualLink = requestBody.link;
        }
        if (requestBody.manualThumbnailImageUrl === '') {
          requestBody.manualThumbnailImageUrl = requestBody.thumbnailImageUrl;
        }

        response = await apiService.createEvent(requestBody);
      }

      if (response && response.data) {
        navigate(`/events/${response.data.id}`);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  const handleReserveAddSubmit = async () => {
    if (window.confirm('이벤트를 예약 추가 하시겠습니까?')) {
      const requestBody = {
        ...getValues(),
        startDay: startDate.toFormat('yyyy-LL-dd'),
        endDay: endDate.toFormat('yyyy-LL-dd'),
      };

      if (!requestBody.title) {
        window.alert('제목을 입력해야 합니다.');
        return;
      }

      if (!requestBody.link && !requestBody.manualLink) {
        window.alert('이벤트 페이지 링크를 입력해야 합니다.');
        return;
      }

      if (
        !requestBody.thumbnailImageUrl &&
        !requestBody.manualThumbnailImageUrl
      ) {
        window.alert('이벤트 이미지 URL을 입력해야 합니다.');
        return;
      }

      const year = reserveAddDate.getFullYear();
      const month = String(reserveAddDate.getMonth() + 1).padStart(2, '0');
      const date = String(reserveAddDate.getDate()).padStart(2, '0');
      await apiService.reserveAddEvent(requestBody, `${year}-${month}-${date}`);

      window.alert('예약되었습니다.');
      navigate('/events');
    }
  };

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <Container className="w-75">
          <h3>이벤트 {eventId ? '편집' : '생성'}</h3>
          <hr />
          <Form onSubmit={handleSubmit(onSubmit)}>
            {eventId && (
              <Form.Group className="mb-3">
                <Form.Label>Event Id</Form.Label>
                <Form.Control defaultValue={eventId} disabled />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>제목</Form.Label>
              <Form.Control
                {...register('title', {
                  required: true,
                })}
                placeholder="이벤트 제목을 입력해주세요"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>통신사</Form.Label>
              <Form.Select
                {...register('mvno', {
                  required: true,
                })}
              >
                {Object.keys(Mvnos).map((key) => {
                  const mvno = Mvnos[key];
                  return (
                    <option key={mvno.value} value={mvno.value}>
                      {mvno.name}
                    </option>
                  );
                })}
                {Object.entries(Mnos).map(([key, mno]) => (
                  <option key={mno.value} value={mno.value}>
                    {mno.name}
                  </option>
                ))}
                <option key={'all'} value={'all'}>
                  {'전체'}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>노출영역</Form.Label>
              <Form.Select
                {...register('exposedArea', {
                  required: true,
                })}
              >
                {Object.keys(EventExposedArea).map((key) => {
                  const exposedArea = EventExposedArea[key];
                  return (
                    <option key={exposedArea.value} value={exposedArea.value}>
                      {exposedArea.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>링크</Form.Label>
              <Form.Control
                {...register('link', {
                  required: true,
                })}
                type="url"
                placeholder="이벤트 페이지 링크를 입력해주세요"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>수동링크</Form.Label>
              <Form.Control
                {...register('manualLink', {
                  required: false,
                })}
                type="url"
                placeholder="스크래핑 된 링크말고 직접 입력하고 싶은 경우 URL을 입력해주세요"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>이미지 URL</Form.Label>
              <Form.Control
                {...register('thumbnailImageUrl', {
                  required: true,
                })}
                type="url"
                placeholder="이벤트 이미지 URL을 입력해주세요"
              />
              {watchImage && <img className="mt-2" src={watchImage} alt="" />}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>수동 등록 이미지 URL</Form.Label>
              <Form.Control
                {...register('manualThumbnailImageUrl', {
                  required: false,
                })}
                type="url"
                placeholder="스크래핑 된 이미지말고 직접 입력하고 싶은 경우 URL을 입력해주세요"
              />
              {watchManualImage && (
                <img className="mt-2" src={watchManualImage} alt="" />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>시작일</Form.Label>
              <DatePicker
                selected={startDate.toJSDate()}
                onChange={(date) =>
                  setStartDate(DateTime.fromJSDate(date).setZone('Asia/Seoul'))
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>종료일</Form.Label>
              <DatePicker
                selected={endDate.toJSDate()}
                onChange={(date) =>
                  setEndDate(DateTime.fromJSDate(date).setZone('Asia/Seoul'))
                }
              />
            </Form.Group>

            <div className="d-flex flex-row-reverse">
              <Button type="submit" variant="outline-primary">
                저장하기
              </Button>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginRight: '0.5rem',
                }}
              >
                <DatePicker
                  selected={reserveAddDate}
                  onChange={(date) => setReserveAddDate(date)}
                ></DatePicker>
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  variant="primary"
                  onClick={handleReserveAddSubmit}
                >
                  예약 추가
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </div>
    </>
  );
}
