import { AxiosResponse } from 'axios';
import api, { adminApi, alarmApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import {
  MobilePlanOrderListDto
} from '../entity/order';

export interface MailTemplateDto {
  name: string;
  date: string; // Date
}

class MailApiService {
  // contact@moyoplan.com
  sendMails(
    mails: { title: string; senderMail: string; content: string; receiverMail: string }[]
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrderListDto>>> {
    return alarmApi.post(`/mail`, mails, {
      withCredentials: true,
    });
  }

  saveMailTemplate(file: File) {
    const formData = new FormData();
    formData.append('files', file);

    return adminApi.post('mail/template', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getMailTemplateList(): Promise<AxiosResponse<ApiResponse<MailTemplateDto[]>>> {
    return adminApi.get('mail/template');
  }

  getMailTemplate(fileName: string): Promise<AxiosResponse<ArrayBuffer>> {
    return adminApi.get(`mail/template/${fileName}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  }

  postMailTemplate(file: File): Promise<AxiosResponse<ApiResponse<{
    paths: string[],
    isSucceed: boolean,
  }>>> {
    const formData = new FormData();
    formData.append('files', file);

    return adminApi.post('mail/template', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteMailTemplate(fileName: string): Promise<AxiosResponse<ApiResponse<boolean>>> {
    return adminApi.delete(`mail/template/${fileName}`);
  }
}

export const mailApiService = new MailApiService();
