import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';

interface SelectBoxProps<T = any> {
  id: string;
  label: string;
  value: SelectProps<T>['value'];
  onChange: SelectProps<T>['onChange'];
  items: Array<{ value: string; label: string } | string>;
  sx?: FormControlProps['sx'];
}

export function SelectBox({
  id,
  label,
  value,
  onChange,
  items,
  ...rootProps
}: SelectBoxProps) {
  return (
    <FormControl {...rootProps}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
      >
        {items.map((item) =>
          typeof item === 'string' ? (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ) : (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
}
