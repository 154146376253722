import qs from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigateWithParams(keys: string[]) {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (pathname: string) => {
      const search = qs.pick(location.search, keys);
      navigate(`${pathname}${search}`);
    },
    [keys, location.search, navigate],
  );
}
