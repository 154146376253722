import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { handleApi } from '../../../common/http_util';
import { getMoyoOnlyPlanInfo } from '../../../apis/service/moyo-only';

export const useGetMoyoOnlyPlanInfo = (requestId: number) => {
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ['moyo', 'only', 'plan', 'info'],
    queryFn: () => {
      const response = handleApi(
        () => getMoyoOnlyPlanInfo({ requestId: requestId }),
        navigate,
      );
      return response;
    },
  });
  return {
    plan: data,
  };
};
