import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import apiService from '../../service/api.service';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export default function PlanDetailInfo() {
  const { planId } = useParams();
  const [plan, setPlan] = useState(null);
  const [planDetail, setPlanDetail] = useState(null);
  const { register, getValues, reset, watch, handleSubmit, control } = useForm(
    {},
  );

  useEffect(() => {
    fetchPlan();
    fetchPlanDetail();
  }, []);

  useEffect(() => {
    if (!planDetail) return;

    reset({
      usimFree: planDetail.usimFree,
      usimFee: planDetail.usimFee,
      usimFreeCondition: planDetail.usimFreeCondition,
      nfc: planDetail.nfc,
      nfcCondition: planDetail.nfcCondition,
      nfcFee: planDetail.nfcFee,
      selfOpening: planDetail.selfOpening,
      tethering: planDetail.tethering,
      tetheringAmount: planDetail.tetheringAmount,
      tetheringDailyAmount: planDetail.tetheringDailyAmount,
      dataSharing: planDetail.dataSharing,
      familyCombine: planDetail.familyCombine,
      internetCombine: planDetail.internetCombine,
      microPayment: planDetail.microPayment,
      roaming: planDetail.roaming,
    });
  }, [planDetail]);

  useEffect(() => {
    if (!plan) return;

    reset({
      mvno: plan.mvno,
      mno: plan.mno,
    });
  }, [plan]);
  const fetchPlan = async () => {
    if (!planId) return;

    try {
      const res = await apiService.getPlanById(planId);

      if (res && res.data && res.data.result) {
        setPlan(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    const requestBody = {
      ...getValues(),
      ...planDetail,
    };

    Object.keys(requestBody).forEach(function (key) {
      if (requestBody[key] === '') {
        requestBody[key] = null;
      }
    });

    try {
      if (planId) {
        await apiService.editPlanDetailById(planId, requestBody);
        alert('저장되었습니다');
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPlanDetail = async () => {
    console.log(planId);
    if (!planId) return;

    try {
      const res = await apiService.getPlanDetailById(planId);

      console.log(res.data.result);
      if (res && res.data && res.data.result) {
        setPlanDetail(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openMoyo = () => {
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/plans/${planId}`,
      '_blank',
    );
  };
  return (
    <>
      <div
        className="mt-3 pb-5"
        style={{ marginLeft: '2rem', marginRight: '2rem' }}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>요금제 상세 정보</h3>
          <div style={{ display: 'inline' }}>
            <Button
              className="me-3"
              variant="outline-primary"
              onClick={onSubmit}
            >
              저장
            </Button>
            <Button className="me-3" variant="outline-info" onClick={openMoyo}>
              모요페이지확인
            </Button>
          </div>
        </div>
        <div>
          <h5>
            <b>
              {plan?.mvno}-{plan?.mno}
            </b>{' '}
            에 적용되는 상세 정보
          </h5>
          <p>요금제 이름 : {plan?.name}</p>
        </div>
        <hr />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>유심 무료 여부</Form.Label>
                <Controller
                  control={control}
                  name="usimFree"
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>유심 무료 조건</Form.Label>
                <Controller
                  name="usimFreeCondition"
                  control={control}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>유심 가격</Form.Label>
                <Controller
                  name="usimFee"
                  control={control}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>NFC 지원여부</Form.Label>
                <Controller
                  name="nfc"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>NFC 지원 조건</Form.Label>
                <Controller
                  name="nfcCondition"
                  control={control}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>NFC 가격</Form.Label>
                <Controller
                  name="nfcFee"
                  control={control}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>테더링 지원 여부</Form.Label>
                <Controller
                  name="tethering"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      checked={watch('tethering')}
                      type={'switch'}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>월 테더링 제공량</Form.Label>
                <Controller
                  name="tetheringAmount"
                  control={control}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>일 테더링 제공량</Form.Label>
                <Controller
                  name="tetheringDailyAmount"
                  control={control}
                  render={({ field }) => <Form.Control {...field} />}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>데이터쉐어링 지원 여부</Form.Label>
                <Controller
                  name="dataSharing"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>가족결합</Form.Label>
                <Controller
                  name="familyCombine"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>인터넷결합</Form.Label>
                <Controller
                  name="internetCombine"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>셀프개통 지원여부</Form.Label>
                <Controller
                  name="selfOpening"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>소액 결제</Form.Label>
                <Controller
                  name="microPayment"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>해외 로밍</Form.Label>
                <Controller
                  name="roaming"
                  control={control}
                  render={({ field }) => (
                    <Form.Check {...field} type={'switch'} />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
