import DataTable from 'react-data-table-component';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FilterComponent } from '../component/FilterComponent';
import apiService from '../service/api.service';
import AddPlansPopup from './events/AddPlansPopup';
import { makeThumbnailSrc } from '../common/mvno-util';
import { Mvnos } from '../common/constants';

export default function PlanContractList() {
  const [plans, setPlans] = useState([]);
  const [mvnos, setMvnos] = useState([]);
  const [showAddPlansPopup, setShowAddPlansPopup] = useState(false);
  const closeAddPlansPopup = () => {
    setShowAddPlansPopup(false);
  };

  const removePlan = (id) => {
    apiService
      .removeContractPlan(id)
      .then(setPlans(plans.filter((plan) => plan.id !== id)));
  };
  const onAddPlans = (newPlans) => {
    apiService
      .addContractPlan(newPlans.map((plan) => plan.id))
      .then(setPlans(removeDuplicate(plans.concat(newPlans))));
  };
  const removeDuplicate = (arr) => {
    let temp = {};

    arr.forEach((plan) => {
      temp[plan.id] = plan;
    });

    return Object.keys(temp).map((k) => temp[k]);
  };
  const planColumns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '60px',
    },
    {
      name: 'MVNO',
      selector: (row) =>
        Object.values(Mvnos).find(
          (e, key) => e.value.toLowerCase() === row.mvno.toLowerCase(),
        ).name,
      sortable: true,
    },
    {
      name: '요금제 이름',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '350px',
    },
    {
      name: 'MNO',
      selector: (row) => row.mno,
      sortable: true,
    },
    {
      name: '망',
      selector: (row) => row.net,
      sortable: true,
    },
    {
      name: '가격',
      selector: (row) => row.fee,
      sortable: true,
    },
    {
      name: '삭제',
      cell: (row, index, column, id) => (
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => removePlan(row.id)}
        >
          삭제
        </Button>
      ),
      center: true,
    },
  ];
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [initCategoryRender, setInitCategoryRender] = useState(true);
  const [initCategoryMvnoRender, setInitCategoryMvnoRender] = useState(true);

  useEffect(() => {
    fetchContractPlans();
    fetchContractMvnos();
  }, []);

  useEffect(() => {
    if (initCategoryRender) {
      setInitCategoryRender(false);
    }
  }, [plans]);

  useEffect(() => {
    if (initCategoryMvnoRender) {
      setInitCategoryMvnoRender(false);
    }
  }, [mvnos]);

  const fetchContractMvnos = async () => {
    const response = await apiService.getContractMvnos();
    console.log(response);
    if (response && response.data) {
      setMvnos(response.data.result);
    }
  };

  const fetchContractPlans = async () => {
    const response = await apiService.getContractPlans();
    console.log(response);
    if (response && response.data) {
      setPlans(response.data.result.list);
    }
  };

  const addPlansButton = (
    <Button
      key="addPlans"
      variant="outline-primary"
      size="sm"
      onClick={() => setShowAddPlansPopup(true)}
    >
      요금제 추가
    </Button>
  );

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <>
      <div className="container-md mt-3 pb-5">
        <h5>제휴 업체 목록</h5>
        {mvnos.map((mvno) => (
          <div className="img_container">
            <img alt="모두의 요금제, 모요" src={makeThumbnailSrc(mvno)} />
          </div>
        ))}
        <DataTable
          title={'제휴 업체 추천 요금제(홈노출)'}
          columns={planColumns}
          data={plans}
          pagination
          paginationPerPage={25}
          actions={[addPlansButton]}
        />
      </div>
      {showAddPlansPopup && (
        <AddPlansPopup
          detail={plans[0]}
          show={showAddPlansPopup}
          onHide={closeAddPlansPopup}
          mvnoList={mvnos}
          onAddPlans={onAddPlans}
        />
      )}
    </>
  );
}
