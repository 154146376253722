import { Button, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Main } from '../../../component/Main';
import {
  npsService,
  NpsTemplateItemType,
  SurveyTemplate,
} from '../../../service/npsService';
import { v4 as uuidv4 } from 'uuid';
import { useLoading } from '../../../context/LoadingContext';

const DEFAULT_TEMPLATE: SurveyTemplate = {
  id: 0,
  name: '',
  regTs: '',
  updTs: '',
  content: [],
};

const NpsLinearTemplate: FC<{ index: number; onClickRemove: () => void }> = ({
  index,
  onClickRemove,
}) => {
  const { register, watch } = useFormContext<SurveyTemplate>();

  return (
    <div>
      <Typography variant="body1">
        점수 정보 ( nps여부 :
        <Checkbox
          checked={!!watch(`content.${index}.isNps`)}
          {...register(`content.${index}.isNps`)}
        />
        )
      </Typography>
      <Grid container mt={1}>
        <TextField
          {...register(`content.${index}.template.title`)}
          label="제목"
        />
        <TextField
          {...register(`content.${index}.template.minDescription`)}
          label="0점 안내"
        />
        <TextField
          {...register(`content.${index}.template.maxDescription`)}
          label="10점 안내"
        />
        <Button onClick={onClickRemove}>삭제</Button>
      </Grid>
    </div>
  );
};

const NpsImageTemplate: FC<{ index: number; onClickRemove: () => void }> = ({
  index,
  onClickRemove,
}) => {
  const { register } = useFormContext<SurveyTemplate>();

  return (
    <div>
      <Typography variant="body1">이미지 정보</Typography>
      <Grid container mt={1}>
        <TextField
          {...register(`content.${index}.template.title`)}
          label="제목"
        />
        <TextField
          {...register(`content.${index}.template.path`)}
          label="이미지 url"
        />
        <Button onClick={onClickRemove}>삭제</Button>
      </Grid>
    </div>
  );
};

const NpsLongTextTemplate: FC<{ index: number; onClickRemove: () => void }> = ({
  index,
  onClickRemove,
}) => {
  const { register } = useFormContext<SurveyTemplate>();

  return (
    <div>
      <Typography variant="body1">서술형 정보</Typography>
      <Grid container mt={1}>
        <TextField
          {...register(`content.${index}.template.title`)}
          label="제목"
        />
        <TextField
          {...register(`content.${index}.template.placeholder`)}
          label="placeholder"
        />
        <Button onClick={onClickRemove}>삭제</Button>
      </Grid>
    </div>
  );
};

const NpsMultipleChoiceTemplate: FC<{
  index: number;
  onClickRemove: () => void;
}> = ({ index, onClickRemove }) => {
  const { register, watch } = useFormContext<SurveyTemplate>();
  const options = useMemo(() => {
    return watch(`content.${index}.template.options`);
  }, []);

  return (
    <div>
      <Typography variant="body1">객관식 정보</Typography>
      <Grid container mt={1}>
        <TextField
          {...register(`content.${index}.template.title`)}
          label="제목"
        />
        <Grid container>
          {options.map(({ value }, index2) => (
            <TextField
              key={value}
              {...register(`content.${index}.template.options.${index2}.text`)}
              label={`질문 ${index2 + 1}`}
            />
          ))}
        </Grid>
        <Button onClick={onClickRemove}>삭제</Button>
      </Grid>
    </div>
  );
};

const NpsTemplateCreatePage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const form = useForm<SurveyTemplate>({ defaultValues: DEFAULT_TEMPLATE });
  const { setLoading } = useLoading();
  const { register, control, reset, watch, getValues } = form;
  const { append, remove } = useFieldArray({
    control,
    name: 'content',
  });

  useEffect(() => {
    if (!id) return;
    npsService.getSurveyTemplate(Number(id)).then((rs) => {
      reset({
        ...(rs.data.result ?? DEFAULT_TEMPLATE),
      });
    });
  }, [id]);

  console.log(watch('content'));
  const appendLinearTemplate = () => {
    append({
      template: {
        title: '',
        minDescription: '',
        maxDescription: '',
      },
      uuid: uuidv4(),
      type: NpsTemplateItemType.LINEAR,
    });
  };

  const appendImageTemplate = () => {
    append({
      template: {
        title: '',
        path: '',
      },
      uuid: uuidv4(),
      type: NpsTemplateItemType.IMAGE,
    });
  };

  const appendLongTextTemplate = () => {
    append({
      template: {
        placeholder: '',
        title: '',
      },
      uuid: uuidv4(),
      type: NpsTemplateItemType.LONG_TEXT,
    });
  };

  const appendMultipleChoiceTemplate = () => {
    append({
      template: {
        title: '',
        options: Array.from({ length: 5 }).map(() => ({
          text: '',
          value: uuidv4(),
        })),
      },
      uuid: uuidv4(),
      type: NpsTemplateItemType.MULTIPLE_CHOICE,
    });
  };

  const onSaveTemplateHandler = async () => {
    setLoading(true);
    try {
      if (id) {
        await updateTemplate();
      } else {
        await postTemplate();
      }
      navigate('/nps/template');
    } catch (e) {
      alert('템플릿을 생성하는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const postTemplate = async () => {
    await npsService.postSurveyTemplate(
      getValues('name'),
      getValues('content') ?? [],
    );
  };

  const updateTemplate = async () => {
    await npsService.putSurveyTemplate(
      Number(id!),
      getValues('name'),
      getValues('content') ?? [],
    );
  };

  const onClickDelete = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    setLoading(true);
    try {
      await npsService.deleteSurveyTemplate(Number(id));
      navigate('/nps/template');
    } catch (e) {
      alert('NPS를 생성하는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Main>
        <Grid container justifyContent="space-between">
          <Typography variant="h5">템블릿 {!!id ? '수정' : '생성'}</Typography>
          <div>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button variant="outlined" onClick={onSaveTemplateHandler}>
                  {!!id ? '수정하기' : '생성하기'}
                </Button>
              </Grid>
              <Grid item>
                {!!id && (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={onClickDelete}
                  >
                    삭제하기
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <FormProvider {...form}>
          <Grid mt={2} container>
            <TextField
              {...register('name')}
              variant="standard"
              key={watch('id')}
              label="제목"
              placeholder="제목"
              fullWidth
            />
            <Grid item container spacing={1} mt={2} py={1}>
              {(watch('content') ?? []).map((item, index) => {
                const onClickRemoveHandler = () => {
                  remove(index);
                };
                switch (item.type) {
                  case NpsTemplateItemType.IMAGE:
                    return (
                      <Grid item width="100%">
                        <NpsImageTemplate
                          key={item.uuid}
                          index={index}
                          onClickRemove={onClickRemoveHandler}
                        />
                      </Grid>
                    );
                  case NpsTemplateItemType.LINEAR:
                    return (
                      <Grid item width="100%">
                        <NpsLinearTemplate
                          key={item.uuid}
                          index={index}
                          onClickRemove={onClickRemoveHandler}
                        />
                      </Grid>
                    );
                  case NpsTemplateItemType.LONG_TEXT:
                    return (
                      <Grid item width="100%">
                        <NpsLongTextTemplate
                          key={item.uuid}
                          index={index}
                          onClickRemove={onClickRemoveHandler}
                        />
                      </Grid>
                    );
                  case NpsTemplateItemType.MULTIPLE_CHOICE:
                    return (
                      <Grid item width="100%">
                        <NpsMultipleChoiceTemplate
                          key={item.uuid}
                          index={index}
                          onClickRemove={onClickRemoveHandler}
                        />
                      </Grid>
                    );
                  default:
                    return <div></div>;
                }
              })}
            </Grid>
          </Grid>
          <Grid>
            <Button onClick={appendLinearTemplate}>점수 추가</Button>
            <Button onClick={appendLongTextTemplate}>서술형 추가</Button>
            <Button onClick={appendImageTemplate}>이미지 추가</Button>
            <Button onClick={appendMultipleChoiceTemplate}>객관식 추가</Button>
          </Grid>
        </FormProvider>
      </Main>
    </>
  );
};

export default NpsTemplateCreatePage;
