import { Tab, TabProps, Tabs, styled } from '@mui/material';
import { Suspense, SyntheticEvent } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Main } from '../../component/Main';
import { PageTitle } from '../../component/PageTitle';
import { Spacing } from '../../component/Spacing';
import { SettlementAlliances } from './SettlementAlliances';
import { SettlementCoupons } from './SettlementCoupons';
import { SettlementInfluencers } from './SettlementInfluencers';
import { SettlementReferrals } from './SettlementReferrals';
import { SettlementSummary } from './SettlementSummary/SettlementSummary';
import { SettlementSubCategoryLayout } from './components/SettlementSubCategoryLayout';
import { useNavigateWithParams } from './hooks/useNavigateWithParams';
import { YEAR_MONTH_QUERY_KEY } from './hooks/useSettlementPeriod';
import {
  SETTLEMENT_MAIN_PATH,
  SettlementRoutes,
  SettlementSubRoutes,
  settlementSubCategoriesByRoutes,
  settlementTabs,
} from './routes';
import Spinner from '../../component/Spinner';

export function SettlementIndexPage() {
  const params = useParams();
  const paths =
    params['*'] != null && params['*'].length > 0 ? params['*'].split('/') : [];
  const category = paths[0] ?? SettlementRoutes.Summary;
  const subCategory =
    paths[1] ??
    settlementSubCategoriesByRoutes[category as SettlementRoutes][0] ??
    '';

  const navigate = useNavigateWithParams([YEAR_MONTH_QUERY_KEY]);
  const handleChange = (_: SyntheticEvent, category: SettlementRoutes) => {
    const subCategory =
      settlementSubCategoriesByRoutes[category].length > 0
        ? `/${settlementSubCategoriesByRoutes[category][0]}`
        : '';
    navigate(`/${SETTLEMENT_MAIN_PATH}/${category}${subCategory}`);
  };

  return (
    <div>
      <Main>
        <PageTitle component="h2">정산 관리</PageTitle>
        <Spacing size={16} />
        <Suspense fallback={<Spinner isShow />}>
          <Tabs
            value={category}
            sx={{ minHeight: 'initial' }}
            onChange={handleChange}
          >
            {settlementTabs.map(({ label, value }) => (
              <MainHeaderTab key={value} label={label} value={value} />
            ))}
          </Tabs>
          <Routes>
            <Route index element={<SettlementSummary />} />
            <Route
              path={`${SettlementRoutes.Summary}`}
              element={<SettlementSummary />}
            />
            <Route
              element={
                <SettlementSubCategoryLayout
                  category={category}
                  subCategory={subCategory}
                />
              }
            >
              <Route
                path={`${SettlementRoutes.레퍼러}/*`}
                element={
                  <SettlementReferrals
                    category={category}
                    subCategory={subCategory as SettlementSubRoutes}
                  />
                }
              />
              <Route
                path={`${SettlementRoutes.제휴채널}/*`}
                element={
                  <SettlementAlliances
                    category={category}
                    subCategory={subCategory as SettlementSubRoutes}
                  />
                }
              />
              <Route
                path={`${SettlementRoutes.인플루언서}/*`}
                element={
                  <SettlementInfluencers
                    category={category}
                    subCategory={subCategory as SettlementSubRoutes}
                  />
                }
              />
              <Route
                path={`${SettlementRoutes.쿠폰}/*`}
                element={
                  <SettlementCoupons
                    category={category}
                    subCategory={subCategory as SettlementSubRoutes}
                  />
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </Main>
    </div>
  );
}

const MainHeaderTab = styled(Tab)<TabProps>({
  textTransform: 'none',
  padding: '9px 16px',
  minHeight: 'initial',
  lineHeight: '24px',
});
