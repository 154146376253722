export default function Spinner({ isShow }) {
  return (
    <>
      {isShow === true && (
        <div className="spinner_wrapper">
          <div className="spinner_img">
            <img src={'/static/img/rainbow.gif'} />
          </div>
        </div>
      )}
    </>
  );
}
