import { KeyAndValueTypes } from '../layout/create-plan/type';

export const MnoList: KeyAndValueTypes[] = [
  {
    key: 'lg',
    value: 'LGU',
  },
  {
    key: 'kt',
    value: 'KT',
  },
  {
    key: 'skt',
    value: 'SKT',
  },
];
