import {
  Paper
} from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { PageTitle } from '../../../component/PageTitle';
import { settlementApiService } from '../../../service/settlementSevice';
import { Section } from '../components/Section';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';

import { formatPhoneNumber, showFallback } from '../data-grid-utils';


export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'id',
    width: 50,
    sortable: false,
  },
  {
    field: 'userId',
    headerName: '유저 ID',
    width: 124,
    sortable: false,
  },
  {
    field: 'name',
    headerName: '이름',
    width: 100,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'phoneNumber',
    headerName: '전화번호',
    width: 124,
    sortable: false,
    valueFormatter: formatPhoneNumber,
  },
  {
    field: 'registrationNumber',
    headerName: '주민번호',
    width: 150,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'idCardCopyLink',
    headerName: '신분증 링크',
    width: 250,
    valueGetter: showFallback,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noreferrer">{params.value}</a>
    )  
  },
  {
    field: 'accountNumber',
    headerName: '계좌 번호',
    width: 300,
    valueGetter: showFallback,
  },
];

export function TaxAccountSection() {
  const { endDate, startDate } = useSettlementPeriod();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'createdAt', sort: 'asc' },
  ]);

  const { data, isLoading } = useQuery(
    [
      'settlement-user-rewards-tax-account',
      endDate,
      startDate,
      page,
      pageSize,
      sortModel[0],
    ],
    () =>
      settlementApiService.getRewardsByUserTaxAccount({
        endDate,
        startDate,
        page,
        size: pageSize,
        sort:
          sortModel[0] != null
            ? `${sortModel[0].field},${sortModel[0].sort}`
            : undefined,
      }),
  );


  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const rowCount = data?.data.result?.totalSize ?? 0;
  const rows = data?.data.result?.list ?? [];

  return (
    <Section>
      <PageTitle>제세공과금 대상자</PageTitle>
      <Paper sx={{ height: 700, width: '100%', padding: '16px' }}>
        <DataGrid
          rowCount={rowCount}
          columns={columns}
          rows={rows}
          sx={{ border: 'none' }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading}
          pagination
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </Paper>
    </Section>
  );
}

