import React, { useEffect } from 'react';
import {
  FormControl,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';

type TetheringServiceProps = {
  tethering: boolean;
  tetheringAmount: number | null;
  tetheringDailyAmount: number | null;
};

export const TetheringService: React.FC<TetheringServiceProps> = ({
  tethering,
  tetheringAmount,
  tetheringDailyAmount,
}) => {
  const { control, watch, setValue } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <Grid item xs={12} md={6}>
      <div>
        <Typography variant="h6" my={2} mt={'30px'}>
          부가 서비스
        </Typography>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <Grid container>
            <Grid item sx={{ display: 'flex' }}>
              <Typography sx={{ m: 'auto' }} variant="subtitle1">
                핫스팟 지원
              </Typography>
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="tethering"
                defaultValue={tethering}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} />
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
        {watch('tethering') && (
          <>
            <Typography variant="subtitle1">핫스팟 제공량</Typography>
            <Grid container sx={{ gap: '0.5rem' }}>
              <FormControl sx={{ mt: 1, width: 120 }}>
                <Controller
                  control={control}
                  defaultValue={tetheringAmount}
                  name="tetheringAmount"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      label="월 제공량"
                      variant="outlined"
                      type="number"
                      error={watch('tetheringAmount') === null ? true : false}
                      onChange={(event) =>
                        field.onChange(parseInt(event.target.value))
                      }
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mt: 1, width: 120 }}>
                <Controller
                  control={control}
                  defaultValue={tetheringDailyAmount}
                  name="tetheringDailyAmount"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="일 제공량"
                      variant="outlined"
                      type="number"
                      error={
                        watch('tetheringDailyAmount') === null ? true : false
                      }
                      onChange={(event) =>
                        field.onChange(parseInt(event.target.value))
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        )}
      </div>
    </Grid>
  );
};
