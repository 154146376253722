import {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ClickAwayListener,
  Collapse as MaterialCollapse,
  Divider,
  List,
  ListItemButton as MaterialListItemButton,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ListItemButton = styled(MaterialListItemButton)(({ theme }) => ({
  padding: '20px 26px',
  height: '56px',
  '&.active': {
    fontWeight: 'bold',
    borderRight: `3px solid ${theme.palette.text.primary}`,
  },
  '&:hover': {
    fontWeight: 'bold',
    borderRight: `3px solid ${theme.palette.text.secondary}`,
  },
}));

const Collapse = styled(MaterialCollapse)(({ theme }) => ({
  background: 'rgb(250, 250, 250)',
  '.MuiList-root': {
    padding: 16,
  },
}));

const CollapseListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: '12px 10px',
  height: '40px',
  borderRadius: '4px',
  marginBottom: '4px',
  '&:last-child': {
    marginBottom: 0,
  },
  '&.active': {
    fontWeight: 'bold',
    background: theme.palette.action.selected,
  },
  '&:hover': {
    fontWeight: 'bold',
    background: theme.palette.action.hover,
  },
}));

export interface NavigationTab {
  name: string;
  collapse?: NavigationTab[];
  path?: string;
  icon?: ReactNode;
  innerItem?: ReactNode;
}

interface ListFrameProps {
  navigationTab: NavigationTab;
  activePath: string;
  foldedNavigation?: boolean;
}

const DrawerNavigationListItem: FC<ListFrameProps> = ({
  navigationTab,
  activePath,
  foldedNavigation,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(isActivePath());
  const [fold, setFold] = useState(!isActivePath());
  const activeClass = useMemo(
    () => (active || !fold ? 'active' : undefined),
    [active, fold],
  );

  useEffect(() => {
    if (foldedNavigation) {
      setFold(true);
    } else if (active) {
      setFold(false);
    }
  }, [foldedNavigation]);

  useEffect(() => {
    const isActive = isActivePath();
    setActive(isActive);
    if (foldedNavigation) {
      setFold(true);
    } else {
      setFold(!isActive);
    }
  }, [activePath, navigationTab]);

  useEffect(() => {
    if (foldedNavigation) {
      setFold(true);
    } else {
      setFold(!active);
    }
  }, [active]);

  function isActivePath() {
    return (
      navigationTab.path === activePath ||
      !!navigationTab.collapse?.find(
        (collapseNavigationTab) => collapseNavigationTab.path === activePath,
      )
    );
  }

  const onClickListItemHandler = () => {
    if (navigationTab.path?.includes('http')) {
      window.open(navigationTab.path, '_blank_');
    } else if (navigationTab.path) {
      navigate(navigationTab.path);
    }
    setFold((rs) => !rs);
  };

  const mainNavigationButtonRef = useRef<HTMLDivElement | null>(null);

  return (
    <div style={{ position: 'relative' }}>
      <ListItemButton
        onClick={onClickListItemHandler}
        className={activeClass}
        ref={mainNavigationButtonRef}
      >
        {/* //사이드바가 열려있다면 닫는 화살표, 닫혀있다면 햄버거 */}
        {foldedNavigation ? navigationTab.icon : navigationTab.name}
        {/* //자식 리스트가 있고 사이드바가 열려있다면 아래 화살표 아이콘 표시 */}
        {!foldedNavigation && navigationTab.collapse ? (
          <div style={{ marginLeft: 'auto', marginRight: '0' }}>
            <ArrowDropDownIcon />
          </div>
        ) : (
          <></>
        )}
      </ListItemButton>
      {/* //자식 리스트가 있다면 리스트를 렌더링 */}
      {navigationTab.collapse &&
        /**
         * 사이드 바가 열려있으면 클릭시 아래로 리스트
         * 사이드 바가 닫혀있으면 아이콘 클릭시 작은 모달로 표시
         */
        (foldedNavigation ? (
          !fold && (
            <ClickAwayListener onClickAway={() => setFold(true)}>
              <Menu
                id="folded-sub-navigation-list"
                anchorEl={mainNavigationButtonRef.current}
                open={!fold}
                onClose={() => setFold(true)}
                variant="menu"
                transformOrigin={{
                  vertical: 60,
                  horizontal: -70,
                }}
                sx={{ pointerEvents: 'none' }}
              >
                {navigationTab.collapse.map(({ name, path }) => {
                  return (
                    <MenuItem
                      key={name}
                      onClick={() => {
                        path && navigate(path);
                        setFold(true);
                      }}
                      sx={{
                        pointerEvents: 'auto',
                        fontWeight: path === activePath ? 700 : 500,
                      }}
                    >
                      {name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </ClickAwayListener>
          )
        ) : (
          <Collapse in={!fold} sx={{ gap: 4 }}>
            <Divider />
            <List component="div" disablePadding>
              {navigationTab.collapse.map(({ name, path }) => {
                const collapseActiveClass =
                  path === activePath ? 'active' : undefined;
                return (
                  <CollapseListItemButton
                    key={name}
                    className={collapseActiveClass}
                    onClick={() => {
                      path && navigate(path);
                    }}
                  >
                    {name}
                  </CollapseListItemButton>
                );
              })}
            </List>
            <Divider />
          </Collapse>
        ))}
      {navigationTab.innerItem && (
        <div
          style={
            foldedNavigation
              ? {
                  position: 'absolute',
                  right: 16,
                  top: 20,
                  transform: 'translate(50%, -50%)',
                }
              : {
                  position: 'absolute',
                  right: 16,
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }
          }
        >
          {navigationTab.innerItem}
        </div>
      )}
    </div>
  );
};

export default DrawerNavigationListItem;
