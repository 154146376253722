import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

export default function DetailRow(props) {
  const content = useMemo(() => {
    if (props.data) {
      return props.data;
    } else if (props.children) {
      return props.children;
    } else {
      return null;
    }
  }, [props]);

  return (
    <Row className="mt-2">
      <Col md={2}>{props.label}</Col>
      <Col md={8}>{content}</Col>
    </Row>
  );
}
