import { MoyoOnlyListTypes } from '../type';

export const validatePlan = (
  item: MoyoOnlyListTypes,
  searchName: string,
  mvnoState: string,
  state: string,
) => {
  let planName = item.planMetaJson?.name;
  if (planName === undefined) {
    item.planMetaJson.name = '모요 온리 변경 요금제에요';
    planName = '모요 온리 변경 요금제에요';
  }

  const isInFilter = (value: string, filter: string) =>
    filter === 'ALL' || filter === value;

  return (
    planName.includes(searchName) &&
    isInFilter(item.mvno, mvnoState) &&
    isInFilter(item.status, state)
  );
};
