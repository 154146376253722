import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import { SpecialGroupNameSelectList } from '../../../entity/plan';

type SpecialCaseProps = {
  isSpecialCase: boolean;
  specialGroupName: string | null;
};

export const SpecialCase: React.FC<SpecialCaseProps> = ({
  isSpecialCase,
  specialGroupName,
}) => {
  const { control, watch } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <Grid item xs={12} md={6}>
      <div>
        <Typography variant="h6" my={2} mt={'30px'} style={{ display: 'flex' }}>
          특수 요금제
          <Controller
            control={control}
            name="isSpecialCase"
            defaultValue={isSpecialCase}
            render={({ field }) => <Switch {...field} checked={field.value} />}
          />
        </Typography>
        {watch('isSpecialCase') && (
          <>
            <FormControl sx={{ mt: 1, mb: 1, minWidth: 150 }}>
              <InputLabel>종류 </InputLabel>
              <Controller
                control={control}
                name="specialGroupName"
                defaultValue={specialGroupName}
                render={({ field }) => (
                  <Select {...field} required label="종류">
                    {SpecialGroupNameSelectList.map((group, index) => (
                      <MenuItem key={index} value={group.key}>
                        {group.value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </>
        )}
      </div>
    </Grid>
  );
};
