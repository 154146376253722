import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';

type AdditionalCallProps = {
  additionalCall: number | null;
};

export const AdditionalCall: React.FC<AdditionalCallProps> = ({
  additionalCall,
}) => {
  const [additionalCallStatus, setAdditionalCallStatus] = useState<string>('');
  const { control, register, watch, setValue } =
    useFormContext<CreateMoyoPlanTypes>();
  return (
    <Grid item xs={12} md={6}>
      <div>
        <Typography variant="h6" my={2} mt={'30px'}>
          부가 통화
        </Typography>
        <FormControl fullWidth>
          <RadioGroup
            value={additionalCallStatus}
            row
            onChange={(event) => {
              setAdditionalCallStatus(event.target.value);
              setValue('additionalCall', null);
            }}
          >
            <FormControlLabel value="제공" control={<Radio />} label="제공" />
            <FormControlLabel
              value="제공 안 함"
              control={<Radio />}
              label="제공 안 함"
            />
          </RadioGroup>
          {additionalCallStatus === '제공' && (
            <FormControl sx={{ mt: 1, width: 160 }}>
              <Controller
                control={control}
                defaultValue={additionalCall}
                name="additionalCall"
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    label="부가통화 제공량"
                    variant="outlined"
                    type="number"
                    error={watch('additionalCall') === null ? true : false}
                    onChange={(event) =>
                      field.onChange(parseInt(event.target.value))
                    }
                  />
                )}
              />
            </FormControl>
          )}
        </FormControl>
      </div>
    </Grid>
  );
};
