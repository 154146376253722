// FilterForm.tsx
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { MoyoOnlyListTypes } from '../type';

interface FilterFormProps {
  mvnoState: string;
  setMvnoState: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  filteredPlans: MoyoOnlyListTypes[] | undefined;
}

export const FilterForm: React.FC<FilterFormProps> = ({
  mvnoState,
  setMvnoState,
  state,
  setState,
  filteredPlans,
}) => (
  <div style={{ display: 'grid', gap: '0.5rem' }}>
    <FormControl fullWidth>
      <InputLabel>처리 상태</InputLabel>
      <Select
        value={state}
        label="처리 상태"
        onChange={(event) => {
          setState(event.target.value);
        }}
      >
        <MenuItem value="ALL">전체</MenuItem>
        <MenuItem value="STANDBY">생성 대기</MenuItem>
        <MenuItem value="STABLE">처리완료</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth>
      <InputLabel>MVNO 필터</InputLabel>
      <Select
        value={mvnoState}
        label="MVNO 필터"
        onChange={(event) => {
          setMvnoState(event.target.value);
        }}
      >
        <MenuItem value="ALL">전체</MenuItem>
        {filteredPlans?.map((option, index) => (
          <MenuItem key={index} value={option.mvno}>
            {option.mvno}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);
