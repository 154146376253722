import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortItem,
} from '@mui/x-data-grid';
import { Box, Chip, Grid, SelectChangeEvent } from '@mui/material';
import { RocketMvnos } from '../entity/onlyRocket';
import { Mvnos, MvnosWithAll } from '../common/constants';
import apiService from '../service/api.service';
import { useDebounce } from '../common/hooks';
import { FilterComponent } from '../component/FilterComponent';
import { ConstraintModal } from './constraint/ConstraintModal';

const krCurrencyFormatter = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW',
});

const isRocket = (mvno: string) => {
  return RocketMvnos.includes(mvno);
};

function PlanColumnValidate(
  state: string,
  isDeleted: boolean,
  manualIsDeleted: boolean,
): { text: string; color: 'error' | 'warning' | 'default' | 'success' } {
  if (state === 'READY') {
    return {
      text: '진행 대기',
      color: 'error',
    };
  }

  if (state === 'CHANGE_RESERVED') {
    return {
      text: '수정 예약',
      color: isDeleted ? 'error' : 'success',
    };
  }

  if (state === 'DELETE_RESERVED') {
    return {
      text: '종료 예약',
      color: 'success',
    };
  }

  if (
    state === 'CONFIRMED' &&
    isDeleted === false &&
    manualIsDeleted === false
  ) {
    return {
      text: '진행중',
      color: 'success',
    };
  }

  if (manualIsDeleted) {
    return {
      text: '수동 종료',
      color: 'error',
    };
  }

  if (isDeleted && manualIsDeleted === false) {
    return {
      text: '종료',
      color: 'error',
    };
  }

  // Default case
  return {
    text: '진행중',
    color: 'success',
  };
}

const planColumns: GridColDef[] = [
  {
    field: 'id',
    headerAlign: 'center',
    align: 'center',
    headerName: 'id',
    width: 60,
  },
  {
    field: 'mvno',
    headerAlign: 'center',
    align: 'center',
    headerName: 'MVNO',
    width: 150,
  },
  {
    field: 'name',
    headerAlign: 'center',
    align: 'center',
    headerName: '요금제 이름',
    width: 200,
  },
  {
    field: 'mno',
    headerAlign: 'center',
    align: 'center',
    headerName: 'MNO',
    width: 30,
  },
  {
    field: 'feeAfterDiscount',
    headerAlign: 'center',
    align: 'center',
    headerName: '할인 전 가격',
    width: 110,
    renderCell: (param: GridRenderCellParams) =>
      krCurrencyFormatter.format(param.row.feeAfterDiscount),
  },
  {
    field: 'fee',
    headerAlign: 'center',
    align: 'center',
    headerName: '할인 후 가격',
    width: 110,
    renderCell: (param: GridRenderCellParams) =>
      krCurrencyFormatter.format(param.row.fee),
  },
  {
    field: 'numberOfMonthsForDiscount',
    headerAlign: 'center',
    align: 'center',
    headerName: '할인기간',
    width: 70,
  },
  {
    field: 'code',
    headerAlign: 'center',
    align: 'center',
    headerName: '요금제 코드',
    width: 130,
  },
  {
    field: '모요개통',
    align: 'center',
    headerAlign: 'center',
    renderCell: (param: GridRenderCellParams) =>
      param.row.isSupportSelfActivation ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
    width: 100,
  },
  {
    field: '간편신청',
    align: 'center',
    headerAlign: 'center',
    renderCell: (param: GridRenderCellParams) =>
      isRocket(param.row.mvno) ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      ),
    width: 100,
  },
  {
    field: '상태',
    align: 'center',
    headerAlign: 'center',
    renderCell: (param: GridRenderCellParams) => {
      const { text, color } = PlanColumnValidate(
        param.row.state,
        param.row.isDeleted,
        param.row.manualIsDeleted,
      );
      return <Chip color={color} label={text} size="small"></Chip>;
    },
    width: 100,
  },
];

interface ConstraintAddTypes {
  content: string;
  id: number;
  description: string | null;
  detailType: string;
  mno: string;
  mvno: string;
  regTs: string;
  type: string;
  typeId: number;
  updTs: string;
  code: string;
  fee: number;
  feeAfterDiscount: number;
  isDeleted: boolean;
  isSupportSelfActivation: boolean;
  name: string;
  net: string;
  nextReservedUpdateTime: string;
  numberOfMonthsForDiscount: number;
  partnerSignupUrl: string;
  partnerSignupUrlForNewPhoneNumber: number;
  selfSignupUrl: string;
  state: string;
  manualIsDeleted: boolean;
}

export default function PlanList({ history }: any) {
  const navigate = useNavigate();
  const mvnoParam = new URLSearchParams(useLocation().search).get('mvno');
  let paramMvno =
    Object.values(Mvnos).find((e, key) => e.value === mvnoParam) ||
    MvnosWithAll.ALL;
  const [plans, setPlans] = useState<ConstraintAddTypes[]>([]);
  const [mvno, setMvno] = useState(paramMvno);
  const [state, setState] = useState('ALL');
  const [scrapingBtnText, setScrapingBtnText] = useState('스크래핑 요청');
  const [filterText, setFilterText] = useState('');
  const [titleText, setTitleText] = useState('상태 필터');
  const [isLoading, setIsLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([]);
  const debouncedFilterText = useDebounce(filterText, 500);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [scrapingBtnDisable, setScrapingBtnDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: '상태',
      sort: 'asc',
    },
  ]);

  useEffect(() => {
    setMvno(paramMvno);
  }, []);

  const onRowClick = async (param: any) => {
    console.log(param);
    if (param.row.state === 'DELETED') {
      if (window.confirm('요금제 수동 삭제했던걸 다시 살리겠습니까?')) {
        await apiService.restorePlan(param.row.id);
        await getPlans();
      }
    } else {
      navigate(`/plans/${param.row.id}`);
    }
  };

  useEffect(() => {
    if (mvno.value !== '') {
      setScrapingBtnText(mvno.value + ' 스크래핑 요청');
      setScrapingBtnDisable(false);
    }
    getPlans().then((r) => navigate('/plans?mvno=' + mvno.value));
  }, [mvno, debouncedFilterText]);

  const getPlans = async () => {
    const response = await apiService.getPlansByMvno(
      encodeURIComponent(mvno.value),
      encodeURIComponent(filterText),
      undefined,
    );
    setPlans(response?.data?.result);
  };

  const mvnoFilter = (
    <DropdownButton
      key="mvnoFilter"
      className="ms-2"
      title={mvno.name}
      variant="outline-primary"
      size="sm"
      style={{ height: '100%' }}
    >
      {Object.keys(MvnosWithAll).map((key) => {
        const item = MvnosWithAll[key];
        return (
          <Dropdown.Item key={key} eventKey={key} onClick={() => setMvno(item)}>
            {item.name}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const requestScraping = async () => {
    setIsLoading(true);
    let response = await apiService.requestScraping(mvno.value);
    if (response.status === 200) {
      alert(`${mvno.name} 스크래핑 완료되었습니다`);
    } else {
      alert(`${mvno.name} 스크래핑이 실패하였습니다`);
    }
    setIsLoading(false);
  };

  const FilterPlan = (plan: ConstraintAddTypes, stateFilter: string) => {
    if (stateFilter === 'ALL') {
      return plan;
    }

    if (
      stateFilter === 'CONFIRMED' &&
      plan.manualIsDeleted === false &&
      plan.isDeleted === false
    ) {
      return plan;
    } else if (plan.manualIsDeleted && stateFilter === 'DELETED') {
      return plan;
    } else if (
      plan.isDeleted &&
      plan.manualIsDeleted === false &&
      stateFilter === 'DELETED2'
    ) {
      return plan;
    }

    if (plan.manualIsDeleted && stateFilter === 'DELETED') {
      return plan;
    } else if (plan.manualIsDeleted === false && stateFilter === 'DELETED') {
      return;
    }

    if (
      plan.isDeleted &&
      plan.manualIsDeleted === false &&
      stateFilter === 'DELETED2'
    ) {
      return plan;
    } else if (plan.isDeleted === false && plan.manualIsDeleted) {
      return;
    } else if (plan.manualIsDeleted === false) {
      return;
    }
    console.log(9);
    return plan.state === stateFilter;
  };

  const filteredPlans = plans.filter((plan) => {
    return FilterPlan(plan, state);
  });

  const sortedFilteredPlans = [...filteredPlans].sort((a, b) => {
    const getScore = (
      state: string | undefined,
      isDeleted: boolean | undefined,
    ) => {
      if (state === 'CONFIRMED' && isDeleted === false) {
        return 0;
      } else if (state === 'READY') {
        return 1;
      } else if (state === 'CHANGE_RESERVED') {
        return 2;
      } else if (state === 'DELETE_RESERVED') {
        return 3;
      } else if (state === 'CONFIRMED' && isDeleted === true) {
        return 4;
      } else if (state === 'DELETED') {
        return 5;
      } else {
        return 6;
      }
    };
    const aScore = getScore(a.state, a.isDeleted);
    const bScore = getScore(b.state, b.isDeleted);
    if (sortModel[0]?.sort === 'desc') {
      return bScore - aScore;
    } else {
      return aScore - bScore;
    }
  });

  const selectedData = filteredPlans
    .filter((plan) => selectedId.includes(plan.id))
    .map((plan) => plan.id);

  const ConstraintHandler = (items: number[]) => {
    if (items.length < 2) {
      return alert('제약조건을 적용할 요금제 두개이상을 선택해주세요!');
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div
        className=" mt-3 pb-12"
        style={{ marginLeft: '1rem', marginRight: '1rem' }}
      >
        <h4>요금제 찾기 및 수정</h4>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          <Grid container sx={{ margin: '0 0 0 auto' }}>
            {isLoading === false ? (
              <>
                <Button
                  variant="primary"
                  onClick={requestScraping}
                  disabled={scrapingBtnDisable}
                  size={'sm'}
                  style={{ height: '30px' }}
                >
                  <span style={{ padding: '0 10px' }}>{scrapingBtnText}</span>
                </Button>
              </>
            ) : (
              <>
                <Button variant="outline-info" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              </>
            )}
            <Button
              variant="primary"
              onClick={() => ConstraintHandler(selectedData)}
              disabled={scrapingBtnDisable}
              size={'sm'}
              style={{ margin: '0 0 0 10px' }}
            >
              <span>제약조건 일괄 적용</span>
            </Button>
            {mvnoFilter}
            <DropdownButton
              key="stateFilter"
              className="ms-2"
              title={titleText}
              variant="outline-primary"
              size="sm"
              style={{ height: '100%' }}
            >
              <Dropdown.Item
                onClick={() => {
                  setState('ALL');
                  setTitleText('전체');
                }}
              >
                전체
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setState('CONFIRMED');
                  setTitleText('진행중');
                }}
              >
                진행중
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setState('DELETED2');
                  setTitleText('종료');
                }}
              >
                종료
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setState('DELETED');
                  setTitleText('수동 종료');
                }}
              >
                수동 종료
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setState('READY');
                  setTitleText('진행 대기');
                }}
              >
                진행 대기
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setState('CHANGE_RESERVED');
                  setTitleText('수정 예약');
                }}
              >
                수정 예약
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setState('DELETE_RESERVED');
                  setTitleText('종료 예약');
                }}
              >
                종료 예약
              </Dropdown.Item>
            </DropdownButton>
          </Grid>
          <Grid container>
            <div
              style={{
                marginLeft: 'auto',
                marginRight: '0',
                display: 'flex',
              }}
            >
              {searchBox}
            </div>
          </Grid>
        </div>
        <Box
          sx={{
            '& .live': {
              backgroundColor: '#fff',
            },
            '& .deleted': {
              backgroundColor: '#cfcfcf75',
            },
          }}
        >
          <DataGrid
            columns={planColumns}
            rows={sortedFilteredPlans}
            onRowSelectionModelChange={(id) => {
              setSelectedId(id);
            }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            checkboxSelection
            onRowClick={onRowClick}
            pagination
            pageSizeOptions={[25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(newModel) => {
              setPage(newModel.page);
              setPageSize(newModel.pageSize);
            }}
            getCellClassName={(params: GridCellParams) => {
              if (
                params.row.isDeleted ||
                params.row.state === 'DELETED' ||
                (params.row.state === 'CONFIRMED' && params.row.manualIsDeleted)
              ) {
                return 'deleted';
              }
              return 'live';
            }}
          />
        </Box>
        <ConstraintModal
          selectedData={selectedData}
          onClose={() => setOpen(false)}
          open={open}
        />
      </div>
    </>
  );
}
