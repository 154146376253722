import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { MoyoOnlyListTypes, ToMoyoOnlyPlanTypes } from '../type';
import { createPlanDetails, groupByTitle } from './PlanDetails';
import { PopupContent } from './PopupContent';
import { PopupActions } from './PopupActions';

interface MoyoOnlyPopupProps {
  open: boolean;
  onClose: () => void;
  plans: MoyoOnlyListTypes;
  onCreateClick: (id: number) => void;
  onChangeMoyoOnlyClick: (
    requestId: number,
    planId: number,
    data: ToMoyoOnlyPlanTypes,
  ) => void;
}

export const MoyoOnlyPopup: FC<MoyoOnlyPopupProps> = ({
  open,
  onClose,
  plans,
  onCreateClick,
  onChangeMoyoOnlyClick,
}) => {
  const [plan, setPlan] = useState<MoyoOnlyListTypes>({ ...plans });

  useEffect(() => {
    setPlan(plans);
  }, [plans]);

  if (!plan) {
    return null;
  }

  const krCurrencyFormatter = new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',
  });

  const planDetails = createPlanDetails(plan, krCurrencyFormatter);

  const groupedPlanDetails = groupByTitle(planDetails);

  const onChangeMoyoOnlyData = {
    fee: plan.planMetaJson?.fee,
    feeAfterDiscount: plan.planMetaJson?.feeAfterDiscount,
    promotionPeriod: plan.planMetaJson?.promotionPeriod,
    status: plan.status,
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        모요Only {plan.planMetaJson?.planId ? '변경' : '생성'}하기
      </DialogTitle>
      <DialogContent sx={{ width: '600px', padding: '16px 20px 0 20px' }}>
        <PopupContent groupedPlanDetails={groupedPlanDetails} />
      </DialogContent>
      <DialogActions sx={{ margin: 'auto' }}>
        <PopupActions
          plan={plan}
          onClose={onClose}
          onCreateClick={onCreateClick}
          onChangeMoyoOnlyClick={onChangeMoyoOnlyClick}
          onChangeMoyoOnlyData={onChangeMoyoOnlyData}
        />
      </DialogActions>
    </Dialog>
  );
};
