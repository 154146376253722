const isPastDate = (date: string) => {
  const now = new Intl.DateTimeFormat('ko-KR', {
    timeZone: 'asia/seoul',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date()).replaceAll('.', '').replaceAll(' ', '-')
  return now > date;
}

export default isPastDate;