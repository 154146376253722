import { Logout } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { ContentPaste } from '@mui/icons-material';
import { FC, MouseEvent, useContext, useState } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

interface DrawerProps {
  foldedNavigation?: boolean;
  newUpdateCount?: number;
  text: string;
  icon: React.ReactNode;
  onClickProps: () => void;
}

const DrawerButton: FC<DrawerProps> = ({
  foldedNavigation,
  text,
  icon,
  onClickProps,
}) => {
  return (
    <>
      {!foldedNavigation && (
        <MenuItem onClick={onClickProps}>
          {icon}
          {text}
        </MenuItem>
      )}
    </>
  );
};

export default DrawerButton;
