import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import apiService from '../../service/api.service';

export default function ScheduleDetail() {
  const bannerScheduleColumns = [
    {
      name: '배너 ID',
      selector: (row) => row.banner.id,
      maxWidth: '60px',
    },
    {
      name: '이미지',
      selector: (row) => row.banner.pcImgUrl,
      cell: (row, index, column, id) => (
        <img
          src={row.banner.pcImgUrl}
          style={{ maxWidth: '300px', maxHeight: '60px' }}
        />
      ),
    },
    {
      name: 'Weight',
      selector: (row) => row.weight,
      cell: (row, index, column, id) => (
        <Form>
          <Form.Control
            type="number"
            size="sm"
            defaultValue={row.weight}
            onChange={(e) => {
              onWeightChange(row, index, e.target.value);
            }}
          />
        </Form>
      ),
      width: '80px',
    },
    {
      name: '삭제',
      cell: (row, index, column, id) => (
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => removeBanner(row.banner.id)}
        >
          삭제
        </Button>
      ),
      center: true,
      maxWidth: '60px',
    },
  ];

  const bannerColumns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
      sortField: 'id',
      maxWidth: '30px',
    },
    {
      name: '제목',
      selector: (row) => row.title,
      sortable: true,
      sortField: 'title',
      maxWidth: '150px',
    },
    {
      name: '캠페인',
      selector: (row) => row.campaign,
      sortable: true,
      sortField: 'campaign',
      maxWidth: '250px',
    },
    {
      name: 'PC 배너',
      selector: (row) => row.pcImgUrl,
      cell: (row, index, column, id) => (
        <img
          src={row.pcImgUrl}
          style={{ maxWidth: '200px', maxHeight: '60px' }}
        />
      ),
    },
  ];

  const { scheduleId } = useParams();
  const [schedule, setSchedule] = useState(null);
  const [bannerSchedules, setBannserSchedules] = useState([]);
  const [deletedBannerIds, setDeletedBannerIds] = useState([]);
  const [banners, setBanners] = useState([]);
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [resetSelectedToggle, setResetSelectedToggle] = useState(false);

  const [startDate, setStartDate] = useState(
    DateTime.local({ zone: 'Asia/Seoul' }),
  );
  const [endDate, setEndDate] = useState(
    DateTime.local({ zone: 'Asia/Seoul' }),
  );

  const { register, getValues, reset, watch, handleSubmit } = useForm({});
  const navigate = useNavigate();

  const fetchSchedule = async () => {
    if (!scheduleId) return;

    try {
      const res = await apiService.getAdSchedule(scheduleId);

      if (res && res.data) {
        const schedule = res.data.result;
        setSchedule(schedule);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBanners = async () => {
    try {
      const res = await apiService.getAdBanners();
      if (res && res.data) {
        setBanners(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSchedule();
    fetchBanners();
  }, []);

  useEffect(() => {
    if (!schedule) return;

    setBannserSchedules(schedule.bannerSchedules);

    if (schedule.startDate) {
      setStartDate(
        DateTime.local(
          schedule.startDate[0],
          schedule.startDate[1],
          schedule.startDate[2],
          0,
          0,
          { zone: 'Asia/Seoul' },
        ),
      );
    }

    if (schedule.endDate) {
      setEndDate(
        DateTime.local(
          schedule.endDate[0],
          schedule.endDate[1],
          schedule.endDate[2],
          0,
          0,
          { zone: 'Asia/Seoul' },
        ),
      );
    }

    reset({
      title: schedule.title,
      type: schedule.type,
      campaign: schedule.campaign,
      isActive: schedule.isActive,
    });
  }, [schedule]);

  const handleBannersSelected = useCallback((state) => {
    setSelectedBanners(state.selectedRows);
  }, []);

  const addBanners = () => {
    const newBannerSchedules = bannerSchedules.slice();
    selectedBanners.forEach((banner) => {
      if (!newBannerSchedules.some((ele) => ele.banner.id === banner.id)) {
        newBannerSchedules.push({
          weight: 1,
          banner: {
            id: banner.id,
            pcImgUrl: banner.pcImgUrl,
          },
        });
      }
    });

    newBannerSchedules.sort((a, b) => b.banner.id - a.banner.id);

    setBannserSchedules(newBannerSchedules);
    setResetSelectedToggle(!resetSelectedToggle);

    setDeletedBannerIds(
      deletedBannerIds.filter(
        (id) => !selectedBanners.some((banner) => id === banner.id),
      ),
    );
  };

  const removeBanner = (bannerId) => {
    setBannserSchedules(
      bannerSchedules.filter((e) => e.banner.id !== bannerId),
    );
    setDeletedBannerIds([...deletedBannerIds, bannerId]);
  };

  const onWeightChange = (bannerSchedule, index, weight) => {
    bannerSchedule.weight = weight;
    setBannserSchedules([
      ...bannerSchedules.slice(0, index),
      bannerSchedule,
      ...bannerSchedules.slice(index + 1),
    ]);
  };

  const addBannersButton = (
    <Button key="add" variant="outline-primary" onClick={addBanners}>
      추가
    </Button>
  );

  const deleteSchedule = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      try {
        await apiService.deleteAdSchdule(schedule.id);
        alert('삭제되었습니다');
        navigate('/ads?tab=schedule');
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onSubmit = async () => {
    const requestBody = {
      ...getValues(),
      startDate: startDate.toFormat('yyyy-LL-dd'),
      endDate: endDate.toFormat('yyyy-LL-dd'),
      banners: bannerSchedules
        .map((e) => {
          return {
            bannerId: e.banner.id,
            weight: e.weight,
          };
        })
        .concat(
          deletedBannerIds.map((id) => {
            return {
              bannerId: id,
              deleted: true,
            };
          }),
        ),
    };

    try {
      if (scheduleId) {
        const res = await apiService.editAdSchdule(scheduleId, requestBody);
        const updatedSchedule = res.data.result;
        setSchedule(updatedSchedule);

        alert('저장되었습니다');
      } else {
        const res = await apiService.createAdSchedule(requestBody);
        const updatedSchedule = res.data.result;
      }

      navigate(`/ads?tab=schedule`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <Container className="w-75">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>스케줄 상세</h3>
              <div style={{ display: 'inline' }}>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  onClick={onSubmit}
                >
                  저장
                </Button>
                {scheduleId && (
                  <Button variant="outline-danger" onClick={deleteSchedule}>
                    삭제
                  </Button>
                )}
              </div>
            </div>
            <hr />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>스케줄 Id</Form.Label>
                <Form.Control defaultValue={scheduleId} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>제목</Form.Label>
                <Form.Control
                  {...register('title', {
                    required: false,
                  })}
                  placeholder="스케줄 제목을 입력해주세요"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>타입</Form.Label>
                <Form.Control
                  {...register('type', {
                    required: false,
                  })}
                  placeholder="스케줄 타입을 입력해주세요(line_home, line_plans, line_events)"
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>캠페인</Form.Label>
                <Form.Control
                  {...register('campaign', {
                    required: false,
                  })}
                  placeholder="광고 캠페인을 입력해주세요"
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>활성 상태</Form.Label>
                <Form.Check {...register('isActive')} type="switch" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="me-3">시작일</Form.Label>
                <ReactDatePicker
                  selected={startDate.toJSDate()}
                  onChange={(date) => {
                    setStartDate(
                      DateTime.fromJSDate(date).setZone('Asia/Seoul'),
                    );
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="me-3">종료일</Form.Label>
                <ReactDatePicker
                  selected={endDate.toJSDate()}
                  onChange={(date) => {
                    setEndDate(DateTime.fromJSDate(date).setZone('Asia/Seoul'));
                  }}
                ></ReactDatePicker>
              </Form.Group>
            </Form>
          </div>
        </Container>
        <Row>
          <Col md={9} lg={6}>
            <DataTable
              title="노출되는 배너"
              columns={bannerScheduleColumns}
              data={bannerSchedules}
            ></DataTable>
          </Col>
          <Col md={9} lg={6}>
            <DataTable
              title="배너"
              columns={bannerColumns}
              data={banners}
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={handleBannersSelected}
              clearSelectedRows={resetSelectedToggle}
              subHeader
              subHeaderComponent={[addBannersButton]}
            ></DataTable>
          </Col>
        </Row>
      </div>
    </>
  );
}
