import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Main } from '../../../component/Main';
import { useLoading } from '../../../context/LoadingContext';
import { OrderStatusOptionList } from '../../../entity/order';
import {
  npsService,
  SurveyTarget,
  SurveyTemplate,
} from '../../../service/npsService';

const DEFAULT_TARGET: SurveyTarget = {
  id: 0,
  regTs: '',
  surveyTemplateId: 0,
  active: false,
  scheduledSendDate: 1,
  targetStatus: '',
  targetType: '',
  updTs: '',
};

const NpsCreatePage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const form = useForm<SurveyTarget>({ defaultValues: DEFAULT_TARGET });
  const [templateList, setTemplateList] = useState<SurveyTemplate[]>([]);
  const { register, reset, watch, getValues } = form;
  const { setLoading } = useLoading();

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    npsService
      .getSurveyTarget(Number(id))
      .then((rs) => {
        reset({
          ...(rs.data.result ?? DEFAULT_TARGET),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    npsService
      .getSurveyTemplateList()
      .then((rs) => {
        setTemplateList(rs.data.result ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSaveHandler = async () => {
    setLoading(true);
    try {
      if (id) {
        await updateTemplate();
      } else {
        await postTemplate();
      }
      navigate('/nps');
    } catch (e) {
      alert('NPS를 생성하는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const postTemplate = async () => {
    await npsService.postSurveyTarget(
      getValues('targetStatus'),
      getValues('surveyTemplateId'),
      getValues('scheduledSendDate'),
    );
  };

  const updateTemplate = async () => {
    await npsService.putSurveyTarget(
      Number(id!),
      getValues('targetStatus'),
      getValues('surveyTemplateId'),
      getValues('scheduledSendDate'),
    );
  };

  const onClickDelete = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    setLoading(true);
    try {
      await npsService.deleteSurveyTarget(Number(id));
      navigate('/nps');
    } catch (e) {
      alert('NPS를 생성하는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Main>
        <Grid container justifyContent="space-between">
          <Typography variant="h5">NPS {!!id ? '수정' : '생성'}</Typography>
          <div>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button variant="outlined" onClick={onSaveHandler}>
                  {!!id ? '수정하기' : '생성하기'}
                </Button>
              </Grid>
              <Grid item>
                {!!id && (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={onClickDelete}
                  >
                    삭제하기
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid mt={2} container spacing={4} alignItems="end">
          <Grid item>
            <FormControl {...register('targetStatus')} style={{ width: 200 }}>
              <InputLabel>NPS 상태값</InputLabel>
              <Select
                {...register('targetStatus')}
                value={watch('targetStatus')}
                label="NPS 상태값"
                placeholder="NPS 상태값"
              >
                {OrderStatusOptionList.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              {...register('scheduledSendDate')}
              style={{ width: 100 }}
            >
              <TextField
                label="이후 N일"
                {...register('scheduledSendDate')}
                type="number"
                inputMode="numeric"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              {...register('surveyTemplateId')}
              style={{ width: 400 }}
            >
              <InputLabel>템플릿</InputLabel>
              <Select
                {...register('surveyTemplateId')}
                key={watch('surveyTemplateId')}
                value={watch('surveyTemplateId') || undefined}
                label="템플릿"
                placeholder="템플릿"
              >
                {templateList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item alignContent="center">
            {watch('surveyTemplateId') ? (
              <a
                target="__blank__"
                href={`/nps/template/${watch('surveyTemplateId')}`}
              >
                템플릿 보기
              </a>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default NpsCreatePage;
