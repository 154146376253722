import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';
import BannerList from './BannerList';
import ScheduleList from './ScheduleList';
import qs from 'query-string';

export default function AdManager() {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const tabKey = query.get('tab') ?? 'banner';

  const onTabChanged = (k) => {
    console.log(k);
    navigate({
      pathname,
      search: qs.stringify({
        tab: k,
      }),
    });
  };

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <h4>광고 관리</h4>
        <Tabs activeKey={tabKey} onSelect={onTabChanged}>
          <Tab eventKey="banner" title="배너">
            <BannerList />
          </Tab>
          <Tab eventKey="schedule" title="스케줄">
            <ScheduleList />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
