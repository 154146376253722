import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import DetailRow from '../../component/DetailRow';
import apiService from '../../service/api.service';

const ReservedEventDetail = () => {
  const { reservedEventId } = useParams();
  const [reservedEvent, setReservedEvent] = useState(null);
  const navigate = useNavigate();

  const getReservedEvent = async () => {
    if (!reservedEventId) {
      return;
    }

    const response = await apiService.getReservedEvent(reservedEventId);
    if (response && response.data) {
      setReservedEvent(response.data.result);
    }
  };

  useEffect(() => {
    getReservedEvent();
  }, []);

  const handleReservedEventRemove = async () => {
    if (window.confirm('이벤트 예약을 삭제하시겠습니까?')) {
      await apiService.removeReservedEvent(reservedEventId);

      window.alert('이벤트 예약이 삭제되었습니다.');
      navigate('/events');
    }
  };

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <Container className="w-75">
          <div className="d-flex justify-content-between align-items-center">
            <h3>이벤트 예약 상세</h3>
            <Button variant="danger" onClick={handleReservedEventRemove}>
              삭제
            </Button>
          </div>
          <hr />
          <DetailRow label="ID" data={reservedEvent?.id} />
          <DetailRow label="제목" data={reservedEvent?.title} />
          <DetailRow label="통신사" data={reservedEvent?.mvno} />
          <DetailRow
            label="링크"
            data={reservedEvent?.manualPageUrl ?? reservedEvent?.pageUrl}
          />
          <DetailRow label="기간">
            {reservedEvent?.startsAt} ~ {reservedEvent?.expiresAt}
          </DetailRow>
          <DetailRow label="이미지">
            <img
              alt="이벤트 이미지"
              src={reservedEvent?.manualImageUrl ?? reservedEvent?.imageUrl}
              style={{ maxWidth: '480px' }}
            />
          </DetailRow>
        </Container>
      </div>
    </>
  );
};

export default ReservedEventDetail;
