import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { changeMoyoOnlyPlan } from '../../../apis/service/moyo-only';
import { ChangeMoyoOnlyPlanTypes } from '../../../layout/moyoonly/type';

export const useChangeMoyoOnlyPlan = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<AxiosResponse<void>>,
    ChangeMoyoOnlyPlanTypes
  >({
    mutationKey: ['change', 'to', 'only'],
    mutationFn: (parameter) =>
      changeMoyoOnlyPlan({
        requestId: parameter.requestId,
        planId: parameter.planId,
        data: parameter.data,
      }),
  });
};
