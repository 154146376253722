import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import ExcelReader from '../../utils/ExcelReader';
import { Editor as TinyMCEEditor } from 'tinymce';
import MailPreviewDialog from './MailPreviewDialog';
import MailContentConvertor from './MailContentConvertor';
import EmailTemplateDialog from './EmailTemplateDialog';
import { Workbook } from 'exceljs';

const _DataGrid = styled(DataGrid)`
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    margin: 0;
  }
`;

interface GroupMailDialogProps {
  open?: boolean;
  title?: string;
  data?: Record<string, any>[];
  onUpdateData?: (data: Record<string, any>[]) => void;
  onClose?: () => void;
  editor?: TinyMCEEditor;
}

const GroupMailDialog: FC<GroupMailDialogProps> = ({
  open = false,
  title,
  data = [],
  onUpdateData,
  onClose,
  editor,
}) => {
  const [_data, setData] = useState<Record<string, any>[]>([]);
  const excelReader = useMemo(() => new ExcelReader(), []);
  const [emailTemplateMoalOpen, setEmailTemplateMoalOpen] = useState(false);
  const [openPreviewData, setOpenPreviewData] = useState<
    { title: string; email: string; content: string } | undefined
  >();

  useEffect(() => {
    setData(data);
  }, [open]);

  const columns = useMemo(() => {
    const previewCell = {
      field: '미리보기',
      headerName: '미리보기',
      renderCell: (param: any) => {
        return (
          <Button
            onClick={() => {
              let content = editor?.getContent() || '';
              let _title = title || '';
              Object.entries(param.row).forEach(([key, value]) => {
                _title = MailContentConvertor.replaceKeyword(
                  _title,
                  key,
                  value as string,
                );
                content = MailContentConvertor.replaceKeyword(
                  content,
                  key,
                  value as string,
                );
              });

              setOpenPreviewData({
                title: _title,
                email: param.row['이메일'] || '',
                content,
              });
            }}
          >
            미리보기
          </Button>
        );
      },
    };
    if (_data.length === 0) {
      return [
        { field: '이름', headerName: '이름' },
        { field: '이메일', headerName: '이메일' },
        previewCell,
      ];
    }
    const _columns = Object.keys(_data[0]).map((value) => ({
      field: value,
      headerName: value,
    }));
    _columns.push(previewCell);
    return _columns;
  }, [_data]);

  const rows = useMemo(() => {
    if (_data.length === 0) return [];
    return _data.map((row, index) => ({
      id: `${Object.values(row)[0]}-${index}`,
      ...row,
    }));
  }, [_data]);

  const onCloseHandler = () => {
    onClose?.();
  };

  const onSaveHandler = () => {
    onUpdateData?.(_data);
    onClose?.();
  };

  const openTemplateModal = () => {
    setEmailTemplateMoalOpen(true);
  }

  const onCloseTemplateModal = () => {
    setEmailTemplateMoalOpen(false);
  }

  const onChangeFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const workbook = await excelReader.onChangeFileHandler(e);
    onLoadGroupMailTemplate(workbook);
  }

  const onSelectEmailTemplate = async (file: File) => {
    const workbook = await excelReader.getWorkbook(file);
    onLoadGroupMailTemplate(workbook);
  }

  const onLoadGroupMailTemplate = (workbook: Workbook) => {
    const worksheet = workbook.getWorksheet(1);
    const rows = worksheet.getRows(1, worksheet.rowCount);
    const resultRows: Record<string, string>[] = [];
    const firstRow: string[] = [];
    rows?.[0].eachCell((cell) => firstRow.push(cell.text));
    if (!firstRow.includes('이름')) {
      alert('이름은 필수입니다.');
      return;
    }
    if (!firstRow.includes('이메일')) {
      alert('이메일은 필수입니다.');
      return;
    }
    for (const row of (rows || [])?.slice(1)) {
      if (!row.cellCount || row.getCell(1).text === '') break;
      console.log(row);
      const resultMap: Record<string, string> = {};
      row.eachCell((cell, index) => {
        resultMap[firstRow[index - 1]] = cell.isHyperlink
          ? // @ts-ignore
          cell.text.richText?.[0].text
          : cell.text;
      });
      resultRows.push(resultMap);
    }
    console.log(resultRows);
    setData((prevData) => [...prevData, ...resultRows]);
  }
  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg" onClose={onCloseHandler}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            대량 발송 템플릿 업로드
            <Box display="flex" gap={1} alignItems='center'>
              <Button onClick={openTemplateModal}>
                엑셀 템플릿 관리
              </Button>
              <Button
                style={{ whiteSpace: 'nowrap', height: 32 }}
                component="label"
              >
                엑셀 업로드
                <input
                  type="file"
                  hidden
                  onChange={onChangeFileHandler}
                />
              </Button>
              <Button onClick={() => setData([])}>
                초기화
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <_DataGrid autoHeight columns={columns} rows={rows} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandler}>취소</Button>
          <Button onClick={onSaveHandler}>저장</Button>
        </DialogActions>
      </Dialog>
      <MailPreviewDialog
        openData={openPreviewData}
        onClose={() => setOpenPreviewData(undefined)}
      />
      <EmailTemplateDialog
        open={emailTemplateMoalOpen}
        onClose={onCloseTemplateModal}
        onSelect={onSelectEmailTemplate}
      />
    </>
  );
};

export default GroupMailDialog;
