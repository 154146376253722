import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Link,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ExcelReader from '../../utils/ExcelReader';
import GroupMailDialog from './GroupMailDialog';
import MailContentConvertor from './MailContentConvertor';
import { mailApiService } from '../../service/mailService';
import { useLoading } from '../../context/LoadingContext';
import MailStorage, { MailStorageData } from './MailStorage';
import MailStorageDialog from './MailStorageDialog';
import apiService from '../../service/api.service';
import EmailContentViewerDialog from './EmailContentViewerDialog';

const Frame = styled.div`
  padding: 32px;
  .ck.ck-editor {
    display: flex;
    flex-direction: column-reverse;
  }
  .ck-toolbar_grouping {
    border: 1px solid #ccced1 !important;
    border-radius: 0 !important;
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }
  .ck-editor__editable {
    height: 400px;
    border: none !important;
    border-left: 1px solid #ccced1 !important;
    border-right: 1px solid #ccced1 !important;
    border-radius: 0 !important;
  }
  .tox-statusbar__branding {
    display: none;
  }
`;

const content_style = `
  body {
    font-family: Helvetica,Arial,sans-serif;
    font-size:16px;
  }
  .template {
    background-color: rgba(7, 186, 156, 0.12);
  }
`;

const toolbar =
  'send-btn | styles | formatgroup paragraphgroup insertgroup table template-btn | preview fullscreen | forecolor backcolor';
const plugins = [
  'advlist',
  'autolink',
  'link',
  'image',
  'lists',
  'charmap',
  'preview',
  'anchor',
  'pagebreak',
  'searchreplace',
  'wordcount',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'emoticons',
  'template',
  'help',
];
const toolbar_groups = {
  formatgroup: {
    icon: 'format',
    tooltip: 'Formatting',
    items:
      'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat',
  },
  paragraphgroup: {
    icon: 'paragraph',
    tooltip: 'Paragraph format',
    items:
      'bullist numlist | alignleft aligncenter alignright | indent outdent',
  },
  insertgroup: {
    icon: 'plus',
    tooltip: 'Insert',
    items: 'link image emoticons charmap hr',
  },
};

const templateKeywordDialog = {
  open: (editor: TinyMCEEditor) => {
    editor.windowManager.open({
      title: '템플릿 키워드 설정',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'keyword',
            label: '템플릿 키워드',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          name: 'closeButton',
          text: '취소',
        },
        {
          type: 'submit',
          name: 'submitButton',
          text: '추가',
          primary: true,
        },
      ],
      initialData: {
        catdata: 'initial Cat',
        isdog: false,
      },
      onSubmit: (api: any) => {
        var data = api.getData();
        editor.execCommand(
          'mceInsertContent',
          false,
          `<span class="template">#{${data.keyword}}</span>`,
        );
        api.close();
      },
    });
  },
};

const EMail: FC = () => {
  const { register, watch, setValue } = useForm({});
  const editorRef = useRef<TinyMCEEditor>();
  const [data, setData] = useState<Record<string, string>[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openEmailStorage, setOpenEmailStorage] = useState<boolean>(false);
  const [openEmailContentViewer, setOpenEmailContentViewer] = useState<boolean>(false);
  const [saveSnackbar, setSaveSnackbar] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const onTemplateAction = (editor: TinyMCEEditor) => {
    return () => {
      const content = editor.selection.getContent();
      if (content) {
        editor.selection.setContent(
          `<span class="template">#{${content}}</span>`,
        );
        editor.focus();
        return;
      }
      templateKeywordDialog.open(editor);
    };
  };

  const onSendAction = (editor: TinyMCEEditor) => {
    return async () => {
      const senderMail = watch('sender');
      if (!senderMail) {
        alert('발신자를 작성해주세요.');
      }
      if (!window.confirm('메일을 보내시겠습니까?')) return;
      try {
        setLoading(true);
        let myData: Record<string, string>[] = [];
        setData((prevData) => {
          myData = [...prevData];
          return prevData;
        });

        const splitedMyData = [];
        while (myData.length) splitedMyData.push(myData.splice(0, 100));

        const rqAllMail = splitedMyData.map(async (_myData) => {
          const mails = _myData.map((rows) => {
            let content = editor.getContent();
            let title = watch('title');

            Object.entries(rows).forEach(([key, value]) => {
              title = MailContentConvertor.replaceKeyword(title, key, value);
              content = MailContentConvertor.replaceKeyword(
                content,
                key,
                value,
              );
            });

            if (watch('tracking')) {
              const utmMedium = watch('utm_medium')
                ? `&utm_medium=${watch('utm_medium')}`
                : '';
              const utmSource = watch('utm_source')
                ? `&utm_source=${watch('utm_source')}`
                : '';
              const utmDescription = watch('utm_description')
                ? `&utm_description=${watch('utm_description')}`
                : '';
              const utmCampaign = watch('utm_campaign')
                ? `&utm_campaign=${watch('utm_campaign')}`
                : '';
              const utmContent = watch('utm_content')
                ? `&utm_content=${watch('utm_content')}`
                : '';
              const utmTerm = watch('utm_term')
                ? `&utm_term=${watch('utm_term')}`
                : '';
              const from = `&from=${rows['이메일']}`;

              const mailTracker = `<img src="https://d1oxiiofqfxjtg.cloudfront.net/1x1.png?user=${rows['이름']}&title=${title}${utmMedium}${utmSource}${utmDescription}${utmCampaign}${utmContent}${utmTerm}${from}" style="width:1px;height:1px;" />`;

              content = `${mailTracker}${content}`;
            }

            return {
              title,
              content,
              senderMail,
              receiverMail: rows['이메일'],
            };
          });

          return await mailApiService.sendMails(mails);
        });
        await Promise.all(rqAllMail);

        setData([]);
        setValue('title', '');
        editorRef.current?.setContent('');
        alert('메일 전송 성공');
      } catch (_: any) {
        alert(`메일을 보내는데 실패했어요. ${_.toString()}`);
      } finally {
        setLoading(false);
      }
    };
  };

  const onClickSaveMail = () => {
    const sender = watch('sender');
    const title = watch('title');
    const content = editorRef.current?.getContent() || '';
    const tracking = watch('tracking');
    const utmMedium = watch('utm_medium');
    const utmSource = watch('utm_source');
    const utmDescription = watch('utm_description');
    const utmCampaign = watch('utm_campaign');
    const utmContent = watch('utm_content');
    const utmTerm = watch('utm_term');

    MailStorage.save({
      sender,
      title,
      content,
      tracking,
      utmMedium,
      utmSource,
      utmDescription,
      utmCampaign,
      utmContent,
      utmTerm,
    });

    setSaveSnackbar(true);
  };

  const onLoadHandler = (data: MailStorageData) => {
    console.log('data.tracking :', data.tracking);
    setValue('sender', data.sender);
    setValue('title', data.title);
    setValue('tracking', data.tracking);
    setValue('utm_medium', data.utmMedium);
    setValue('utm_source', data.utmSource);
    setValue('utm_description', data.utmDescription);
    setValue('utm_campaign', data.utmCampaign);
    setValue('utm_content', data.utmContent);
    setValue('utm_term', data.utmTerm);
    editorRef.current?.setContent(data.content || '');
    setOpenEmailStorage(false);
  };

  return (
    <>
      <Frame>
        <Link
          href="https://www.notion.so/moyoplan/72bc8b7e06c443e4950520b1e1932bbb?pvs=4"
          target="__blank__"
        >
          사용법
        </Link>
        <br />
        <Box>
          <Grid container gap={1} justifyContent="flex-end">
            <Button variant="outlined" onClick={onClickSaveMail}>
              저장
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenEmailStorage(true);
              }}
            >
              불러오기
            </Button>
          </Grid>
        </Box>
        <FormControlLabel
          label="트랙킹 활성화 (기본적으로 이름과 제목 붙어요)"
          control={
            <Checkbox checked={!!watch('tracking')} {...register('tracking')} />
          }
        />
        {watch('tracking') && (
          <Box>
            <Grid container gap={1} marginBottom={1}>
              <Grid container gap={1}>
                <TextField
                  label="utm_medium"
                  size="small"
                  {...register('utm_medium')}
                />
                <TextField
                  label="utm_source"
                  size="small"
                  {...register('utm_source')}
                />
                <TextField
                  label="utm_description"
                  size="small"
                  {...register('utm_description')}
                />
              </Grid>
              <Grid container gap={1}>
                <TextField
                  label="utm_campaign"
                  size="small"
                  {...register('utm_campaign')}
                />
                <TextField
                  label="utm_content"
                  size="small"
                  {...register('utm_content')}
                />
                <TextField
                  label="utm_term"
                  size="small"
                  {...register('utm_term')}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="8px"
          alignItems="flex-end"
          gap={2}
        >
          <Grid container gap={1}>
            <TextField
              {...register('sender', {
                required: false,
              })}
              placeholder="발신자"
              label="발신자"
              fullWidth
              variant="standard"
            />
            <TextField
              {...register('title', {
                required: false,
              })}
              placeholder="제목"
              label="제목"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Button
            style={{ whiteSpace: 'nowrap', height: 32 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            템플릿 추가
          </Button>
          <Button
            style={{ whiteSpace: 'nowrap', height: 32 }}
            onClick={() => {
              setOpenEmailContentViewer(true) 
            }}
          >
            HTML Viewer
          </Button>
        </Box>
        <Box marginBottom="8px">
          {data.length > 0 && (
            <Typography variant="caption" component="span">
              총 {data.length}명{' '}
            </Typography>
          )}
          {data.map((rows, index) => (
            <Typography variant="caption" component="span">
              {index !== 0 ? ', ' : ''}
              {rows['이름']}({rows['이메일']})
            </Typography>
          ))}
        </Box>
        <Editor
          apiKey='4igthdh6hystfjhpr7ehjd01wqg76cn2h7qt3ki5lw2r3evh'
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          initialValue=""
          init={{
            setup: (editor) => {
              editor.ui.registry.addButton('template-btn', {
                icon: 'code-sample',
                onAction: onTemplateAction(editor),
              });
              editor.ui.registry.addButton('send-btn', {
                text: '보내기',
                onAction: onSendAction(editor),
              });
            },
            height: 500,
            plugins,
            toolbar,
            menubar: false,
            toolbar_groups,
            skin: 'outside',
            toolbar_location: 'bottom',
            content_style,
            images_upload_handler: async (blobInfo) => {
              const file = new File([blobInfo.blob()], blobInfo.filename());
              const rs = await apiService.fileUpload(file, 'upload_email');
              return rs.data.result.paths[0];
            },
          }}
        />
        <br />
        <Typography variant="caption">{`#{변수}를 통해 대상마다 커스텀한 내용을 채워줄 수 있습니다.`}</Typography>
        <br />
        <Typography variant="caption">{`템플릿에 추가할 때는 이름, 이메일은 필수로 등록해줘야하며, #{}는 제외하고 등록합니다.`}</Typography>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={3000}
          open={saveSnackbar}
          onClose={() => setSaveSnackbar(false)}
        >
          <Alert severity="success">메일 내용이 저장되었습니다.</Alert>
        </Snackbar>
      </Frame>
      <GroupMailDialog
        title={watch('title')}
        open={open}
        data={data}
        editor={editorRef.current}
        onUpdateData={setData}
        onClose={() => {
          setOpen(false);
        }}
      />
      <MailStorageDialog
        open={openEmailStorage}
        onLoad={onLoadHandler}
        onClose={() => {
          setOpenEmailStorage(false);
        }}
      />
      <EmailContentViewerDialog
        open={openEmailContentViewer}
        loadContent={() => editorRef.current?.getContent() ??''}
        onClose={() => {
          setOpenEmailContentViewer(false);
        }}
      />
    </>
  );
};

export default EMail;
