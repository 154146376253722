import { KeyAndValueTypes } from '../layout/create-plan/type';

export const NetworkList: KeyAndValueTypes[] = [
  {
    key: 'LTE',
    value: 'LTE',
  },
  {
    key: '5G',
    value: '5G',
  },
];
