import { DatePicker } from '@mui/lab';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { OrderStatus } from '../../entity/order';

type OrderSearchProps = {
  dateStandard?: string;
  onDateStandardChanged: (s: string) => void;
  startDate: DateTime | undefined | null;
  onStartDateChanged: (date: DateTime | undefined | null) => void;
  endDate: DateTime | undefined | null;
  onEndDateChanged: (date: DateTime | undefined | null) => void;
  customerName?: string;
  onCustomerNameChanged: (name: string) => void;
  customerPhoneNumber?: string;
  onCustomerPhoneNumberChanged: (number: string) => void;
  planName?: string;
  onPlanNameChanged: (name: string) => void;
};

export default function OrderSearchSection({
  dateStandard,
  onDateStandardChanged,
  startDate,
  onStartDateChanged,
  endDate,
  onEndDateChanged,
  customerName,
  onCustomerNameChanged,
  customerPhoneNumber,
  onCustomerPhoneNumberChanged,
  planName,
  onPlanNameChanged,
}: OrderSearchProps) {
  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={6} mt={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography mb={1}>날짜로 검색</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Select
                size="small"
                sx={{
                  marginRight: '1rem',
                }}
                defaultValue="createdAt"
                value={dateStandard}
                onChange={(e: any) => onDateStandardChanged(e.target.value)}
              >
                <MenuItem value="completedAt">개통일</MenuItem>
                <MenuItem value="createdAt">신청일</MenuItem>
              </Select>
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '1rem',
                }}
              >
                <DatePicker
                  label="시작일"
                  value={startDate}
                  onChange={(newValue: any) => {
                    onStartDateChanged(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </div>
              <DatePicker
                label="종료일"
                value={endDate}
                onChange={(newValue: any) => {
                  onEndDateChanged(newValue);
                }}
                renderInput={(params: any) => (
                  <TextField {...params} size="small" />
                )}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography mb={1}>고객 이름으로 검색</Typography>
          <TextField
            label="고객 성함"
            value={customerName}
            onChange={(e: any) => onCustomerNameChanged(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography mb={1}>전화번호로 검색</Typography>
          <TextField
            label=" - 없이 전화번호를 입력해주세요 ( ex: 01012345678 )"
            value={customerPhoneNumber}
            onChange={(e: any) => onCustomerPhoneNumberChanged(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography mb={1}>요금제 이름으로 검색</Typography>
          <TextField
            label="요금제 이름"
            value={planName}
            onChange={(e) => onPlanNameChanged(e.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}
