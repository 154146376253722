import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import { FormControl, Grid, TextField, Typography } from '@mui/material';

type PartnerLinksProps = {
  partnerSignupUrl: string | null;
};

export const PartnerLinks: FC<PartnerLinksProps> = ({ partnerSignupUrl }) => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" my={2} mt={'30px'}>
        파트너스 링크
      </Typography>
      <FormControl fullWidth>
        <Controller
          control={control}
          defaultValue={partnerSignupUrl}
          name="partnerSignupUrl"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              type="string"
              label={'가입 신청 링크'}
              variant="outlined"
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};
