import { Spacing } from '../../component/Spacing';
import { BizmSection } from './components/BizmSection';
import { SettlementSubCategoryRawDataSection } from './components/SettlementSubCategoryRawDataSection';
import { SettlementSubRoutes } from './routes';

export function SettlementInfluencers({
  subCategory,
}: {
  category: string;
  subCategory: SettlementSubRoutes;
}) {
  return (
    <>
      <Spacing size={56} />
      <BizmSection subCategory={subCategory} />
      <Spacing size={64} />
      <SettlementSubCategoryRawDataSection subCategory={subCategory} />
    </>
  );
}
