import { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import apiService from '../service/api.service';

type LoginProps = {
  from: string;
};
export default function Login(props: LoginProps) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { user, loginUser } = useContext(UserContext);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const handleLogin = async (data: any) => {
    try {
      const response = await apiService.login(data);
      if (response.status === 200) {
        // @ts-ignore
        loginUser({ username: data.username });
        setIsLoggedIn(true);
      }
    } catch (err) {
      console.debug(err);
    }
    console.log(isLoggedIn);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);
  // @ts-ignore
  const { from } = props.from || { from: { pathname: '/' } };
  if (user) {
    return <Navigate to={from} />;
  }

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center h-100"
        style={{ backgroundColor: '#4e73df' }}
      >
        <div
          className="d-flex flex-column justify-content-center rounded p-5 w-50"
          style={{ backgroundColor: 'white' }}
        >
          <div className="d-flex justify-content-center pb-3">
            <h3>Moyo Admin</h3>
          </div>
          <div>
            <Form onSubmit={handleSubmit(handleLogin)}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  {...register('username', { required: true })}
                  placeholder="아이디를 입력해주세요"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  {...register('password', { required: true })}
                  type="password"
                  placeholder="비밀번호를 입력하세요"
                />
              </Form.Group>
              <Button type="submit">로그인</Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
