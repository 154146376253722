import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { getFileNameFromUUIDPath } from "../../common/file-utils";
import { adminApi } from "../../common/http_util";
import { useMailTemplate } from "../../hooks/mail-template/useMailTemplate";
import { mailApiService, MailTemplateDto } from "../../service/mailService";

interface Props {
  open?: boolean;
  onClose?: () => void;
  loadContent: () => string;
}

const EmailContentViewerDialog: FC<Props> = (({
  open = false,
  onClose,
  loadContent,
}) => {
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (!open) return;
    setContent(loadContent());
  }, [open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          내용 확인
        </Box>
      </DialogTitle>
      <DialogContent style={{ whiteSpace: 'pre' }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  )
});

export default EmailContentViewerDialog;
