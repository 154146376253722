import { FC, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import {
  GetPostListResponse,
  GetPostListResponseEntry,
} from '../../entity/communityEntity';
import { communityApiService } from '../../service/communityService';

const CommunityTableColumns: TableColumn<
  GetPostListResponseEntry & { id: number }
>[] = [
  {
    name: '순번',
    selector: 'id',
    maxWidth: '30px',
  },
  {
    name: '제목',
    selector: 'title',
    cell: (row) => <Link to={`/community/${row.uuid}`}>{row.title}</Link>,
  },
  {
    name: '댓글',
    selector: 'childCommentCount',
    maxWidth: '60px',
  },
  {
    name: '답변',
    selector: 'childPostCount',
    maxWidth: '60px',
  },
  {
    name: '생성일',
    selector: 'createdAt',
    maxWidth: '180px',
  },
  {
    name: '조회수',
    selector: 'viewCount',
    maxWidth: '60px',
  },
];

const EMPTY_COLUMN = {
  id: 99999,
  childCommentCount: 0,
  contentPreview: '',
  childPostCount: 0,
  createdAt: '',
  slug: '',
  title: '',
  uuid: '',
  viewCount: 0,
};

const CommunityPage: FC = () => {
  const [communityData, setCommunityData] = useState<
    (GetPostListResponseEntry & { id: number })[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const totalAnswerCount = await (
        await communityApiService.getMeta()
      ).data.childPostCount;
      setTotalSize(totalAnswerCount);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const communityRsList = await Promise.all(
          Array.from({ length: perPage / 10 }).map((_, index) =>
            communityApiService.getPostList(
              (page - 1) * (perPage / 10) + index + 1,
              'REG_TS_DESC',
            ),
          ),
        );

        let index = (page - 1) * perPage + 1;
        const communityData = communityRsList
          .map((rs) => rs.data.results.map((e) => ({ id: index++, ...e })))
          .flat();
        setCommunityData(
          communityData.length > 0 ? communityData : [EMPTY_COLUMN],
        );
      } catch (_: any) {
      } finally {
        setLoading(false);
      }
    })();
  }, [page, perPage]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const onChangeRowsPerPageHandler = (
    currentRowsPerPage: number,
    currentPage: number,
  ) => {
    setPerPage(currentRowsPerPage);
    setPage(currentPage);
  };

  return (
    <div>
      <div className="container-md mt-3 pb-5">
        <DataTable
          title="질문/답변 목록"
          columns={CommunityTableColumns}
          data={communityData}
          pagination
          paginationServer
          paginationTotalRows={totalSize}
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          paginationPerPage={perPage}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          progressPending={loading}
          onChangePage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default CommunityPage;
