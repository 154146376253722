import axios, { AxiosResponse } from "axios";
import { ApiResponse } from "../entity/api";

export enum NpsTemplateItemType {
  IMAGE = 'IMAGE',
  LINEAR = 'LINEAR',
  LONG_TEXT = 'LONG_TEXT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export type NpsTemplateItem = {
  uuid: string;
  type: NpsTemplateItemType.IMAGE;
  template: NpsImageTemplate;
  isNps?: boolean;
} | {
  uuid: string;
  type: NpsTemplateItemType.LINEAR;
  template: NpsLinearTemplate;
  isNps?: boolean;
} | {
  uuid: string;
  type: NpsTemplateItemType.LONG_TEXT;
  template: NpsLongTextTemplate;
  isNps?: boolean;
} | {
  uuid: string;
  type: NpsTemplateItemType.MULTIPLE_CHOICE;
  template: NpsMultipleChoiceTemplate;
  isNps?: boolean;
}

interface NpsLinearTemplate {
  title: string;
  minDescription: string;
  maxDescription: string;
  value?: string;
}

interface NpsLongTextTemplate {
  title: string;
  placeholder: string;
  value?: string;
}

interface NpsImageTemplate {
  title: string;
  path: string;
  value?: string;
}

interface NpsMultipleChoiceTemplate {
  title: string;
  options: {
    text: string;
    value: string;
  }[];
  value?: string;
}

export interface NpsSurveyResult {
  id: number;
  userId: number;
  orderId: number;
  score: number | null;
  content: string | null;
  contentMeta: string | null;
  templateTargetId: number | null;
  templateName: string | null;
  createdAt: string;
}

export interface NpsResult {
  totalCount: number;
  surveys: NpsSurveyResult[];
  npsData: {
    npsScore: number;
    promoters: number;
    passives: number;
    datractors: number;
  }
}

export interface SurveyTarget {
  id: number;
  targetType: string;
  targetStatus: string;
  surveyTemplateId: number;
  scheduledSendDate?: number;
  active: boolean;
  regTs: string;
  updTs: string;
}

export interface SurveyTemplate {
  id: number;
  name: string;
  content: NpsTemplateItem[];
  regTs: string;
  updTs: string;
}

interface _SurveyTemplate {
  id: number;
  name: string;
  content: string;
  regTs: string;
  updTs: string;
}

class NpsService {
  private readonly http;

  constructor() {
    this.http = axios.create({
      baseURL: new URL('/api/v1/moyo/survey', process.env.REACT_APP_API_BASE_URL)
        .href,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getSurveyTarget(
    id: number
  ): Promise<AxiosResponse<ApiResponse<SurveyTarget>>> {
    return this.http.get(
      `/target/${id}`,
    );
  }

  getSurveyTargetList(): Promise<AxiosResponse<ApiResponse<SurveyTarget[]>>> {
    return this.http.get('/target/list');
  }

  postSurveyTarget(
    targetStatus: string, // TODO: status값으로 바꾸기
    surveyTemplateId: number,
    scheduledSendDate?: number,
    active?: boolean,
  ): Promise<AxiosResponse<ApiResponse<any>>> {
    return this.http.post('/target', {
      targetType: 'nps',
      targetStatus,
      surveyTemplateId,
      scheduledSendDate,
      active,
    });
  }

  putSurveyTarget(
    id: number,
    targetStatus: string,
    surveyTemplateId: number,
    scheduledSendDate?: number,
    active?: boolean,
  ): Promise<
    AxiosResponse<ApiResponse<any>>
  > {
    return this.http.put(`/target/${id}`, {
      targetType: 'nps',
      targetStatus,
      surveyTemplateId,
      scheduledSendDate,
      active,
    });
  }

  deleteSurveyTarget(
    id: number,
  ): Promise<
    AxiosResponse<ApiResponse<any>>
  > {
    return this.http.delete(`/target/${id}`);
  }

  async getSurveyTemplate(id: number): Promise<AxiosResponse<ApiResponse<SurveyTemplate>>> {
    const rs = await this._getSurveyTemplate(id);
    const result = rs.data.result;
    return {
      ...rs,
      data: {
        ...rs.data,
        result: result ? {
          ...result,
          content: JSON.parse(result?.content ?? '[]') as NpsTemplateItem[]
        } : undefined
      }
    }
  }

  async getSurveyTemplateList(): Promise<AxiosResponse<ApiResponse<SurveyTemplate[]>>> {
    const rs = await this._getSurveyTemplateList();
    const result = rs.data.result;
    return {
      ...rs,
      data: {
        ...rs.data,
        result: result ? (
          result.map((item) => ({
            ...item,
            content: JSON.parse(item.content ?? '[]') as NpsTemplateItem[]
          }))
        ) : undefined
      }
    }
  }

  private _getSurveyTemplate(id: number): Promise<AxiosResponse<ApiResponse<_SurveyTemplate>>> {
    return this.http.get(
      `/template/${id}`,
    );
  }

  private _getSurveyTemplateList(): Promise<AxiosResponse<ApiResponse<_SurveyTemplate[]>>> {
    return this.http.get(
      `/template/list`,
    );
  }

  postSurveyTemplate(
    name: string,
    npsTemplateItems: NpsTemplateItem[],
  ): Promise<AxiosResponse<ApiResponse<any>>> {
    const content = JSON.stringify(npsTemplateItems)
    return this.http.post('/template', {
      name,
      content,
    });
  }

  putSurveyTemplate(
    id: number,
    name: string,
    npsTemplateItems: NpsTemplateItem[],
  ): Promise<
    AxiosResponse<ApiResponse<any>>
  > {
    const content = JSON.stringify(npsTemplateItems)
    return this.http.put(`/template/${id}`, {
      name,
      content,
    });
  }

  deleteSurveyTemplate(
    id: number,
  ): Promise<
    AxiosResponse<ApiResponse<any>>
  > {
    return this.http.delete(`/template/${id}`);
  }

  getSurveyResult(
    templateSurveyId: number,
    // page: number,
    // pageSize: number,
    startDate?: string,
    endDate?: string,
  ): Promise<
    AxiosResponse<ApiResponse<NpsResult>>
  > {
    return this.http.get(`/`, {
      params: {
        startDate,
        endDate,
        page: 0,
        size: 2147483647,
        sort: 'createdAt,desc',
        'template-target-id': templateSurveyId
      }
    });
  }
}

export const npsService = new NpsService();