import { Button, Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import { Section } from './Section';
import { SectionHeader } from './SectionHeader';
import { SelectBox } from './SelectBox';
import {
  EVENT_TYPE_QUERY_KEY,
  useSettlementEventType,
} from '../hooks/useSettlementEventType';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';

export function SearchSection({
  showEventType = false,
}: {
  showEventType?: boolean;
}) {
  const {
    yearMonth: currentYearMonth,
    availableYearMonths,
    setYearMonth: setYearMonthQueryString,
  } = useSettlementPeriod();
  const { eventType: currentEventType, eventTypes } = useSettlementEventType();

  const [yearMonth, setYearMonth] = useState(currentYearMonth);
  const [eventType, setEventType] = useState(currentEventType);

  const handleSubmit = useCallback(() => {
    const extraParams = showEventType
      ? [[EVENT_TYPE_QUERY_KEY, eventType] as [string, string]]
      : undefined;
    setYearMonthQueryString(yearMonth, extraParams);
  }, [setYearMonthQueryString, yearMonth, eventType, showEventType]);

  return (
    <Section>
      <SectionHeader
        title="검색"
        description="선택한 조회 기간의 개통 내역을 검색하실 수 있어요"
      />
      <Grid container gap={2} alignItems="center">
        <Grid item>
          <SelectBox
            id="summary-month"
            label="조회 기간"
            value={yearMonth}
            onChange={(e) => {
              setYearMonth(e.target.value);
            }}
            items={availableYearMonths}
            sx={{ width: '180px' }}
          />
        </Grid>
        {showEventType && (
          <Grid>
            <SelectBox
              id="summary-month"
              label="이벤트 타입"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
              }}
              items={eventTypes}
              sx={{ width: '180px' }}
            />
          </Grid>
        )}
        <Grid>
          <Button
            size="large"
            variant="outlined"
            color="inherit"
            onClick={handleSubmit}
          >
            검색하기
          </Button>
        </Grid>
      </Grid>
    </Section>
  );
}
