import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Paper } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadFile } from '../../../common/file-utils';
import { PageTitle } from '../../../component/PageTitle';
import { settlementApiService } from '../../../service/settlementSevice';
import {
  formatDate,
  formatNumber,
  formatPhoneNumber,
  showFallback,
} from '../data-grid-utils';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';
import { SettlementSubRoutes } from '../routes';
import { Section } from './Section';
import { useEventParameter } from '../hooks/useEventParameter';

const columns: GridColDef[] = [
  {
    field: 'id',
    description: '리워드 ID',
    width: 100,
    sortable: false,
  },
  {
    field: 'eventName',
    description: '이벤트명',
    width: 200,
    sortable: false,
  },
  {
    field: 'eventType',
    description: '이벤트 타입',
    width: 124,
    sortable: false,
  },
  {
    field: 'paymentType',
    description: '대금을 지급하는 주체',
    width: 124,
    sortable: false,
  },
  {
    field: 'partnerName',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'rewardType',
    width: 184,
    sortable: false,
  },
  {
    field: 'rewardItem',
    width: 124,
    sortable: false,
  },
  {
    field: 'getType',
    width: 124,
    sortable: false,
  },
  {
    field: 'userId',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'userName',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'mixUserId',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'affiliateUserId',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'userPhoneNumber',
    width: 124,
    sortable: false,
    valueFormatter: formatPhoneNumber,
  },
  {
    field: 'moyoRewardValue',
    type: 'number',
    width: 124,
    sortable: true,
    valueFormatter: formatNumber,
  },
  {
    field: 'partnerRewardValue',
    type: 'number',
    width: 124,
    sortable: true,
    valueFormatter: formatNumber,
  },
  {
    field: 'moyoRewardMixValue',
    type: 'number',
    width: 124,
    sortable: true,
    valueFormatter: formatNumber,
  },
  {
    field: 'partnerRewardMixValue',
    type: 'number',
    width: 124,
    sortable: true,
    valueFormatter: formatNumber,
  },
  {
    field: 'createdAt',
    type: 'number',
    width: 150,
    sortable: false,
    valueFormatter: formatDate,
  },
  {
    field: 'completedAt',
    type: 'number',
    width: 150,
    sortable: false,
    valueFormatter: formatDate,
  },
  {
    field: 'orderStatus',
    type: 'number',
    width: 240,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'orderId',
    type: 'string',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'orderUserId',
    type: 'string',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'orderUserName',
    type: 'number',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'orderUserPhoneNumber',
    type: 'number',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'orderMvno',
    type: 'number',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'planFee',
    type: 'number',
    width: 124,
    sortable: false,
    valueFormatter: formatNumber,
  },
  {
    field: 'planName',
    type: 'string',
    width: 300,
    sortable: false,
    valueGetter: showFallback,
  },
];

export function SettlementSubCategoryRawDataSection({
  subCategory,
}: {
  subCategory: SettlementSubRoutes;
}) {
  const { endDate, startDate } = useSettlementPeriod();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'moyoRewardValue', sort: 'desc' },
  ]);
  const eventParameter = useEventParameter(subCategory);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data, isLoading } = useQuery(
    [
      'settlement-raw-data',
      endDate,
      startDate,
      eventParameter,
      page,
      pageSize,
      sortModel[0],
    ],
    () =>
      settlementApiService.getRawData({
        ...eventParameter,
        endDate,
        startDate,
        page,
        size: pageSize,
        sort:
          sortModel[0] != null
            ? `${sortModel[0].field},${sortModel[0].sort}`
            : undefined,
      }),
    { suspense: true },
  );

  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const rowCount = data?.data.result.totalSize;
  const rows = data?.data.result.list ?? [];

  return (
    <Section>
      <PageTitle>검증용 raw_data</PageTitle>
      <Paper sx={{ height: 700, width: '100%', padding: '16px' }}>
        <Box sx={{ display: 'flex', gap: '16px', padding: '16px' }}>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              setIsDownloading(true);
              // @ts-ignore
              const response = await settlementApiService.getRawDataSheet({
                ...eventParameter,
                endDate,
                startDate,
                rewardUserIds: selectedIds,
              });
              downloadFile(response);
              setIsDownloading(false);
            }}
            disabled={selectedIds.length === 0 || isLoading || isDownloading}
          >
            선택한 이벤트 엑셀 다운로드
          </Button>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            disabled={isLoading || isDownloading}
            onClick={async () => {
              setIsDownloading(true);
              const response = await settlementApiService.getRawDataSheet({
                ...eventParameter,
                endDate,
                startDate,
              });
              downloadFile(response);
              setIsDownloading(false);
            }}
          >
            전체 엑셀 다운로드
          </Button>
        </Box>
        <DataGrid
          rowCount={rowCount}
          columns={columns}
          rows={rows}
          checkboxSelection
          sx={{ border: 'none' }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isDownloading}
          pagination
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        />
      </Paper>
    </Section>
  );
}
