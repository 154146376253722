import React, { ChangeEvent, FC, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';

interface DiscountInputFieldsTypes {
  call: number | null;
  textMessage: number | null;
  dataSpeed: number | null;
  dataSpeed2: number | null;
  throttledSpeed: number | null;
}

export const DiscountInputFields: FC<DiscountInputFieldsTypes> = ({
  call,
  textMessage,
  dataSpeed,
  dataSpeed2,
  throttledSpeed,
}) => {
  const [isUnlimitedCall, setIsUnlimitedCall] = useState(false);
  const [isUnlimitedText, setIsUnlimitedText] = useState(false);
  const { control, setValue } = useFormContext<CreateMoyoPlanTypes>();

  const UnlimitedValue = 9999;

  return (
    <>
      <Typography variant="h6" mt={'20px'}>
        요금제 데이터
      </Typography>
      <FormControl fullWidth sx={{ gap: '0.5rem' }}>
        <>
          <Grid>
            <span>통화 무제한</span>
            <Controller
              control={control}
              name="call"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={isUnlimitedCall}
                  onChange={(event) => {
                    setValue('call', 9999);
                    setIsUnlimitedCall(event.target.checked);
                  }}
                />
              )}
            />

            <span>문자 무제한</span>
            <Controller
              control={control}
              name="textMessage"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={isUnlimitedText}
                  onChange={(event) => {
                    setValue('textMessage', 9999);
                    setIsUnlimitedText(event.target.checked);
                  }}
                />
              )}
            />
          </Grid>
          <Controller
            control={control}
            defaultValue={call}
            name="call"
            render={({ field }) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={isUnlimitedCall}
                required
                {...field}
                type="number"
                fullWidth
                label="통화(분)"
                value={isUnlimitedCall ? UnlimitedValue : field.value}
                onChange={(event) =>
                  field.onChange(parseInt(event.target.value))
                }
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={textMessage}
            name="textMessage"
            render={({ field }) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={isUnlimitedText}
                required
                {...field}
                type="number"
                fullWidth
                label="문자(건)"
                value={isUnlimitedText ? UnlimitedValue : field.value}
                onChange={(event) =>
                  field.onChange(parseInt(event.target.value))
                }
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={dataSpeed}
            name="dataSpeed"
            render={({ field }) => (
              <TextField
                required
                {...field}
                type="number"
                fullWidth
                label="데이터(GB)"
                onChange={(event) =>
                  field.onChange(parseInt(event.target.value))
                }
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={dataSpeed2}
            name="dataSpeed2"
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                label="일 추가 데이터(GB)"
                onChange={(event) =>
                  field.onChange(parseInt(event.target.value))
                }
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={throttledSpeed}
            name="throttledSpeed"
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                label="소진시 속도(Mbps)"
                onChange={(event) => {
                  field.onChange(parseInt(event.target.value));
                }}
              />
            )}
          />
        </>
      </FormControl>
    </>
  );
};
