export interface MailStorageData {
  id?: string;
  sender?: string;
  title?: string;
  content?: string;
  tracking?: boolean;
  utmMedium?: string;
  utmSource?: string;
  utmDescription?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmTerm?: string;
}

const getUuid = () => {
  const get = () => {
    return (4096 + Math.floor(Math.random() * 61439)).toString(16);
  }
  return `${get()}-${get()}-${get()}-${get()}`;
}

const MAIL_STORAGE = 'mail_storage';

const MailStorage = (() => {
  const save = (data: MailStorageData) => {
    const {
      sender,
      title,
      content,
      tracking,
      utmMedium,
      utmSource,
      utmDescription,
      utmCampaign,
      utmContent,
      utmTerm, } = data;
    const mailStorage = JSON.parse(localStorage.getItem(MAIL_STORAGE) || '[]');
    const id = getUuid();

    mailStorage.push({
      sender,
      title,
      content,
      tracking,
      utmMedium,
      utmSource,
      utmDescription,
      utmCampaign,
      utmContent,
      utmTerm,
      id,
    } as MailStorageData);

    localStorage.setItem(MAIL_STORAGE, JSON.stringify(mailStorage));
  }

  const getAll = (): MailStorageData[] => {
    return JSON.parse(localStorage.getItem(MAIL_STORAGE) || '[]') as MailStorageData[];
  }

  const update = (mailList: MailStorageData[]) => {
    localStorage.setItem(MAIL_STORAGE, JSON.stringify(mailList));
  }

  return {
    save,
    getAll,
    update,
  }
})();

export default MailStorage;
