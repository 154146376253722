export type PlanMeta = {
  id: number;
  name: string;
  groupName: string;
  mvno: string;
  mno: string;
  net: string;
  mobileData?: number;
  mobileData2?: number;
  mobileDataStandard?: string;
  speedWhenExhausted?: number;
  mobileDataString: string;
  mobileDataDescription?: string;
  mobileDataDateExhaustedDescription: string;
  speedWhenExhaustedDescription?: string;
  isWishlist: boolean;
  mobileDataStr?: string;
  mobileData2Str?: string;
  mobileData3Str?: string;
  mobileData3Description?: string;
  mobileVoice: number;
  mobileMessage: number;
  할인끝난후_금액?: number;
  할인적용_개월수?: number;
  카드혜택적용가?: number;
  fee: number;
  feeString: string;
  bitlyUrl: string;
  signupUrl: string;
  번호이동_파트너링크?: string;
  신규가입_파트너링크?: string;
  isTablet: boolean;
  state: string;
  isDeleted: boolean;
  hasMoyoEvent: boolean;
  retargetImgName?: string;
  isPartnerMvno?: boolean; // 제휴된 요금제인지
  regTs: number;
  updTs: number;
};

export type SimplePlanMeta = {
  id: number;
  name: string;
  mvno: string;
  mno: string;
  net: string;
  fee: number;
  isDeleted: boolean;
  partnerSignupUrl?: string;
  partnerSignupUrlForNewPhoneNumber?: string;
};

export type SpecialGroupNameType = {
  senior: '시니어 요금제';
  iphone: '아이폰 요금제';
  tablet: '테블릿 요금제';
  official: '공무원 요금제';
  watch: '워치 요금제';
  junior: '청소년 요금제';
  koreans: '해외출국자 요금제';
  soldier: '군인 요금제';
  welfare: '복지 요금제';
};

export const SpecialGroupNameSelectList: {
  key: keyof SpecialGroupNameType;
  value: string;
}[] = [
  {
    key: 'senior',
    value: '시니어 요금제',
  },
  {
    key: 'iphone',
    value: '아이폰 요금제',
  },
  {
    key: 'tablet',
    value: '테블릿 요금제',
  },
  {
    key: 'official',
    value: '공무원 요금제',
  },
  {
    key: 'watch',
    value: '워치 요금제',
  },
  {
    key: 'junior',
    value: '청소년 요금제',
  },
  {
    key: 'koreans',
    value: '해외출국자 요금제',
  },
  {
    key: 'soldier',
    value: '군인 요금제',
  },
  {
    key: 'welfare',
    value: '복지 요금제',
  },
];
