import { Box, Container, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from '../common/hooks';
import { handleApi } from '../common/http_util';
import { useLoading } from '../context/LoadingContext';
import { MobilePlanOrder } from '../entity/order';
import { orderApiService } from '../service/order';
import OrderSearchSection from './calculate/OrderSearchSection';

const columns: GridColDef[] = [
  {
    field: 'completedAt',
    headerName: '개통완료일',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value) return '';

      const millis = params.value as number;
      return DateTime.fromMillis(millis).toLocaleString(DateTime.DATE_SHORT);
    },
  },
  {
    field: 'createdAt',
    headerName: '신청일',
    headerAlign: 'center',
    align: 'center',
    width: 250,
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value) return '';

      const millis = params.value as number;
      return DateTime.fromMillis(millis).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS,
      );
    },
  },
  {
    field: 'status',
    headerAlign: 'center',
    headerName: '상태',
    align: 'center',
    width: 180,
  },
  {
    field: 'customerName',
    headerAlign: 'center',
    headerName: '고객 성함',
    align: 'center',
  },
  {
    field: 'birthday',
    headerAlign: 'center',
    headerName: '생년월일',
    align: 'center',
  },
  {
    field: 'registerType',
    headerAlign: 'center',
    headerName: '신청 유형',
    align: 'center',
  },
  {
    field: 'customerPhoneNumber',
    headerName: '신청 전화번호',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      // @ts-ignore
      return params.value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    },
    width: 180,
  },
  {
    field: 'orderPhoneNumber',
    headerName: '개통 전화번호',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      // @ts-ignore
      if (params.value == null || typeof params.value != 'string') return '';

      return params.value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    },
    width: 180,
  },
  {
    field: 'planName',
    headerAlign: 'center',
    headerName: '요금제',
    align: 'center',
    width: 200,
  },
  {
    field: 'requestedAt',
    headerName: '개통요청일',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value) return '';

      const millis = params.value as number;
      return DateTime.fromMillis(millis).toLocaleString(DateTime.DATE_SHORT);
    },
  },
];

const rows: Array<MobilePlanOrder> = [];

/**
 * 요금제 정산하기 컴포넌트
 */
const PlanCalculate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoading();

  const [orders, setOrders] = useState<Array<MobilePlanOrder>>(rows);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'createdAt', sort: 'desc' },
  ]);
  let initStartDate = null;
  let initEndDate = null;
  let startDateParam = searchParams.get('startDate');
  if (startDateParam != null) {
    initStartDate = DateTime.fromISO(startDateParam);
  }
  let endDateParam = searchParams.get('endDate');
  if (endDateParam != null) {
    initEndDate = DateTime.fromISO(endDateParam);
  }

  const [dateStandard, setDateStandard] = useState<string>('createdAt');
  const [startDate, setStartDate] = useState<DateTime | undefined | null>(
    initStartDate,
  );
  const [endDate, setEndDate] = useState<DateTime | undefined | null>(
    initEndDate,
  );
  const [customerName, setCustomerName] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [planName, setPlanName] = useState<string>('');

  const debouncedCustomerName = useDebounce(customerName, 500);
  const debouncedCustomerPhoneNumber = useDebounce(customerPhoneNumber, 500);
  const debouncedPlanName = useDebounce(planName, 500);

  const getData = () => {
    return {
      customerName,
      customerPhoneNumber,
      planName,
      dateStandard,
      orderDateTimeFrom: startDate?.toISODate(),
      orderDateTimeTo: endDate?.toISODate(),
      status: 'ACTIVATION_COMPLETE',
    };
  };

  const fetchOrders = async () => {
    setLoading(true);
    const sortItem = sortModel.length > 0 ? sortModel[0] : undefined;

    const data = getData();

    const orderList = await handleApi(
      () =>
        orderApiService.getListForCalculate(
          data,
          page,
          pageSize,
          sortItem?.field,
          sortItem?.sort?.toString(),
        ),
      navigate,
    );

    setLoading(false);

    if (!orderList) return;

    setTotalCount(orderList.totalCount);
    setOrders(orderList.list);
  };

  useEffect(() => {
    fetchOrders();
  }, [
    page,
    pageSize,
    sortModel,
    dateStandard,
    startDate,
    endDate,
    debouncedCustomerName,
    debouncedCustomerPhoneNumber,
    debouncedPlanName,
  ]);

  useEffect(() => {
    const startDateParam = searchParams.get('startDate');
    if (startDateParam != null) setStartDate(DateTime.fromISO(startDateParam));

    const endDateParam = searchParams.get('endDate');
    if (endDateParam != null) setEndDate(DateTime.fromISO(endDateParam));
  }, [searchParams]);

  const onStartDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;

    setStartDate(date);

    // const param = new URLSearchParams(window.location.search);
    // param.set('startDate', date.toSQLDate());
    // window.location.search = param.toString();
  };
  const onEndDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;
    setEndDate(date);

    // const param = new URLSearchParams(window.location.search);
    // param.set('endDate', date.toSQLDate());
    // window.location.search = param.toString();
  };

  const onCustomerPhoneNumberChange = (phoneNumber: string) => {
    setCustomerPhoneNumber(phoneNumber.replace('-', ''));
  };
  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const onSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const onClickRow = (rowParams: GridRowParams) => {
    window.open(`/orders/${rowParams.row.id}`, '_blank');
  };

  return (
    <>
      <Container sx={{ display: 'block', mt: 3 }} maxWidth="xl">
        <OrderSearchSection
          dateStandard={dateStandard}
          onDateStandardChanged={setDateStandard}
          startDate={startDate}
          onStartDateChanged={onStartDateChange}
          endDate={endDate}
          onEndDateChanged={onEndDateChange}
          customerName={customerName}
          onCustomerNameChanged={setCustomerName}
          customerPhoneNumber={customerPhoneNumber}
          onCustomerPhoneNumberChanged={onCustomerPhoneNumberChange}
          planName={planName}
          onPlanNameChanged={setPlanName}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" mt={3}>
            온라인 가입 신청서
          </Typography>
        </Box>
        <DataGrid
          sx={{
            mt: 3,
          }}
          rows={orders}
          columns={columns}
          onRowClick={onClickRow}
          disableRowSelectionOnClick={true}
          autoHeight
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            onPageChange(page);
            onPageSizeChange(pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
      </Container>
    </>
  );
};

export default PlanCalculate;
