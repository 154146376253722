import { Box, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { PageTitle } from '../../../component/PageTitle';
import { settlementApiService } from '../../../service/settlementSevice';
import { Section } from '../components/Section';
import { convertEventTypeParameter } from '../convertEventTypeParameter';
import {
  formatEventType,
  formatGetType,
  formatNumber,
  formatPaymentType,
  formatRewardItem,
  formatRewardType,
  showFallback,
} from '../data-grid-utils';
import { useSettlementEventType } from '../hooks/useSettlementEventType';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';

import { commaizeNumber } from '../../../common/number-utils';

const columns: GridColDef[] = [
  {
    field: 'eventName',
    headerName: '이벤트명',
    width: 280,
    sortable: false,
  },
  {
    field: 'eventType',
    headerName: '이벤트 타입',
    width: 124,
    sortable: false,
    valueFormatter: formatEventType,
  },
  {
    field: 'paymentType',
    headerName: '대급 지급 타입',
    width: 124,
    sortable: false,
    valueFormatter: formatPaymentType,
  },
  {
    field: 'partnerName',
    headerName: '파트너명',
    width: 124,
    sortable: false,
    valueGetter: showFallback,
  },
  {
    field: 'rewardType',
    headerName: '리워드 타입',
    width: 184,
    sortable: false,
    valueFormatter: formatRewardType,
  },
  {
    field: 'rewardItem',
    headerName: '리워드 아이템',
    width: 124,
    sortable: false,
    valueFormatter: formatRewardItem,
  },
  {
    field: 'getType',
    headerName: '유저 종류',
    width: 124,
    sortable: false,
    valueFormatter: formatGetType,
  },
  {
    field: 'totalCount',
    headerName: '개통 건수',
    type: 'number',
    width: 124,
    sortable: false,
    valueFormatter: formatNumber,
  },
  {
    field: 'totalMoyoRewardValue',
    headerName: '모요 부담 금액',
    type: 'number',
    width: 124,
    sortable: false,
    valueFormatter: formatNumber,
  },
  {
    field: 'totalPartnerRewardValue',
    headerName: '파트너 부담 금액',
    type: 'number',
    width: 124,
    sortable: false,
    valueFormatter: formatNumber,
  },
  {
    field: 'totalMoyoRewardMixValue',
    headerName: '모요 부담 금액 Mix',
    type: 'number',
    width: 124,
    sortable: false,
    valueFormatter: formatNumber,
  },
  {
    field: 'totalPartnerRewardMixValue',
    headerName: '파트너 부담 금액 Mix',
    type: 'number',
    width: 124,
    sortable: false,
    valueFormatter: formatNumber,
  },
];

export function EventRewardsSection() {
  const { eventType: eventTypeRaw } = useSettlementEventType();
  const eventType = convertEventTypeParameter(eventTypeRaw);
  const { endDate, startDate } = useSettlementPeriod();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'totalMoyoRewardValue', sort: 'desc' },
  ]);

  const { data, isLoading } = useQuery(
    ['settlement-event-rewards', endDate, startDate, eventType],
    () =>
      settlementApiService.getRewardsByEvent({
        endDate,
        startDate,
        eventType,
      }),
  );

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const rowCount = data?.data.result?.length ?? 0;
  const rows = data?.data.result ?? [];

  const totalAmount =
    rows
      ?.map((item: any) => item.totalMoyoRewardValue)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;

  const totalOpenCount =
    rows
      ?.map((item: any) => item.totalCount)
      ?.reduce((prev: any, next: any) => prev + next, 0) ?? 0;

  return (
    <Section>
      <PageTitle>전체 이벤트별 성과</PageTitle>
      <Paper sx={{ height: 700, width: '100%', padding: '16px' }}>
        <Box sx={{ display: 'flex', gap: '16px', padding: '16px' }}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2">
              총 지급금액 {commaizeNumber(totalAmount)}원 | 총 개통건수{' '}
              {commaizeNumber(totalOpenCount)}건
            </Typography>
          </Box>
        </Box>
        <DataGrid
          rowCount={rowCount}
          columns={columns}
          rows={rows}
          sx={{ border: 'none' }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading}
          slots={{
            footer: () => <></>,
          }}
        />
      </Paper>
    </Section>
  );
}
