import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { getFileNameFromUUIDPath } from "../../common/file-utils";
import { adminApi } from "../../common/http_util";
import { useMailTemplate } from "../../hooks/mail-template/useMailTemplate";
import { mailApiService, MailTemplateDto } from "../../service/mailService";

interface Props {
  open?: boolean;
  onClose?: () => void;
  onSelect?: (file: File) => void;
}

const EmailTemplateDialog: FC<Props> = (({
  open = false,
  onClose,
  onSelect,
}) => {
  const { mailTemplateList, uploadMailTemplate, removeMailTemplate, loadMailTemplateList } = useMailTemplate();

  const uploadFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e;
    try {
      if (!e.currentTarget.files) throw new Error('not found files');
      const file = e.currentTarget.files[0];
      if (file.name.split('.').pop()?.toLocaleLowerCase() !== 'xlsx') {
        throw new Error('not allow extension');
      };
      await uploadMailTemplate.mutate(file, {
        onSuccess: () => loadMailTemplateList()
      });
    } catch (err: any) {
      currentTarget.value = '';
      throw new Error('not allow extension');
    }
  }

  const onRemoveHandlerClosure = (mailTemplate: MailTemplateDto) => {
    return () => {
      removeMailTemplate.mutate(mailTemplate.name, {
        onSuccess: () => loadMailTemplateList()
      })
    };
  }

  const onSelectHandlerClosure = (mailTemplate: MailTemplateDto) => {
    return async () => {
      const response = await mailApiService.getMailTemplate(mailTemplate.name);
      const fileData = new Uint8Array(response.data);
      const file = new File([fileData], getFileNameFromUUIDPath(mailTemplate.name), { type: 'application/octet-stream' });
      onSelect?.(file);
      onClose?.();
    }
  }

  const downloadFile = (mailTemplate: MailTemplateDto) => {
    return async () => {
      const response = await mailApiService.getMailTemplate(mailTemplate.name);
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = getFileNameFromUUIDPath(mailTemplate.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          대량 발송 템플릿 관리
          <Button
            component="label"
          >
            템플릿 저장
            <input
              type="file"
              hidden
              onChange={uploadFileHandler}
            />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>파일명</TableCell>
                <TableCell align="center" width={200}>생성일자</TableCell>
                <TableCell width={140}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                mailTemplateList?.map((mailTemplate) => (
                  <TableRow
                    key={mailTemplate.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link onClick={downloadFile(mailTemplate)}>
                        {getFileNameFromUUIDPath(mailTemplate.name)}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(mailTemplate.date).format('YYYY-MM-DD HH:mm:ss')}
                    </TableCell>
                    <TableCell align="right">
                      <Box gap={1} display="flex">
                        <Button
                          variant="outlined"
                          onClick={onSelectHandlerClosure(mailTemplate)}
                        >
                          적용
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={onRemoveHandlerClosure(mailTemplate)}
                        >
                          삭제
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  )
});

export default EmailTemplateDialog;
