import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useGetMoyoOnlyPlanInfo } from '../../hooks/queries/moyo-only/useGetMoyoOnlyPlanInfo';
import { GetMoyoOnlyPlanTypes, PlanMetaJsonTypes } from './type';
import { SpecialGroupNameSelectList } from '../../entity/plan';
import { useModifyMoyoOnlyPlan } from '../../hooks/queries/moyo-only/useModifyMoyoOnlyPlan';

export default function MoyoOnlyPlanPage() {
  const { handleSubmit, reset, register, watch, getValues, setValue } = useForm(
    {},
  );
  const { id } = useParams();

  const { plan }: { plan: GetMoyoOnlyPlanTypes | undefined } =
    useGetMoyoOnlyPlanInfo(Number(id));

  const parsedPlanMeta: PlanMetaJsonTypes = plan
    ? JSON.parse(plan.planMetaJson)
    : null;

  const modifyMoyoOnlyPlanMutation = useModifyMoyoOnlyPlan();

  const modifyMoyoOnlyPlanRequest = (id: number, formatPayload: string) => {
    modifyMoyoOnlyPlanMutation.mutate({
      requestId: id,
      planMetaJson: formatPayload,
    });
  };

  const responseSubmit = () => {
    return alert('성공적으로 수정되었습니다.');
  };

  const NotifyIsError = (notifyText: string) => {
    return alert(notifyText);
  };

  const validateData = (fee: number, feeAfterDiscount: number) => {
    return fee > feeAfterDiscount;
  };

  const onSubmit = (formData: PlanMetaJsonTypes) => {
    if (validateData(formData.fee, formData.feeAfterDiscount)) {
      NotifyIsError('할인가격이 할인종료 후 가격보다 큽니다!');
      return;
    }
    const formatPayload = JSON.stringify(formData);
    try {
      modifyMoyoOnlyPlanRequest(Number(id), formatPayload);
      responseSubmit();
    } catch (error) {
      alert('수정에 실패하였습니다.');
    }
  };

  useEffect(() => {
    reset({
      name: parsedPlanMeta?.name,
      mvno: plan?.mvno,
      mno: parsedPlanMeta?.mno,
      feeAfterDiscount: parsedPlanMeta?.feeAfterDiscount,
      fee: parsedPlanMeta?.fee,
      promotionPeriod: parsedPlanMeta?.promotionPeriod,
      partnerSignupUrl: parsedPlanMeta?.partnerSignupUrl,
      dataSpeed: parsedPlanMeta?.dataSpeed,
      dataSpeed2: parsedPlanMeta?.dataSpeed2,
      call: parsedPlanMeta?.call,
      textMessage: parsedPlanMeta?.textMessage,
      throttledSpeed: parsedPlanMeta?.throttledSpeed,
      code: parsedPlanMeta?.code,
      tethering: parsedPlanMeta?.tethering,
      tetheringAmount: parsedPlanMeta?.tetheringAmount,
      tetheringDailyAmount: parsedPlanMeta?.tetheringDailyAmount,
      additionalCall: parsedPlanMeta?.additionalCall,
      isSpecialCase: parsedPlanMeta?.isSpecialCase,
      specialGroupName: parsedPlanMeta?.specialGroupName,
      planId: parsedPlanMeta?.planId,
    });
  }, [plan]);

  const watchTethering = watch('tethering');
  const watchSpecialCase = watch('isSpecialCase');

  return (
    <>
      <div
        className="mt-3 pb-5"
        style={{ marginLeft: '2rem', marginRight: '2rem' }}
      >
        <div className="mb-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>요금제 상세</h3>
              <div style={{ display: 'inline' }}>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  type="submit"
                >
                  수정
                </Button>
              </div>
            </div>
            <hr />
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>모요온리 요청 id</Form.Label>
                  <Form.Control defaultValue={id} disabled />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>mvno</Form.Label>
                  <Form.Control
                    {...register('mvno', {
                      required: false,
                    })}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>mno</Form.Label>
                  <Form.Control
                    {...register('mno', {
                      required: false,
                    })}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>요금제명</Form.Label>
                  <Form.Control
                    {...register('name', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>파트너사 링크</Form.Label>
                  <Form.Control
                    {...register('partnerSignupUrl', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>요금제 코드</Form.Label>
                  <Form.Control
                    {...register('code', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div style={{ margin: '3rem 0' }}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>가격</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('fee', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>할인적용 개월수</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('promotionPeriod', {
                        required: false,
                      })}
                      onChange={(event) => {
                        const enteredValue = parseInt(event.target.value);
                        if (enteredValue > 36) {
                          setValue('promotionPeriod', 36);
                          NotifyIsError(
                            '할인개월 수는 36개월 이하입니다.\n개발자에게 문의해주세요.',
                          );
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>할인끝난후 금액</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('feeAfterDiscount', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>데이터(GB)</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('dataSpeed', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>일 추가 데이터(GB)</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('dataSpeed2', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>소진시속도(Mbps)</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('throttledSpeed', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>통화(분)</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('call', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>문자(건)</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('textMessage', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>부가 통화량</Form.Label>
                    <Form.Control
                      type="number"
                      {...register('additionalCall', {
                        required: false,
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Form.Group style={{ display: 'flex', gap: '0.5rem' }}>
                  <Form.Label>핫스팟 지원</Form.Label>
                  <Form.Switch
                    type="boolean"
                    {...register('tethering', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ marginBottom: '0.5rem' }}>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>월 제공량</Form.Label>
                  <Form.Control
                    type="number"
                    {...register('tetheringAmount', {
                      required: false,
                    })}
                    disabled={!watchTethering}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>일 제공량</Form.Label>
                  <Form.Control
                    type="number"
                    {...register('tetheringDailyAmount', {
                      required: false,
                    })}
                    disabled={!watchTethering}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group style={{ display: 'flex', gap: '0.5rem' }}>
                  <Form.Label>특수 요금제</Form.Label>
                  <Form.Switch
                    type="boolean"
                    {...register('isSpecialCase', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: '0.5rem' }}>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>종류</Form.Label>
                  <Form.Select
                    disabled={!watchSpecialCase}
                    {...register('specialGroupName')}
                  >
                    {SpecialGroupNameSelectList.map((group) => (
                      <option value={group.key}>{group.value}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}
