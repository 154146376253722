import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';

interface MailPreviewDialogProps {
  openData?: { email?: string, title?: string, content?: string };
  onClose?: () => void;
}

const MailPreviewDialog: FC<MailPreviewDialogProps> = (({
  openData,
  onClose,
}) => {
  return (
    <Dialog open={!!openData} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {openData?.title}
          <Typography variant='caption'>{openData?.email}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dangerouslySetInnerHTML={{ __html: openData?.content || '' }} />
      <DialogActions>
        <Button onClick={onClose}>확인</Button>
      </DialogActions>
    </Dialog>
  )
});

export default MailPreviewDialog;
