import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDebounce } from '../../common/hooks';
import { FilterComponent } from '../../component/FilterComponent';
import apiService from '../../service/api.service';
import FilterSelectComponent from '../../component/FilterSelectComponent';
import { MnosWithAll, MvnosWithAll } from '../../common/constants';
import { TableContainer } from '@mui/material';

const formatter = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW',
});

const planColumns = [
  {
    name: 'id',
    selector: (row) => row.id,
    sortable: true,
    maxWidth: '60px',
  },
  {
    name: 'MVNO',
    selector: (row) => row.mvno,
    sortable: true,
    maxWidth: '150px',
  },
  {
    name: '요금제 이름',
    selector: (row) => row.name,
    sortable: true,
    minWidth: '250px',
  },
  {
    name: 'MNO',
    selector: (row) => row.mno,
    sortable: true,
    maxWidth: '150px',
  },
  {
    name: '망',
    selector: (row) => row.net,
    sortable: true,
  },
  {
    name: '개월',
    selector: (row) => row.numberOfMonthsForDiscount,
    sortable: true,
  },
  {
    name: '가격',
    selector: (row) => row.fee,
    sortable: true,
    cell: (row) => formatter.format(row.fee),
  },
];

export default function AddPlansPopup({
  detail,
  mvnoList,
  show,
  onHide,
  onAddPlans,
  onAddPlansGroup,
  mvno,
}) {
  const [plans, setPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [mnoSelect, setMnoSelect] = useState(null);
  const [mvnoSelect, setMvnoSelect] = useState(mvno);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const debouncedFilterText = useDebounce(filterText, 500);

  const fetchPlans = useCallback(async () => {
    const response = await apiService.getPlansByMnoAndMvno(
      encodeURIComponent(mvnoSelect),
      encodeURIComponent(mnoSelect),
      encodeURIComponent(filterText),
      false,
    );
    setPlans(response.data.result);
  }, [mvnoSelect, mnoSelect, filterText]);

  useEffect(() => {
    if (detail) fetchPlans();
  }, [detail, debouncedFilterText, fetchPlans]);

  useEffect(() => {
    if (mnoSelect == null && mvnoSelect == null) return;

    fetchPlans();
  }, [fetchPlans, mnoSelect, mvnoSelect]);

  const handleRowSelected = useCallback((state) => {
    setSelectedPlans(state.selectedRows);
  }, []);

  const onClickAdd = useMemo(() => {
    return () => {
      onAddPlans(selectedPlans);
      onHide();
    };
  }, [selectedPlans]);

  // const onClickAdd = () => {
  //   onAddPlans(selectedPlans);
  //   onHide();
  // };

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const mnoSelectBox = useMemo(() => {
    return (
      <FilterSelectComponent
        selectList={Object.values(MnosWithAll)}
        onChange={(e) => setMnoSelect(e.target.value)}
        value={mnoSelect}
      />
    );
  });

  const mvnoSelectBox = useMemo(() => {
    return (
      <FilterSelectComponent
        selectList={Object.values(MvnosWithAll)}
        onChange={(e) => setMvnoSelect(e.target.value)}
        value={mvnoSelect}
      />
    );
  });

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>요금제 추가</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          title={`요금제${
            selectedPlans.length ? ` (${selectedPlans.length})` : ''
          }`}
          columns={planColumns}
          data={plans}
          pagination
          selectableRows
          paginationPerPage={50}
          selectableRowsHighlight
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          onSelectedRowsChange={handleRowSelected}
          subHeader
          subHeaderComponent={[mnoSelectBox, mvnoSelectBox, searchBox]}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button variant="primary" onClick={onClickAdd}>
          추가하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
