import { Spacing } from '../../../component/Spacing';
import { SearchSection } from './SearchSection';
import { SettlementSubCategoryTabs } from './SettlementSubCategoryTabs';
import { SummarySection } from './SummarySection';
import { settlementSubRoutKeyByValue } from '../routes';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

export function SettlementSubCategoryLayout({
  category,
  subCategory,
}: {
  category: string;
  subCategory: string;
}) {
  return (
    <>
      <Spacing size={16} />
      <SettlementSubCategoryTabs
        category={category}
        subCategory={subCategory}
      />
      <Spacing size={32} />
      <SearchSection />
      <Spacing size={24} />
      <SummarySection
        subCategory={subCategory}
        subCategoryName={settlementSubRoutKeyByValue[subCategory]}
      />
      <Suspense
        fallback={
          <div
            style={{
              padding: '80px',
              height: '640px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
}
