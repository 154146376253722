import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Form } from 'react-bootstrap';
import apiService from '../../service/api.service';
import { useCreateConstraint } from '../../hooks/queries/moyo-only/useCreateConstraint';
import { ConstraintApiInterface } from '../../apis/service/constraints/types';

interface MoyoOnlyRequestPopupProps {
  open: boolean;
  onClose: () => void;
  selectedData: number[];
}

interface Constraint {
  description: string;
}

export const ConstraintModal: FC<MoyoOnlyRequestPopupProps> = ({
  open,
  onClose,
  selectedData,
}) => {
  const [dangerConstraintList, setDangerConstraintList] = useState<
    Constraint[]
  >([]);
  const [infoConstraintList, setInfoConstraintList] = useState<Constraint[]>(
    [],
  );

  const addDangerConstraint = () => {
    const newConstraint: Constraint = {
      description: '',
    };
    setDangerConstraintList([...dangerConstraintList, newConstraint]);
  };

  const addInfoConstraint = () => {
    const newConstraint: Constraint = {
      description: '',
    };
    setInfoConstraintList([...infoConstraintList, newConstraint]);
  };

  const handleDangerInputChange = (index: number, value: string) => {
    // 입력 필드의 값을 업데이트
    const updatedConstraints = [...dangerConstraintList];
    updatedConstraints[index].description = value;
    setDangerConstraintList(updatedConstraints);
  };

  const handleInfoInputChange = (index: number, value: string) => {
    // 입력 필드의 값을 업데이트
    const updatedConstraints = [...infoConstraintList];
    updatedConstraints[index].description = value;
    setInfoConstraintList(updatedConstraints);
  };

  const responseRequest = (text: string) => {
    onClose();
    return alert(text);
  };

  const CreatePlanConstraintMutation = useCreateConstraint();

  const onSubmit = () => {
    try {
      const JsonStringifyContent = JSON.stringify({
        info: infoConstraintList,
        danger: dangerConstraintList,
      });
      const requestParameter: ConstraintApiInterface = {
        planIds: selectedData,
        content: JsonStringifyContent,
      };
      CreatePlanConstraintMutation.mutate(requestParameter);
      responseRequest('성공적으로 일괄 적용하였습니다!');
    } catch (error) {
      console.log(error);
      responseRequest(
        '일괄 적용에 에러가 발생했습니다.ㅜ개발자에게 문의해주세요',
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ width: '500px' }}>제약조건 일괄 추가하기</DialogTitle>
      <DialogContent>
        <Form.Group className="mb-3 mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Form.Label>경고성 정보 내용</Form.Label>
            <Button onClick={addDangerConstraint}>내용 추가</Button>
          </div>
          {dangerConstraintList.map((constraint, index) => (
            <Form.Control
              className="mb-3"
              key={index}
              value={constraint.description}
              onChange={(e) => handleDangerInputChange(index, e.target.value)}
            />
          ))}
        </Form.Group>
        <Form.Group className="mb-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Form.Label>안내성 정보 내용</Form.Label>
            <Button onClick={addInfoConstraint}>내용 추가</Button>
          </div>
          {infoConstraintList.map((constraint, index) => (
            <Form.Control
              className="mb-3"
              key={index}
              value={constraint.description}
              onChange={(e) => handleInfoInputChange(index, e.target.value)}
            />
          ))}
        </Form.Group>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit}>요청하기</Button>
      </DialogActions>
    </Dialog>
  );
};
