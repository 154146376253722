import { FormProvider, set, useForm } from 'react-hook-form';
import { CreateMoyoPlanTypes } from './type';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FeeInformationInputs } from './components/FeeInformationInputs';
import { DiscountInputFields } from './components/PlanDataInputs';
import { MnoSelect } from './components/MnoSelect';
import { NetworkSelect } from './components/NetworkSelect';
import { MvnoSelect } from './components/MvnoSelect';
import { PartnerLinks } from './components/PartnersLink';
import { PlanCodeInput } from './components/PlanCodeInput';
import { AdditionalCall } from './components/AdditionalCall';
import { TetheringService } from './components/TetheringService';
import { SpecialCase } from './components/SpecialCase';
import { useCreateMoyoOnlyPlan } from '../../hooks/queries/moyo-plan/useCreateMoyoPlan';
import { KtNetworkRegistered } from './components/KtNetworkRegistered';

const plan: CreateMoyoPlanTypes = {
  name: '',
  carrier: '',
  mno: 'LGU',
  dataSpeed: null,
  dataSpeed2: null,
  throttledSpeed: null,
  call: null,
  textMessage: null,
  fee: null,
  feeAfterDiscount: null,
  promotionPeriod: null,
  code: null,
  partnerSignupUrl: null,
  additionalCall: null,
  isSpecialCase: false,
  specialGroupName: null,
  tethering: false,
  tetheringAmount: null,
  tetheringDailyAmount: null,
  network: 'LTE',
  isKtNetworkRegistered: false,
};

function CreatePlanPage() {
  const form = useForm<CreateMoyoPlanTypes>({ defaultValues: plan });
  const { handleSubmit, setValue, watch } = form;

  const validateData = (
    fee: number,
    feeAfterDiscount: number,
    promotionPeriod: number,
  ) => {
    console.log(feeAfterDiscount === null && promotionPeriod === null);
    if (feeAfterDiscount === null && promotionPeriod === null) {
      return false;
    } else {
      return fee > feeAfterDiscount;
    }
  };

  const validateResponse = (text: string) => {
    return alert(text);
  };

  const transformData = (data: CreateMoyoPlanTypes) => {
    const setData = { ...data };
    if (setData.throttledSpeed) {
      setData.throttledSpeed *= 1000;
    }

    if (setData.tethering === false) {
      setData.tetheringAmount = null;
      setData.tetheringDailyAmount = null;
    }

    if (setData.isSpecialCase === false) {
      setData.specialGroupName = null;
    }

    if (
      !validateData(
        setData.fee as number,
        setData.feeAfterDiscount as number,
        setData.promotionPeriod as number,
      )
    ) {
      return setData;
    } else {
      validateResponse(
        '할인시 가격이 할인종료 후 가격보다 큽니다.\n다시 확인해주세요!',
      );
      return;
    }
  };

  const createMoyoPlanMutation = useCreateMoyoOnlyPlan();
  const onSubmit = (formData: CreateMoyoPlanTypes) => {
    const formatData = transformData(formData);
    if (formatData) {
      try {
        createMoyoPlanMutation.mutate(formatData);
        alert('새로운 요금제를 생성하였습니다.');
      } catch (error) {
        alert('요금제 생성에 실패했어요');
        console.log(error);
      }
    }
  };
  return (
    <FormProvider {...form}>
      <div style={{ display: 'flex', height: '100%' }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& > div': { marginBottom: '12px' },
            margin: '2rem auto 2rem auto',
            width: '75%',
            minWidth: '75%',
          }}
        >
          <Typography variant="h5" fontWeight={'bold'} mb={3}>
            모요 요금제 생성하기
          </Typography>
          <FeeInformationInputs
            name={plan.name}
            fee={plan.fee}
            feeAfterDiscount={plan.feeAfterDiscount}
            promotionPeriod={plan.promotionPeriod}
          />
          <Grid container sx={{ gap: 'auto' }}>
            <MvnoSelect carrier={plan.carrier} />
            <MnoSelect mno={plan.mno} />
            <NetworkSelect network={plan.network} />
          </Grid>
          {watch('mno') === 'KT' ? (
            <KtNetworkRegistered
              isKtNetworkRegistered={plan.isKtNetworkRegistered}
            />
          ) : (
            <></>
          )}
          <Grid container spacing={2}>
            <PartnerLinks partnerSignupUrl={plan.partnerSignupUrl} />
            <PlanCodeInput code={plan.code} />
          </Grid>

          <DiscountInputFields
            call={plan.call}
            textMessage={plan.textMessage}
            dataSpeed={plan.dataSpeed}
            dataSpeed2={plan.dataSpeed2}
            throttledSpeed={plan.throttledSpeed}
          />
          <Grid container>
            <AdditionalCall additionalCall={plan.additionalCall} />
            <TetheringService
              tethering={plan.tethering}
              tetheringAmount={plan.tetheringAmount}
              tetheringDailyAmount={plan.tetheringDailyAmount}
            />
          </Grid>
          <SpecialCase
            isSpecialCase={plan.isSpecialCase}
            specialGroupName={plan.specialGroupName}
          />
          <Button variant="contained" type="submit">
            생성하기
          </Button>
        </Box>
      </div>
    </FormProvider>
  );
}

export default CreatePlanPage;
