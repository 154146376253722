import React, { useContext, useState } from 'react';
import { ListItemIcon, MenuItem } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerNavigationListItem from './DrawerNavigationList';
import PushPinIcon from '@mui/icons-material/PushPin';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CelebrationIcon from '@mui/icons-material/Celebration';
import RocketIcon from '@mui/icons-material/Rocket';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import EmailIcon from '@mui/icons-material/Email';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DrawerButton from './DrawerButton';
import { Logout } from '@mui/icons-material';
import { UserContext } from '../../context/UserContext';

const drawerWidth = 280;

const ListFrame = styled('div')`
  height: calc(100% - 128px);
  overflow: auto;
`;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // necessary for content to be below app bar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '.MuiPaper-root': {
    boxShadow: theme.shadows[12],
  },
}));

const ProfileFrame = styled('div')`
  display: flex;
  padding: 16px;
  height: 64px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;
const GoToV2Frame = styled('div')`
  display: flex;
  padding: 16px;
  height: 64px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;

const rootPath = {
  settlement: '/settlement',
  nps: '/nps',
};

const Tabs = [
  {
    name: '요금제 생성',
    path: '/plan/create',
    icon: <AddIcon />,
  },
  {
    name: '요금제 관리',
    icon: <SettingsApplicationsIcon />,
    collapse: [
      {
        name: '요금제 찾기 및 수정',
        path: '/plans',
      },
      {
        name: '요금제 제약조건',
        path: '/constraint',
      },
      {
        name: '요금제 상세보기',
        path: '/plan-detail',
      },
      {
        name: '모요 온리 요금제 관리',
        path: '/moyo-only/management',
      },
      {
        name: 'Disclaimer',
        path: '/disclaimer',
      },
    ],
  },
  {
    name: '이벤트 관리',
    icon: <CelebrationIcon />,
    collapse: [
      {
        name: 'mvno 이벤트',
        path: '/events',
      },
    ],
  },
  {
    name: '광고',
    path: '/ads',
    icon: <RocketIcon />,
  },
  {
    name: 'seo',
    path: '/seo',
    icon: <ScreenSearchDesktopIcon />,
  },
  {
    name: 'E-Mail',
    path: '/email',
    icon: <EmailIcon />,
  },
  {
    name: '질/답 관리',
    icon: <QuestionMarkIcon />,
    collapse: [
      {
        name: '질문/답변',
        path: 'community',
      },
      {
        name: '질문/답변 생성',
        path: '/generate/community',
      },
    ],
  },
  {
    name: '정산',
    icon: <PaymentsIcon />,
    collapse: [
      {
        name: 'Summary',
        path: `${rootPath.settlement}/summary`,
      },
      {
        name: '레퍼러',
        path: `${rootPath.settlement}/referrals`,
      },
      {
        name: '제휴채널',
        path: `${rootPath.settlement}/alliances`,
      },
      {
        name: '인플루언서',
        path: `${rootPath.settlement}/influencers`,
      },
      {
        name: '쿠폰',
        path: `${rootPath.settlement}/coupons`,
      },
    ],
  },
  {
    name: 'NPS',
    icon: <PeopleIcon />,
    collapse: [
      {
        name: 'NPS 생성',
        path: rootPath.nps,
      },
      {
        name: 'NPS 템플릿',
        path: `${rootPath.nps}/template`,
      },
      {
        name: 'NPS 결과',
        path: `${rootPath.nps}/result`,
      },
    ],
  },
];

const DrawerNavigation = () => {
  const { logoutUser } = useContext(UserContext);
  const [open, setOpen] = useState(true);
  const [pin, setPin] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePushPin = () => {
    setPin(!pin);
  };

  const isPin = pin ? handleDrawerOpen : handleDrawerClose;

  const navigate = useNavigate();
  const onClickLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logoutUser();
      navigate('/login');
    }
  };

  const GoToV2 = () => {
    const adminUrl = 'https://admin.moyoplan.team';
    return (window.location.href = adminUrl);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={isPin}
      >
        <DrawerHeader>
          {open && (
            <Typography
              variant="h5"
              my={2}
              fontWeight={600}
              margin={'20px auto 20px 26px'}
              onClick={() => navigate('/')}
              sx={{ cursor: 'pointer' }}
            >
              Moyo admin
            </Typography>
          )}
          {open ? (
            <IconButton onClick={handlePushPin}>
              <PushPinIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{ margin: 'auto', marginTop: '16px' }}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <ListFrame>
          <List id="navigation-box">
            {Tabs.map((navigationTab) => (
              <DrawerNavigationListItem
                key={navigationTab.name}
                navigationTab={navigationTab}
                activePath={''}
                foldedNavigation={!open}
              />
            ))}
          </List>
        </ListFrame>
        <ProfileFrame>
          <div style={{ display: 'flex' }}>
            <DrawerButton
              icon={
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
              }
              text="로그아웃"
              foldedNavigation={!open}
              onClickProps={onClickLogout}
            />
            <DrawerButton
              icon={
                <ListItemIcon>
                  <ChangeCircleIcon fontSize="small" />
                </ListItemIcon>
              }
              text="admin v2"
              foldedNavigation={!open}
              onClickProps={GoToV2}
            />
          </div>
        </ProfileFrame>
      </Drawer>
    </Box>
  );
};

export default DrawerNavigation;
