import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { SETTLEMENT_MAIN_PATH, settlementSubTabsByCategory } from '../routes';
import { Tab, TabProps, Tabs, styled } from '@mui/material';

export function SettlementSubCategoryTabs({
  category,
  subCategory,
}: {
  category: string;
  subCategory: string;
}) {
  const navigate = useNavigate();
  const handleChange = (_: SyntheticEvent, subCategory: string) => {
    navigate(`/${SETTLEMENT_MAIN_PATH}/${category}/${subCategory}`);
  };

  return (
    <Tabs
      value={subCategory}
      sx={{ minHeight: 'initial' }}
      onChange={handleChange}
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
    >
      {settlementSubTabsByCategory[category].map(({ label, value }) => (
        <SubHeaderTab key={value} label={label} value={value} />
      ))}
    </Tabs>
  );
}

const SubHeaderTab = styled(Tab)<TabProps>({
  textTransform: 'none',
  padding: '9px 16px',
  minHeight: 'initial',
  lineHeight: '24px',
});
