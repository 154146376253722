import { FC, JSXElementConstructor, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import apiService from '../../service/api.service';
import { PlanConstraint } from './types';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import styled from '@emotion/styled';

const StyledDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid #f0f0f0`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid 
      #f0f0f0
    `,
  },
  '& .MuiDataGrid-cell': {
    color: 'rgba(0,0,0,.85)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
}));

const PlanRelatedConstraintDataTable = ({
  mvno,
  mno,
  planId,
}: {
  mvno: string;
  mno: string;
  planId: number;
}) => {
  const [constraints, setConstraints] = useState<PlanConstraint[]>([]);

  const getConstraints = async () => {
    if (mvno == null || mno == null || planId == null) {
      return;
    }

    const response = await apiService.getConstraintsForPlan(mvno, mno, planId);
    if (response && response.data) {
      setConstraints(response.data.result);
    }
  };

  const parsedContents = constraints.map((constraint) => ({
    ...constraint,
    content: JSON.parse(constraint.content),
  }));

  useEffect(() => {
    getConstraints();
  }, [mvno, mno, planId]);

  const createButton = (
    <Link
      style={{ marginLeft: 'auto', marginRight: '0' }}
      to={`/constraint/add?mvno=${mvno}&mno=${mno}&planId=${planId}&constraintId=${parsedContents[0]?.id}`}
    >
      <Button>제약 조건 추가</Button>
    </Link>
  );

  const handleRowClick = (constraint: PlanConstraint) =>
    window.open(`/constraint/${constraint.id}`);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {createButton}
      <StyledDataGrid
        columns={dataTableColumns}
        rows={parsedContents}
        onRowClick={() => handleRowClick}
      />
    </div>
  );
};

const dataTableColumns: GridColDef[] = [
  {
    field: 'id',
    renderCell: (param: GridCellParams) => param.row.id,
    sortable: true,
    width: 20,
  },
  {
    field: '타입',
    renderCell: (param: GridCellParams) => param.row.type,
    width: 120,
  },
  {
    field: '상세 타입',
    renderCell: (param: GridCellParams) => param.row.detailType,
    width: 120,
  },
  {
    field: '내용',
    renderCell: (param: GridCellParams) => {
      return (
        <>
          경고성:{' '}
          {param.row.content?.danger?.map(
            (constraint: { description: string }) => constraint?.description,
          )}
          <br />
          정보성:{' '}
          {param.row.content?.info?.map(
            (constraint: { description: string }) => constraint?.description,
          )}
        </>
      );
    },
    width: 600,
  },
  {
    field: '등록일자',
    renderCell: (param: GridCellParams) => param.row.regTs,
    width: 240,
  },
];

export default PlanRelatedConstraintDataTable;
