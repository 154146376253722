import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FilterComponent } from './FilterComponent';
import { useLocation } from 'react-router-dom';
import { Mvnos, MvnosWithAll } from '../common/constants';
import { useDebounce } from '../common/hooks';
import apiService from '../service/api.service';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

const PlanDataTable = ({ onRowClick, urlPath }) => {
  const navigate = useNavigate();
  const mvnoParam = new URLSearchParams(useLocation().search).get('mvno');
  let paramMvno =
    Object.values(Mvnos).find((e, key) => e.value === mvnoParam) ||
    MvnosWithAll.ALL;
  const [mvno, setMvno] = useState(paramMvno);
  const [plans, setPlans] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const debouncedFilterText = useDebounce(filterText, 500);
  console.log(mvno);

  useEffect(() => {
    setMvno(paramMvno);
  }, []);
  const getPlans = async () => {
    const response = await apiService.getPlansByMvno(
      encodeURIComponent(mvno.value),
      encodeURIComponent(filterText),
      false,
    );
    setPlans(response?.data?.result);
  };

  useEffect(() => {
    getPlans().then((r) => navigate(`/${urlPath}?mvno=` + mvno.value));
  }, [mvno, debouncedFilterText]);

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <DropdownButton
        key="mvnoFilter"
        title={mvno.name}
        variant="outline-primary"
        size="sm"
      >
        {Object.keys(MvnosWithAll).map((key) => {
          const item = MvnosWithAll[key];
          return (
            <Dropdown.Item eventKey={key} onClick={() => setMvno(item)}>
              {item.name}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <DataTable
        columns={planColumns}
        data={plans}
        onRowClicked={onRowClick}
        pagination
        paginationPerPage={30}
        selectableRows
        selectableRowsHighlight
        subHeaderComponent={[searchBox]}
        subHeader
      />
    </>
  );
};

export default PlanDataTable;

const formatter = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW',
});
const feeSort = (rowA, rowB) => {
  console.log(rowA);
  const a = rowA.fee;
  const b = rowB.fee;

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const planColumns = [
  {
    name: 'id',
    selector: (row) => row.id,
    sortable: true,
    maxWidth: '5px',
    center: true,
  },
  {
    name: 'MVNO',
    selector: (row) => row.mvno,
    sortable: true,
    maxWidth: '30px',
    center: true,
  },
  {
    name: '요금제 이름',
    selector: (row) => row.name,
    sortable: true,
    minWidth: '50px',
    maxWidth: '200px',
    center: true,
  },
  {
    name: 'MNO',
    selector: (row) => row.mno,
    sortable: true,
    maxWidth: '30px',
  },
  {
    name: '가격',
    selector: (row) => formatter.format(row.fee),
    sortable: true,
    maxWidth: '30px',
    sortFunction: feeSort,
  },
  {
    name: '파트너링크(번호이동)',
    selector: (row) => row.partnerSignupUrl,
    sortable: true,
    width: 'auto',
    minWidth: '350px',
  },
  {
    name: '파트너링크(신규가입)',
    selector: (row) => row.partnerSignupUrlForNewPhoneNumber,
    sortable: true,
    maxWidth: '150px',
  },
  {
    name: '삭제',
    selector: (row) => (row.isDeleted ? '삭제됨' : ''),
    sortable: true,
    maxWidth: '10px',
  },
];
