import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { modifyMoyoOnlyPlan } from '../../../apis/service/moyo-only';

export interface modifyMoyoOnlyPlanType {
  requestId: number;
  planMetaJson: string;
}

export const useModifyMoyoOnlyPlan = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<AxiosResponse<void>>,
    modifyMoyoOnlyPlanType
  >({
    mutationKey: undefined,
    mutationFn: (parameter) =>
      modifyMoyoOnlyPlan({
        requestId: parameter.requestId,
        planMetaJson: parameter.planMetaJson,
      }),
  });
};
