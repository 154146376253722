import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { NetworkList } from '../../../entity/network';

type NetworkSelectProps = {
  network: string | null;
};

export const NetworkSelect: FC<NetworkSelectProps> = ({ network }) => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <div>
      <Typography variant="h6" my={2} mt={'30px'}>
        네트워크
      </Typography>
      <FormControl sx={{ mt: 1, mb: 1, minWidth: 150 }}>
        <InputLabel>종류</InputLabel>
        <Controller
          control={control}
          defaultValue={network}
          name="network"
          rules={{ required: true }}
          render={({ field }) => (
            <Select required {...field}>
              {NetworkList.map((group, index) => (
                <MenuItem key={index} value={group.value}>
                  {group.value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};
