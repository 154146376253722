import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Main } from '../../../component/Main';
import { OrderStatusOptionList } from '../../../entity/order';
import { npsService, SurveyTarget } from '../../../service/npsService';

const NpsTargetTableColumns: TableColumn<SurveyTarget>[] = [
  {
    name: '순번',
    selector: 'id',
    maxWidth: '30px',
  },
  {
    name: '상태값',
    selector: 'name',
    cell: (row) => (
      <Link
        to={`/nps/result/${row.id}?surveyTemplateId=${row.surveyTemplateId}`}
      >
        {
          OrderStatusOptionList.find((item) => item.key === row.targetStatus)
            ?.name
        }
      </Link>
    ),
  },
  {
    name: '생성일',
    selector: 'regTs',
    maxWidth: '180px',
  },
  {
    name: '수정일',
    selector: 'updTs',
    maxWidth: '180px',
  },
];

const NpsResultListPage: FC = () => {
  const [templateList, setTemplateList] = useState<SurveyTarget[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    npsService
      .getSurveyTargetList()
      .then((rs) => {
        setTemplateList(rs.data.result ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Main>
        <DataTable
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>NPS 결과</div>
            </Grid>
          }
          columns={NpsTargetTableColumns}
          data={templateList}
          pagination
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          progressPending={loading}
        />
      </Main>
    </>
  );
};

export default NpsResultListPage;
