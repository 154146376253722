import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GenerateCommunityPost } from '../../../entity/generateCommunityEntity';
import { generateCommunityApiService } from '../../../service/generateCommunityService';
import RefreshIcon from '@mui/icons-material/Refresh';
import styled from '@emotion/styled';
import RANDOM_NAMES from '../../../data/random-name';
import moment from 'moment';
import { gptApiService } from '../../../service/gptService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const _TitleFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
`;

interface GenerateCommunityEditDialogProps {
  generateCommunityPost?: GenerateCommunityPost;
  onClose: () => void;
  onSubmit: () => void;
}

const GenerateCommunityEditDialog: FC<GenerateCommunityEditDialogProps> = ({
  generateCommunityPost,
  onClose,
  onSubmit,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [gptAnswer, setGPTAnswer] = useState<GenerateCommunityPost>();
  const { register, watch } = useForm<{ [key in string]: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [metaTitle, setMetaTitle] = useState<string>();

  const getRandomTime = () => {
    const RandomHours = Math.round(9 + Math.random() * 11);
    const RandomMinute = Math.floor(Math.random() * 60);
    const RandomSeconds = Math.floor(Math.random() * 60);
    const time = moment()
      .add(-Math.floor(Math.random() * 30), 'days')
      .hours(RandomHours)
      .minute(RandomMinute)
      .second(RandomSeconds)
      .locale('ko')
      .toISOString();
    return time;
  };

  useEffect(() => {
    if (!!generateCommunityPost) {
      generateCommunityApiService
        .getGPTPost(generateCommunityPost.id)
        .then((rs) => {
          if (!rs.data) return;
          setGPTAnswer(rs.data);
        });
      // onLoadGPTAnswer(generateCommunityPost);
      setOpen(true);
      // return (() => {
      //   cancelTokenSourceRef.current?.cancel();
      // })
    }
  }, [generateCommunityPost]);

  const onLoadGPTAnswer = async (
    _generateCommunityPost: GenerateCommunityPost,
  ) => {
    cancelTokenSourceRef.current?.cancel();

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    cancelTokenSourceRef.current = source;
    setLoading(true);
    try {
      const rs = await generateCommunityApiService.getAskGPT(
        [_generateCommunityPost],
        source.token,
      );
      setGPTAnswer(rs.data.result?.[0]);
    } catch (_: any) {
      alert(`에러 발생 : ${_.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  const onCloseHandler = () => {
    setOpen(false);
    setGPTAnswer(undefined);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const onSubmitHandler = async () => {
    try {
      setSaveLoading(true);
      const content = watch('content');
      const title = watch('title');
      const answerList = Array.from({
        length: gptAnswer?.answerList.length ?? 0,
      })
        .map((_, index) => watch(`answer-${index}`))
        .filter((answer) => !!answer);

      if (!title || !content || !answerList?.length) {
        alert('제목, 내용, 답변 중 없는게 있습니다.');
        return;
      }
      await generateCommunityApiService.postManual({
        dummyPostId: generateCommunityPost?.id ?? 0,
        content,
        title,
        answerList,
        author: RANDOM_NAMES[Math.floor(Math.random() * RANDOM_NAMES.length)],
        regTs: getRandomTime(),
      });
      onSubmit();
    } catch (_: any) {
      alert(`저장 중 에러 : ${_.toString()}`);
    } finally {
      setSaveLoading(false);
    }
  };

  const metaTitleAccordionExpanded = async (_: any, expanded: boolean) => {
    if (!expanded) return;
    if (metaTitle) return;
    const rs = await gptApiService.getMetaTitle(
      `${generateCommunityPost?.title}\n${generateCommunityPost?.content}`,
    );
    setMetaTitle(rs.data.response);
  };

  return (
    <Dialog open={open} fullWidth maxWidth={false}>
      <DialogTitle>
        <_TitleFrame>
          타이틀 {generateCommunityPost?.id}{' '}
          <RefreshIcon
            onClick={() => {
              generateCommunityPost && onLoadGPTAnswer(generateCommunityPost);
            }}
          />
        </_TitleFrame>
      </DialogTitle>
      <DialogContent
        style={{
          position: 'relative',
          overflow: loading ? 'hidden' : 'inherit',
        }}
      >
        <Accordion onChange={metaTitleAccordionExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">추천 제목</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {metaTitle ? (
              <pre style={{ whiteSpace: 'pre-wrap' }}>{metaTitle}</pre>
            ) : (
              <Box my={2}>
                <LinearProgress />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2} pt={2}>
          <Grid container item spacing={1} direction="row">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="제목"
                disabled
                value={generateCommunityPost?.title}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="제목"
                key={gptAnswer?.title}
                defaultValue={gptAnswer?.title}
                {...(gptAnswer?.id ? register('title') : {})}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} direction="row">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="내용"
                disabled
                value={generateCommunityPost?.content}
                multiline
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key={gptAnswer?.content}
                fullWidth
                label="내용"
                defaultValue={gptAnswer?.content}
                multiline
                {...(gptAnswer?.id ? register('content') : {})}
              />
            </Grid>
          </Grid>
          {Array.from({
            length: Math.max(
              generateCommunityPost?.answerList.length ?? 0,
              gptAnswer?.answerList.length ?? 0,
            ),
          }).map((_, index) => (
            <Grid container item spacing={1} direction="row">
              <Grid item xs={6}>
                {generateCommunityPost?.answerList?.[index] && (
                  <TextField
                    fullWidth
                    label={`답변 ${index + 1}`}
                    disabled
                    value={generateCommunityPost?.answerList?.[index]}
                    multiline
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {gptAnswer?.answerList?.[index] && (
                  <TextField
                    key={gptAnswer?.answerList?.[index]}
                    fullWidth
                    label={`답변 ${index + 1}`}
                    defaultValue={gptAnswer?.answerList?.[index]}
                    multiline
                    {...register(`answer-${index}`)}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {loading && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: '#ffffff',
              opacity: 0.7,
              left: 0,
              top: 0,
              zIndex: 10,
            }}
          >
            <CircularProgress
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onCloseHandler}>
          취소
        </Button>
        <Button variant="outlined" color="primary" onClick={onSubmitHandler}>
          적용
        </Button>
      </DialogActions>
      {saveLoading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: '#ffffff',
            opacity: 0.7,
            left: 0,
            top: 0,
            zIndex: 10,
          }}
        >
          <CircularProgress
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      )}
    </Dialog>
  );
};

export default GenerateCommunityEditDialog;
