import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadFile } from '../../../common/file-utils';
import { PageTitle } from '../../../component/PageTitle';
import { settlementApiService } from '../../../service/settlementSevice';
import { Section } from '../components/Section';
import { userPerformanceColumns } from '../components/SettlementSubCategoryUserPerformanceSection';
import { convertEventTypeParameter } from '../convertEventTypeParameter';
import { useSettlementEventType } from '../hooks/useSettlementEventType';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';

import { Spacing } from '../../../component/Spacing';
import { moyoColors } from '../../../common/moyo-colors';
import { useLoading } from '../../../context/LoadingContext';
import EmailIcon from '@mui/icons-material/Email';

export function UserRewardSection() {
  const { loading: isGloballyLoading, setLoading } = useLoading();
  const { eventType: eventTypeRaw } = useSettlementEventType();
  const eventType = convertEventTypeParameter(eventTypeRaw);
  const { endDate, startDate } = useSettlementPeriod();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'totalMoyoRewardValue', sort: 'desc' },
  ]);

  const [openSMSDialog, setOpenSMSDialog] = useState(false);

  const { data, isLoading } = useQuery(
    [
      'settlement-user-rewards',
      endDate,
      startDate,
      eventType,
      page,
      pageSize,
      sortModel[0],
    ],
    () =>
      settlementApiService.getRewardsByUser({
        eventType,
        endDate,
        startDate,
        page,
        size: pageSize,
        sort:
          sortModel[0] != null
            ? `${sortModel[0].field},${sortModel[0].sort}`
            : undefined,
      }),
    { refetchOnWindowFocus: false, refetchOnMount: false },
  );

  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const rowCount = data?.data.result?.totalSize ?? 0;
  const rows = data?.data.result?.list ?? [];

  return (
    <Section>
      <PageTitle>전체 유저별 성과</PageTitle>
      <Paper sx={{ height: 700, width: '100%', padding: '16px' }}>
        <Box sx={{ display: 'flex', gap: '16px', padding: '16px' }}>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              setLoading(true);
              try {
                const response =
                  await settlementApiService.getRewardsByUserSheet({
                    eventType,
                    endDate,
                    startDate,
                    rewardUserIds:
                      rows
                        .filter((row) => selectedIds.includes(row.id))
                        .map((row) => row.userId)
                        .filter((a): a is number => a != null) ?? [],
                  });
                downloadFile(response);
              } finally {
                setLoading(false);
              }
            }}
            disabled={
              selectedIds.length === 0 || isLoading || isGloballyLoading
            }
          >
            선택한 이벤트 엑셀 다운로드
          </Button>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            disabled={isLoading || isGloballyLoading}
            onClick={async () => {
              setLoading(true);
              try {
                const response =
                  await settlementApiService.getRewardsByUserSheet({
                    eventType,
                    endDate,
                    startDate,
                  });
                downloadFile(response);
              } finally {
                setLoading(false);
              }
            }}
          >
            전체 엑셀 다운로드
          </Button>
          <Button
            size="medium"
            variant="contained"
            startIcon={<EmailIcon />}
            disabled={
              selectedIds.length === 0 || isLoading || isGloballyLoading
            }
            onClick={async () => {
              setOpenSMSDialog(true);
            }}
          >
            제세 공과금 알림톡 보내기
          </Button>
        </Box>
        <DataGrid
          rowCount={rowCount}
          columns={userPerformanceColumns}
          rows={rows}
          checkboxSelection
          sx={{ border: 'none' }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isGloballyLoading}
          pagination
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        />
      </Paper>
      <Dialog
        open={openSMSDialog}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setOpenSMSDialog(false);
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            제세공과금 알림톡 {selectedIds.length}건 일괄 발송
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography color={moyoColors.indigo600} fontWeight={700}>
            알림톡 미리보기
          </Typography>
          <Spacing size={10} />
          <Typography>
            안녕하세요. 모요입니다.
            <br />
            {`{이벤트명} 이벤트에 참여해 주셔서 감사드립니다.`}
            <br />
            <br />
            경품 지급을 위한 개인정보를 요청드리니 아래 버튼을 클릭하여 제출
            부탁드립니다 🙂 감사합니다.
            <br />
            <br />
            해당 개인정보 수집을 거절하실 경우 경품 지급이 불가합니다.
            <br />
            {`{${
              new Date().getMonth() + 1
            }월 25일} 까지 정보가 확인되지 않으면 경품 지급이 취소될 수 있습니다.`}
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenSMSDialog(false);
            }}
          >
            취소
          </Button>
          <Button
            variant="contained"
            disabled={isLoading}
            onClick={async () => {
              setLoading(true);
              try {
                let response =
                  await settlementApiService.senTaxNotifierAlimtalk({
                    eventType,
                    endDate,
                    startDate,
                    rewardUserIds:
                      rows
                        .filter((row) => selectedIds.includes(row.id))
                        .map((row) => row.userId)
                        .filter((a): a is number => a != null) ?? [],
                  });

                if (response.data.result == true) {
                  alert('전송 성공!');
                  setOpenSMSDialog(false);
                }
              } catch (e) {
                window.alert('알림톡을 보내는 동안 오류가 발생했습니다.');
              } finally {
                setLoading(false);
              }
            }}
          >
            보내기
          </Button>
        </DialogActions>
      </Dialog>
    </Section>
  );
}
