import {
  SettlementRoutes,
  SettlementSubRoutes,
} from '../layout/settlement/routes';

export enum EventType {
  친구초대 = 'referral',
  애드링크 = 'adlink',
  제휴채널 = 'affiliate',
  인플루언서 = 'influencer',
  쿠폰 = 'coupon',
}

export function reverseKeyValue(target: object) {
  return Object.fromEntries(
    Object.entries(target).map(([key, value]) => [value, key]),
  );
}

export const eventTypeLabelByType = reverseKeyValue(EventType);

export enum EventName {
  친구초대 = 'referral',
  페이북 = 'affiliate_paybooc',
  핀크 = 'affiliate_finnq',
  뱅크샐러드 = 'affiliate_banksalad',
  에이블리 = 'affiliate_a-bly',
  정가거부 = 'influencer_refusal-of-price-07',
  정가거부_8 = 'influencer_refusal-of-price-08',
  정가거부_9 = 'influencer_refusal-of-price-09',
  애드링크1 = 'addlink_tier_1',
  애드링크2 = 'addlink_tier_2',
  모요쿠폰 = 'coupon_first_rocket',
}

export const eventNameLabelByName = reverseKeyValue(EventName);


export enum EventParameterType {
  eventType = 'eventType',
  eventName = 'eventName',
  none = 'none',
}
export const eventParameterTypeBySubCategories = {
  [SettlementSubRoutes.친구초대]: { type: EventParameterType.eventType, key: EventType.친구초대 },
  [SettlementSubRoutes.애드링크]: { type: EventParameterType.eventType, key: EventType.애드링크 },
  [SettlementSubRoutes.뱅크샐러드]: { type: EventParameterType.eventName, key: EventName.뱅크샐러드 },
  [SettlementSubRoutes.에이블리]: { type: EventParameterType.eventName, key: EventName.에이블리 },
  [SettlementSubRoutes.페이북]: { type: EventParameterType.eventName, key: EventName.페이북 },
  [SettlementSubRoutes.핀크]: { type: EventParameterType.eventName, key: EventName.핀크 },
  [SettlementSubRoutes.정가거부]: { type: EventParameterType.eventType, key: EventType.인플루언서 },
  [SettlementSubRoutes.모요쿠폰]: { type: EventParameterType.eventType, key: EventType.쿠폰 },
  [SettlementSubRoutes.브랜드쿠폰]: { type: EventParameterType.none, key: undefined },
  [SettlementSubRoutes.믹스쿠폰]: { type: EventParameterType.none, key: undefined },
};

export const eventTypesByCategory = {
  [SettlementRoutes.레퍼러]: [
    EventType.애드링크,
    EventType.친구초대
  ],
  [SettlementRoutes.제휴채널]: [
    EventType.제휴채널,
  ],
  [SettlementRoutes.인플루언서]: [
    EventType.인플루언서,
  ],
  [SettlementRoutes.쿠폰]: [
    EventType.쿠폰,
  ],
};

export interface Reward {
  id: number;
  eventName: EventName;
  eventType: EventType;
  paymentType: 'moyo' | 'partner' | 'mix';
  partnerName?: string;
  rewardType: 'activation' | 'without_activation' | 'draw';
  rewardItem: 'gift' | 'cash' | 'goods';
  getType: 'inviter' | 'invitee' | 'mix';
  moyoRewardValue?: number;
  partnerRewardValue?: number;
  moyoRewardMixValue?: number;
  partnerRewardMixValue?: number;
  createdA?: string;
  updatedAt: string;
}

export interface UserReward {
  id: number;
  eventName?: EventName;
  eventType?: EventType;
  paymentType?: 'moyo' | 'partner' | 'mix';
  partnerName?: string;
  rewardType?: 'activation' | 'without_activation' | 'draw';
  rewardItem?: 'gift' | 'cash' | 'goods';
  getType?: 'inviter' | 'invitee' | 'mix';
  userName?: string;
  userPhoneNumber?: string;
  totalCount?: number;
  totalMoyoRewardValue?: number;
  totalPartnerRewardValue?: number;
  totalMoyoRewardMixValue?: number;
  totalPartnerRewardMixValue?: number;
  userId?: number;
  mixUserId?: number;
  affiliateUserId?: number;
  accountNumber?: string;
}

export interface UserRewardTaxAccount {
  id: number;
  userId: number;
  name: string;
  phoneNumber: string;
  registrationNumber: string;
  idCardCopyLink: string;
  accountNumber: string;
  createdAt: string;
  updatedAt: string;
}

export interface BizmReward {
  id: number;
  eventName?: EventName;
  userName?: string;
  userPhoneNumber?: string;
  moyoRewardValue?: number;
  partnerRewardValue?: number;
}
