import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import apiService from '../../service/api.service';
import PlanRelatedConstraintDataTable from './PlanRelatedConstraintDataTable';
import PlanReserveEditDataTable from './PlanReserveEditDataTable';

export default function PlanDetail() {
  const { planId } = useParams();
  const [plan, setPlan] = useState(null);
  const [planHistoryList, setPlanHistoryList] = useState([]);

  const [reserveTime, setReserveTime] = useState(
    new Date(new Date().toDateString()),
  );
  const [reserveDeleteDate, setReserveDeleteDate] = useState(new Date());
  const [isReserveDeleteEnabled, setIsReserveDeleteEnabled] = useState(false);

  const { register, getValues, reset, watch, handleSubmit } = useForm({});

  const fetchPlan = async () => {
    if (!planId) return;

    try {
      const res = await apiService.getPlanById(planId);

      if (res && res.data && res.data.result) {
        setPlan(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPlanHistory = async () => {
    if (!planId) return;

    try {
      const res = await apiService.getPlanHistoryListById(planId);

      if (res && res.data && res.data.result) {
        setPlanHistoryList(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReservePlanDeleteDate = async () => {
    if (!planId) return;

    try {
      const res = await apiService.getPlanReserveDeleteDate(planId);
      if (res && res.data) {
        const applyDate = res.data.result;
        if (applyDate) {
          setReserveDeleteDate(new Date(applyDate));
          setIsReserveDeleteEnabled(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPlan();
    fetchPlanHistory();
    fetchReservePlanDeleteDate();
  }, []);

  useEffect(() => {
    if (!plan) return;

    reset({
      name: plan.name,
      mvno: plan.mvno,
      mno: plan.mno,
      bitlyUrl: plan.bitlyUrl,
      fee: plan.fee,
      createdDateTime: plan.createdDateTime,
      할인끝난후_금액: plan.할인끝난후_금액,
      할인적용_개월수: plan.할인적용_개월수,
      번호이동_파트너링크: plan.번호이동_파트너링크,
      신규가입_파트너링크: plan.신규가입_파트너링크,
      selfSignupUrl: plan.selfSignupUrl,
      dataSpeed: plan.mobileData,
      dataSpeed2: plan.mobileData2,
      throttledSpeed: plan.speedWhenExhausted,
      call: plan.mobileVoice,
      textMessage: plan.mobileMessage,
      isManualCreated: plan.isManualCreated,
    });
  }, [plan]);

  const openMoyo = () => {
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/plans/${planId}`,
      '_blank',
    );
  };

  const onReserveSave = async () => {
    const requestBody = {
      ...getValues(),
    };

    Object.keys(requestBody).forEach(function (key) {
      if (requestBody[key] === '') {
        requestBody[key] = null;
      }
    });

    const data = {
      type: 'PLAN_META',
      reserveTime: reserveTime,
      updatedValue: requestBody,
    };
    try {
      if (planId) {
        await apiService.reserveEditPlanById(planId, data);
        alert('예약되었습니다');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onReserveDelete = async () => {
    if (window.confirm('요금제 종료를 예약하시겠습니까?')) {
      await apiService.reservePlanDelete(planId, reserveDeleteDate);
      setReserveDeleteDate(reserveDeleteDate);
      setIsReserveDeleteEnabled(true);
    }
  };

  const onCancelReserveDelete = async () => {
    if (window.confirm('요금제 종료를 취소하시겠습니까?')) {
      await apiService.cancelReservePlanDelete(planId);
      setIsReserveDeleteEnabled(false);
    }
  };

  const onRestore = async () => {
    await apiService.restorePlan(planId);
    window.location.reload();
  };

  const onSubmit = async () => {
    const requestBody = {
      ...getValues(),
    };

    Object.keys(requestBody).forEach(function (key) {
      if (requestBody[key] === '') {
        requestBody[key] = null;
      }
    });

    try {
      if (planId) {
        const res = await apiService.editPlanById(planId, requestBody);
        setPlan(res.data.result);
        alert('저장되었습니다');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = async () => {
    try {
      const res = await apiService.deletePlan(planId);
      setPlan(res.data.result);
      alert('삭제되었습니다');
      window.close();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {isReserveDeleteEnabled && (
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: 'darkred',
            textAlign: 'center',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginBottom: '2rem',
            padding: '0.5rem',
          }}
        >
          이 요금제는 {reserveDeleteDate.toLocaleDateString()}에 종료될
          예정입니다.
          <Button
            size="sm"
            variant="danger"
            style={{ marginLeft: '0.5rem' }}
            onClick={onCancelReserveDelete}
          >
            취소하기
          </Button>
        </h1>
      )}
      <div
        className="mt-3 pb-5"
        style={{ marginLeft: '2rem', marginRight: '2rem' }}
      >
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3>요금제 상세</h3>
            <div style={{ display: 'inline' }}>
              {!isReserveDeleteEnabled && (
                <>
                  <DatePicker
                    className="me-3"
                    selected={reserveDeleteDate}
                    onChange={(date) => {
                      setReserveDeleteDate(date);
                    }}
                    dateFormat="yyyy/M/d"
                  />
                  <Button
                    className="me-3"
                    variant="danger"
                    onClick={onReserveDelete}
                  >
                    예약 종료
                  </Button>
                </>
              )}
              <DatePicker
                className="me-3"
                selected={reserveTime}
                onChange={(date) => {
                  setReserveTime(date);
                }}
                showTimeSelect
                dateFormat="yyyy/M/d h:mm aa"
              />
              <Button
                className="me-3"
                variant="outline-primary"
                onClick={onReserveSave}
              >
                예약 저장
              </Button>
              <Button
                className="me-3"
                variant="outline-primary"
                onClick={onSubmit}
              >
                저장
              </Button>

              <Button
                className="me-3"
                variant="outline-secondary"
                onClick={onRestore}
              >
                복원
              </Button>
              <Button
                className="me-3"
                variant="outline-danger"
                onClick={onDelete}
              >
                삭제
              </Button>
              <Button
                className="me-3"
                variant="outline-info"
                onClick={openMoyo}
              >
                모요페이지확인
              </Button>
            </div>
          </div>
          <hr />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>요금제 Id</Form.Label>
                  <Form.Control defaultValue={planId} disabled />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>mvno</Form.Label>
                  <Form.Control
                    {...register('mvno', {
                      required: false,
                    })}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>mno</Form.Label>
                  <Form.Control
                    {...register('mno', {
                      required: false,
                    })}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>요금제명</Form.Label>
                  <Form.Control
                    {...register('name', {
                      required: false,
                    })}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>가격</Form.Label>
                  <Form.Control
                    {...register('fee', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>할인적용 개월수</Form.Label>
                  <Form.Control
                    {...register('할인적용_개월수', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>할인끝난후 금액</Form.Label>
                  <Form.Control
                    {...register('할인끝난후_금액', {
                      required: false,
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>데이터(GB)</Form.Label>
                  <Form.Control
                    {...register('dataSpeed', {
                      required: false,
                    })}
                    disabled={!getValues('isManualCreated')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>일 추가 데이터(GB)</Form.Label>
                  <Form.Control
                    {...register('dataSpeed2', {
                      required: false,
                    })}
                    disabled={!getValues('isManualCreated')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>소진시 속도(1Mbps = 1000)</Form.Label>
                  <Form.Control
                    {...register('throttledSpeed', {
                      required: false,
                    })}
                    disabled={!getValues('isManualCreated')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>통화(분)</Form.Label>
                  <Form.Control
                    {...register('call', {
                      required: false,
                    })}
                    disabled={!getValues('isManualCreated')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>문자(건)</Form.Label>
                  <Form.Control
                    {...register('textMessage', {
                      required: false,
                    })}
                    disabled={!getValues('isManualCreated')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>가입하기 링크</Form.Label>
              <Form.Control
                {...register('bitlyUrl', {
                  required: false,
                })}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>파트너사 링크</Form.Label>
              <Form.Control
                {...register('번호이동_파트너링크', {
                  required: false,
                })}
                placeholder="파트너사 링크를 입력해주세요"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>파트너사 링크(신규가입)</Form.Label>
              <Form.Control
                {...register('신규가입_파트너링크', {
                  required: false,
                })}
                placeholder="신규가입이 별도로 있는 경우 입력해주세요"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>셀프개통 링크</Form.Label>
              <Form.Control
                {...register('selfSignupUrl', {
                  required: false,
                })}
                placeholder="셀프개통 링크를 입력해주세요"
              />
            </Form.Group>
          </Form>
        </div>
        <PlanRelatedConstraintDataTable
          planId={planId}
          mvno={plan?.mvno}
          mno={plan?.mno}
        />
        <div>
          <DataTable
            title="요금제 변경 기록"
            columns={planHistoryColumns}
            data={planHistoryList}
            pagination
            defaultSortAsc={false}
            defaultSortFieldId={2}
            paginationPerPage={10}
          />
        </div>
        <PlanReserveEditDataTable planId={planId} />
      </div>
    </>
  );
}
const planHistoryColumns = [
  {
    name: '변경',
    selector: (row) => row.eventType,
    sortable: true,
    maxWidth: '50px',
  },
  {
    name: '변경 날짜',
    selector: (row) => row.createdDateTime,
    sortable: true,
    minWidth: '200px',
    maxWidth: '200px',
  },

  {
    name: '가격',
    selector: (row) => row.fee,
    sortable: true,
    maxWidth: '60px',
  },
  {
    name: '할인적용_개월수',
    selector: (row) => row.할인적용_개월수,
    sortable: true,
    maxWidth: '50px',
  },
  {
    name: '할인끝난후_금액',
    selector: (row) => row.할인끝난후_금액,
    sortable: true,
    maxWidth: '60px',
  },
  {
    name: '파트너링크',
    selector: (row) => row.번호이동_파트너링크,
    sortable: false,
  },
];
