import { ReactNode } from 'react';
import { Divider, Typography } from '@mui/material';
import { Spacing } from '../../../component/Spacing';
import { moyoColors } from '../../../common/moyo-colors';

interface SectionHeaderProps {
  title: string | ReactNode;
  description?: string | ReactNode;
}

export function SectionHeader({ title, description }: SectionHeaderProps) {
  return (
    <div style={{ padding: '16px 0' }}>
      <Typography variant="h5" component="h3">
        {title}
      </Typography>
      <Spacing size={8} />
      {description != null && (
        <>
          <Typography variant="body2" component="p" color={moyoColors.gray500}>
            {description}
          </Typography>
          <Spacing size={8} />
        </>
      )}
      <Divider />
    </div>
  );
}
