import { useMutation, useQuery } from "@tanstack/react-query"
import { mailApiService } from "../../service/mailService";

export const useMailTemplate = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['mail-template-list'],
    queryFn: async () => {
      const response = await mailApiService.getMailTemplateList();
      return response.data.result;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const uploadMailTemplate = useMutation({
    mutationKey: ['post-mail-template'],
    mutationFn: async (file: File) => {
      const response = await mailApiService.postMailTemplate(file);
      return response.data.result
    }
  });

  const removeMailTemplate = useMutation({
    mutationKey: ['delete-mail-template'],
    mutationFn: async (fileName: string) => {
      const response = await mailApiService.deleteMailTemplate(fileName);
      return response.data.result
    }
  });

  return {
    mailTemplateList: data,
    loadMailTemplateList: refetch,
    loadingMailTemplateList: isLoading,
    removeMailTemplate,
    uploadMailTemplate,
    getMailTemplate: () => { },
  }
}