import {
  Avatar,
  Button,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { moyoColors } from '../../../common/moyo-colors';
import { commaizeNumber } from '../../../common/number-utils';
import { ComponentType } from 'react';
import { useNavigateWithParams } from '../hooks/useNavigateWithParams';
import { YEAR_MONTH_QUERY_KEY } from '../hooks/useSettlementPeriod';

interface SettlementCardProps {
  title: string;
  description?: string;
  iconComponent?: ComponentType<any>;
  amount: number;
  link?: string;
  unit?: '원' | '건';
}

export function SettlementCard({
  title,
  description,
  iconComponent: IconComponent,
  amount,
  unit = '원',
  link,
}: SettlementCardProps) {
  const navigate = useNavigateWithParams([YEAR_MONTH_QUERY_KEY]);

  return (
    <Card>
      <List>
        <ListItem
          secondaryAction={
            link != null ? (
              <Button variant="outlined" onClick={() => navigate(link)}>
                자세히 보기
              </Button>
            ) : undefined
          }
        >
          {IconComponent != null && (
            <ListItemAvatar>
              <Avatar sx={{ bgColor: moyoColors.gray400 }}>
                <IconComponent sx={{ height: '20px' }} />
              </Avatar>
            </ListItemAvatar>
          )}
          <ListItemText
            title={description}
            primary={title}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={`${commaizeNumber(amount)}${unit}`}
            secondaryTypographyProps={{
              variant: 'h4',
              fontWeight: 700,
              color: 'initial',
            }}
          />
        </ListItem>
      </List>
    </Card>
  );
}
