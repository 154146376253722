import { Reward, UserRewardTaxAccount } from './../entity/settlement';
import qs from 'query-string';
import axios, { AxiosResponse } from 'axios';
import { BizmReward as BizMReward, UserReward } from '../entity/settlement';
import { ApiResponse } from '../entity/api';

interface DateParams {
  startDate: string;
  endDate: string;
}

interface EventNameParams {
  eventName?: string;
}

interface EventTypeParams {
  eventType?: string;
}

interface PagingParams {
  page: number;
  size: number;
  sort?: string;
  direction?: string;
}

interface SelectParams {
  rewardUserIds?: number[];
}

interface PagingResponse<T> {
  page: number;
  totalSize: number;
  list: T[];
}


class SettlementApiService {
  private readonly http;

  constructor() {
    this.http = axios.create({
      baseURL: new URL('/api/v1', process.env.REACT_APP_SETTLEMENT_API_BASE_URL)
        .href,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getAllRewardEvents(
  ): Promise<AxiosResponse<{
    data: Reward[]
  }>> {
    return this.http.get(
      `/settlement/reward/list`,
    );
  }

  getRewardsByEvent(
    params: DateParams & EventTypeParams & EventTypeParams,
  ): Promise<AxiosResponse<any>> {
    return this.http.get(
      `/settlement/reward/event/statistics?${qs.stringify(params)}`,
    );
  }

  getRewardsByEventSheet({
    rewardUserIds,
    ...params
  }: DateParams & EventNameParams & EventTypeParams & SelectParams): Promise<
    AxiosResponse<any>
  > {
    return this.http.post(
      `/settlement/reward/event/statistics${
        rewardUserIds ? `/choice` : ''
      }/export/excel?${qs.stringify(params)}`,
      { rewardUserIds },
      { responseType: 'arraybuffer' },
    );
  }

  getRewardsByUser(
    params: DateParams & EventNameParams & PagingParams & EventTypeParams,
  ): Promise<AxiosResponse<ApiResponse<PagingResponse<UserReward>>>> {
    return this.http.get(
      `/settlement/reward/user/statistics?${qs.stringify(params)}`,
    );
  }

  getRewardsByUserSheet({
    rewardUserIds,
    ...params
  }: DateParams & EventNameParams & EventTypeParams & SelectParams): Promise<
    AxiosResponse<any>
  > {
    return this.http.post(
      `/settlement/reward/user/statistics${
        rewardUserIds ? `/choice` : ''
      }/export/excel?${qs.stringify(params)}`,
      { rewardUserIds },
      { responseType: 'arraybuffer' },
    );
  }

  getRewardsByUserTaxAccount(
    params: DateParams & PagingParams,
  ): Promise<AxiosResponse<ApiResponse<PagingResponse<UserRewardTaxAccount>>>> {
    return this.http.get(
      `/settlement/reward/user/tax-account?${qs.stringify(params)}`,
    );
  }

  senTaxNotifierAlimtalk({
    rewardUserIds,
    ...params
  }: DateParams & EventNameParams & EventTypeParams & SelectParams): Promise<
    AxiosResponse<any>
  > {
    return this.http.post(
      `/settlement/reward/tax-notifier-alarm?${qs.stringify(params)}`,
      { rewardUserIds },
    );
  }

  getRawData(
    params: DateParams & EventNameParams & PagingParams,
  ): Promise<AxiosResponse<any>> {
    return this.http.get(
      `/settlement/reward/user/raw/statistics?${qs.stringify(params)}`,
    );
  }

  getRawDataSheet({
    rewardUserIds,
    ...params
  }: DateParams & EventNameParams & EventTypeParams & SelectParams): Promise<
    AxiosResponse<any>
  > {
    return this.http.post(
      `/settlement/reward/user/raw/statistics${
        rewardUserIds ? `/choice` : ''
      }/export/excel?${qs.stringify(params)}`,
      { rewardUserIds },
      { responseType: 'arraybuffer' },
    );
  }

  getBizM(
    params: DateParams & EventNameParams & PagingParams & EventTypeParams,
  ): Promise<AxiosResponse<ApiResponse<PagingResponse<BizMReward>>>> {
    return this.http.get(
      `/settlement/reward/bizm/user?${qs.stringify(params)}`,
    );
  }

  getBizMSheet({
    rewardUserIds,
    ...params
  }: DateParams & EventNameParams & EventTypeParams & SelectParams): Promise<
    AxiosResponse<any>
  > {
    return this.http.post(
      `/settlement/reward/bizm/user${
        rewardUserIds ? `/choice` : ''
      }/export/excel?${qs.stringify(params)}`,
      { rewardUserIds },
      { responseType: 'arraybuffer' },
    );
  }

  getInternetUsers(
    params: DateParams & PagingParams,
  ): Promise<AxiosResponse<any>> {
    return this.http.get(
      `/settlement/reward/internet-user/statistics?${qs.stringify(params)}`,
    );
  }

  getInternetUsersSheet({
    rewardUserIds,
    ...params
  }: DateParams & SelectParams): Promise<AxiosResponse<any>> {
    return this.http.post(
      `/settlement/reward/internet-user/statistics${
        rewardUserIds ? `/choice` : ''
      }/export/excel?${qs.stringify(params)}`,
      { rewardUserIds },
      { responseType: 'arraybuffer' },
    );
  }
}

export const settlementApiService = new SettlementApiService();
