import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

import { RequireAuth } from './component/RequireAuth';
import { UserContext } from './context/UserContext';
import AdManager from './layout/banners/AdManager';
import BannerDetail from './layout/banners/BannerDetail';
import ScheduleDetail from './layout/banners/ScheduleDetail';
import Constraint from './layout/constraint/Constraint';
import ConstraintDetail from './layout/constraint/ContraintDetail';
import EventDetail from './layout/events/EventDetail';
import EventEdit from './layout/events/EventEdit';
import EventList from './layout/events/EventList';
import ReservedEventDetail from './layout/events/ReservedEventDetail';
import Login from './layout/Login';
import PlanCalculate from './layout/PlanCalculate';
import PlanContractList from './layout/PlanContractList';
import PlanDetailInfoList from './layout/PlanDetailInfoList';
import PlanGift from './layout/PlanGift';
import PlanList from './layout/PlanList';
import Disclaimer from './layout/plans/Disclaimer';
import DisclaimerDetail from './layout/plans/DisclaimerDetail';
import PlanDetail from './layout/plans/PlanDetail';
import PlanDetailInfo from './layout/plans/PlanDetailInfo';
import SeoKeyword from './layout/seo/SeoKeyword';
import EMail from './layout/email/EMail';
import { LoadingProvider } from './context/LoadingContext';
import CommunityPage from './layout/community/CommunityPage';
import CommunityDetailPage from './layout/community/detail/CommunityDetailPage';
import GenerateCommunityPage from './layout/generate/community/GenerateCommunityPage';
import { SettlementIndexPage } from './layout/settlement/SettlementIndexPage';
import { SETTLEMENT_MAIN_PATH } from './layout/settlement/routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import NpsListPage from './layout/nps/nps/NpsListPage';
import NpsTemplateListPage from './layout/nps/npsTemplate/NpsTemplateListPage/NpsTemplateListPage';
import NpsResultListPage from './layout/nps/npsResult/NpsResultListPage';
import NpsTemplateCreatePage from './layout/nps/npsTemplate/NpsTemplateCreatePage';
import NpsCreatePage from './layout/nps/nps/NpsCreatePage';
import NpsResultPage from './layout/nps/npsResult/NpsResultPage';
import DrawerNavigation from './component/DrawerNavigation';
import CreatePlanPage from './layout/create-plan';
import { Box } from '@mui/material';
import { MoyoOnlyListPage } from './layout/moyoonly';
import MoyoOnlyPlanPage from './layout/moyoonly-plan';

const queryClient = new QueryClient({
  defaultOptions: {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  },
});

const STORAGE_ADMIN_USER = 'admin_user';

const initialUser = localStorage.getItem(STORAGE_ADMIN_USER)
  ? JSON.parse(localStorage.getItem(STORAGE_ADMIN_USER))
  : null;

function App() {
  const [user, setUser] = useState(initialUser);

  const loginUser = (userData) => {
    if (!userData) return;

    localStorage.setItem(STORAGE_ADMIN_USER, JSON.stringify(userData));
    setUser(userData);
  };

  const logoutUser = () => {
    localStorage.removeItem(STORAGE_ADMIN_USER);
    setUser(null);
  };

  useEffect(() => {
    const envType = process.env.REACT_APP_API_BASE_URL;
    const answer = window.confirm('v1을 사용해야 하는 상황인가요?');
    const redirectUrl = envType.includes('alpha')
      ? 'https://admin.alpha.moyoplan.team'
      : 'https://admin.moyoplan.team';
    if (answer) {
      alert('v1을 써야 하는 상황을 요금제관리tf에 이슈레이징 해주세요');
    } else {
      alert('v2로 이동합니다');
      window.location.href = redirectUrl;
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider
          value={{
            user,
            loginUser,
            logoutUser,
          }}
        >
          <LoadingProvider>
            <BrowserRouter>
              <Box sx={{ display: 'flex' }}>
                {user ? <DrawerNavigation /> : <></>}
                <div style={{ width: '100%', minHeight: '100vh' }}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <PlanList />
                        </RequireAuth>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/gifts"
                      element={
                        <RequireAuth>
                          <PlanGift />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/events/add"
                      element={
                        <RequireAuth>
                          <EventEdit />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/events/reserve/:reservedEventId"
                      element={
                        <RequireAuth>
                          <ReservedEventDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/events/:eventId"
                      element={
                        <RequireAuth>
                          <EventDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/events/:eventId/edit"
                      element={
                        <RequireAuth>
                          <EventEdit />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/events"
                      element={
                        <RequireAuth>
                          <EventList />
                        </RequireAuth>
                      }
                    />
                    x
                    <Route
                      path="/plan-contract"
                      element={
                        <RequireAuth>
                          <PlanContractList />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/calculate"
                      element={
                        <RequireAuth>
                          <PlanCalculate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/plan-detail"
                      element={
                        <RequireAuth>
                          <PlanDetailInfoList />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/disclaimer"
                      element={
                        <RequireAuth>
                          <Disclaimer />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/disclaimer/:disclaimer"
                      element={
                        <RequireAuth>
                          <DisclaimerDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/plan-detail/:planId"
                      element={
                        <RequireAuth>
                          <PlanDetailInfo />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/plans/:planId"
                      element={
                        <RequireAuth>
                          <PlanDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/plans"
                      element={
                        <RequireAuth>
                          <PlanList />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ads/schedules/add"
                      element={
                        <RequireAuth>
                          <ScheduleDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ads/schedules/:scheduleId"
                      element={
                        <RequireAuth>
                          <ScheduleDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ads/banners/add"
                      element={
                        <RequireAuth>
                          <BannerDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ads/banners/:bannerId"
                      element={
                        <RequireAuth>
                          <BannerDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ads"
                      element={
                        <RequireAuth>
                          <AdManager />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/constraint"
                      element={
                        <RequireAuth>
                          <Constraint />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/constraint/add"
                      element={
                        <RequireAuth>
                          <ConstraintDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/constraint/:constraintId"
                      element={
                        <RequireAuth>
                          <ConstraintDetail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/seo"
                      element={
                        <RequireAuth>
                          <SeoKeyword />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/email"
                      element={
                        <RequireAuth>
                          <EMail />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/community"
                      element={
                        <RequireAuth>
                          <CommunityPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/community/:uuid"
                      element={
                        <RequireAuth>
                          <CommunityDetailPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/generate/community"
                      element={
                        <RequireAuth>
                          <GenerateCommunityPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/generate/community/:uuid"
                      element={
                        <RequireAuth>
                          <CommunityDetailPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/${SETTLEMENT_MAIN_PATH}/*`}
                      element={
                        <RequireAuth>
                          <SettlementIndexPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps`}
                      element={
                        <RequireAuth>
                          <NpsListPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/:id`}
                      element={
                        <RequireAuth>
                          <NpsCreatePage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/create`}
                      element={
                        <RequireAuth>
                          <NpsCreatePage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/template`}
                      element={
                        <RequireAuth>
                          <NpsTemplateListPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/template/:id`}
                      element={
                        <RequireAuth>
                          <NpsTemplateCreatePage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/template/create`}
                      element={
                        <RequireAuth>
                          <NpsTemplateCreatePage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/result`}
                      element={
                        <RequireAuth>
                          <NpsResultListPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/nps/result/:id`}
                      element={
                        <RequireAuth>
                          <NpsResultPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/moyo-only/management`}
                      element={
                        <RequireAuth>
                          <MoyoOnlyListPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={`/moyo-only/plan/:id`}
                      element={
                        <RequireAuth>
                          <MoyoOnlyPlanPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/plan/create"
                      element={
                        <RequireAuth>
                          <CreatePlanPage />
                        </RequireAuth>
                      }
                    />
                  </Routes>
                </div>
              </Box>
            </BrowserRouter>
          </LoadingProvider>
        </UserContext.Provider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
