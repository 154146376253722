import React from 'react';
import { Button } from '@mui/material';
import { MoyoOnlyListTypes, ToMoyoOnlyPlanTypes } from '../type';

interface PopupActionsProps {
  plan: MoyoOnlyListTypes;
  onClose: () => void;
  onCreateClick: (id: number) => void;
  onChangeMoyoOnlyClick: (
    requestId: number,
    planId: number,
    data: ToMoyoOnlyPlanTypes,
  ) => void;
  onChangeMoyoOnlyData: ToMoyoOnlyPlanTypes;
}

export const PopupActions: React.FC<PopupActionsProps> = ({
  plan,
  onClose,
  onCreateClick,
  onChangeMoyoOnlyClick,
  onChangeMoyoOnlyData,
}) => {
  const styles = {
    cancelButton: {
      padding: '0.5rem 2.5rem',
      background: '#f2f2f2',
      borderRadius: '0.9rem',
      color: '#3182F5',
    },
    createButton: {
      padding: '0.5rem 1.9rem',
      background: '#3182F5',
      borderRadius: '0.9rem',
      color: '#fff',
    },
  };

  return (
    <>
      <Button onClick={onClose} style={styles.cancelButton}>
        취소
      </Button>
      {plan.planMetaJson?.planId ? (
        <Button
          onClick={() =>
            onChangeMoyoOnlyClick(
              plan.id,
              plan.planMetaJson?.planId,
              onChangeMoyoOnlyData,
            )
          }
          style={styles.createButton}
        >
          변경하기
        </Button>
      ) : (
        <Button
          onClick={() => onCreateClick(plan.id)}
          style={styles.createButton}
        >
          생성하기
        </Button>
      )}
    </>
  );
};
