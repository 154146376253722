import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import { FormControl, Grid, TextField, Typography } from '@mui/material';

type PlanCodeInputProps = {
  code: string | null;
};

export const PlanCodeInput: FC<PlanCodeInputProps> = ({ code }) => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" my={2} mt={'30px'}>
        파트너스 링크
      </Typography>
      <FormControl fullWidth>
        <Controller
          control={control}
          defaultValue={code}
          name="code"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={'요금제 코드'}
              variant="outlined"
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};
