export type MvnoUser = {
  value: string;
};

export const Mvnos: MvnoUser[] = [
  { value: 'KCTV' },
  { value: 'KG모바일' },
  { value: 'ktmmobile' },
  { value: 'KTskylife' },
  { value: 'sk7mobile' },
  { value: 'U+알뜰모바일' },
  { value: '고고모바일' },
  { value: '드림모바일' },
  { value: 'liivm' },
  { value: '마이월드' },
  { value: 'valuecomm' },
  { value: 'marvelring' },
  { value: 'mobing' },
  { value: '사람과연결' },
  { value: '서경모바일' },
  { value: 'sugarmobile' },
  { value: 'snowman' },
  { value: 'smartel' },
  { value: '아이즈모바일' },
  { value: '에스원안심모바일' },
  { value: 'amobile' },
  { value: 'yeoyou' },
  { value: '위너스텔' },
  { value: '이야기모바일' },
  { value: '이지모바일' },
  { value: 'insmobile' },
  { value: '에르엘' },
  { value: '미니게이트' },
  { value: 'tplus' },
  { value: '프리티' },
  { value: '핀다이렉트' },
  { value: '헬로모바일' },
  { value: 'pointplus' },
  { value: 'SKT' },
  { value: 'lgu' },
  { value: 'kt' },
];
