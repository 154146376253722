import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Paper } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadFile } from '../../../common/file-utils';
import { PageTitle } from '../../../component/PageTitle';
import { settlementApiService } from '../../../service/settlementSevice';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';
import { Section } from './Section';


const columns: GridColDef[] = [
  {
    field: 'userPhoneNumber',
    headerName: '지급할 번호',
    width: 124,
    sortable: false,
    valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
      value?.replace(/\+82 |-/g, ''),
  },
  {
    field: 'userName',
    headerName: '유저명',
    width: 124,
    sortable: false,
  },
  {
    field: 'userId',
    headerName: '유저 ID',
    width: 124,
    sortable: false,
  },
  {
    field: 'internetOrderId',
    headerName: '인터넷 주문 ID',
    width: 124,
    sortable: false,
  },
];

export function InternetUsersSection() {
  const { endDate, startDate } = useSettlementPeriod();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'totalMoyoRewardValue', sort: 'desc' },
  ]);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data, isLoading } = useQuery(
    [
      'settlement-internet-rewards',
      endDate,
      startDate,
      page,
      pageSize,
      sortModel[0],
    ],
    () =>
      settlementApiService.getInternetUsers({
        endDate,
        startDate,
        page,
        size: pageSize,
      }),
    { suspense: true },
  );

  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const rowCount = data?.data.result.totalSize;
  const rows = data?.data.result.list ?? [];

  return (
    <Section>
      <PageTitle>인터넷 초대 유저</PageTitle>
      <Paper sx={{ height: 700, width: '100%', padding: '16px' }}>
        <Box sx={{ display: 'flex', gap: '16px', padding: '16px' }}>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              setIsDownloading(true);
              // @ts-ignore
              const response = await settlementApiService.getInternetUsersSheet(
                {
                  endDate,
                  startDate,
                  rewardUserIds: selectedIds,
                },
              );
              downloadFile(response);
              setIsDownloading(false);
            }}
            disabled={selectedIds.length === 0 || isLoading || isDownloading}
          >
            선택한 이벤트 엑셀 다운로드
          </Button>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            disabled={isLoading || isDownloading}
            onClick={async () => {
              setIsDownloading(true);
              const response = await settlementApiService.getInternetUsersSheet(
                { endDate, startDate },
              );
              downloadFile(response);
              setIsDownloading(false);
            }}
          >
            전체 엑셀 다운로드
          </Button>
        </Box>
        <DataGrid
          rowCount={rowCount}
          columns={columns}
          rows={rows}
          checkboxSelection
          sx={{ border: 'none' }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isDownloading}
          pagination
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        />
      </Paper>
    </Section>
  );
}
