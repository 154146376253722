import { PlanMeta } from './plan';

export type MobilePlanOrder = {
  id: number;
  status: string;
  mvno: string;
  planName: string;
  customerName: string;
  customerPhoneNumber: string;
  birthday?: string;
  orderPhoneNumber: string;
  registerType?: string;
  userId?: number;
  planHistoryId: string;
  memo?: string;
  createdAt: number;
  updatedAt: number;
  historyList: Array<MobilePlanOrderHistory>;
  planMeta: PlanMeta;
  moyoLink: string;
  detail?: MobilePlanOrderDetail;
  completedAt: number;
  requestedAt: number;
};

export type OrderStatusOptions = {
  statusList: Array<OrderStatus>;
};

export type OrderStatus = {
  key: string;
  name: string;
  details: Array<string>;
};

export type MobilePlanOrderHistory = {
  status: string;
  statusDetail: string;
  memo: string;
  adminUserName?: string;
  createdAt: number;
};

export type MobilePlanOrderListDto = {
  totalCount: number;
  list: Array<MobilePlanOrder>;
};

export type MobilePlanOrderDetail = {
  id: number;
  registrationNumberFirst?: string;
  registrationNumberSecond?: string;

  licenseType?: string;
  licenseIssuedAt?: string;
  driverLicense1?: string;
  driverLicense2?: string;
  driverLicense3?: string;
  driverLicense4?: string;

  phoneMno?: string;
  phoneMvno?: string;

  certificationType?: string;
  ci?: string;
  di?: string;

  hasUsim?: boolean;
  usimModel?: string;
  usimSerialNumber?: string;

  recipientName?: string;
  recipientPhoneNumber?: string;
  postalCode?: string;
  addressLevel1?: string;
  addressLevel2?: string;
  email?: string;

  paymentNotiChannel?: string;

  paymentMethod?: string;
  bank?: string;
  accountNumber?: string;
  cardNumber?: string;
  cardExpiresMonth?: string;
  cardExpiresYear?: string;

  changeCertBillingMethod?: string;
  changeCertBillingInfo?: string;

  wishNumber1?: string;
  wishNumber2?: string;

  deliveryCompany?: string;
  deliveryTrackingNumber?: string;
};

export const OrderStatusOptionList = [
  {
    "key": "PRE_APPLICATION_REGISTERED",
    "name": "신청서 작성중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_REGISTERED",
    "name": "서류접수",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_CHECKED",
    "name": "서류확인완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_REJECTED",
    "name": "개통불가",
    "details": [
      "번호이동 인증수단 오류",
      "이전 통신사 오류",
      "기타 정보 오류",
      "번호이동 3개월 제한",
      "회선 수 초과",
      "미납/연체",
      "가입 제한",
      "신용불량",
      "기타"
    ],
    "orderType": "register"
  },
  {
    "key": "HAPPY_CALL_COMPLETE",
    "name": "해피콜 완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "HAPPY_CALL_MISSED",
    "name": "해피콜 부재중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_CANCELED",
    "name": "개통 전 취소",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "NEW_NUMBER_ACTIVATED",
    "name": "[신규가입] 선개통",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "DELIVERY_REQUESTED",
    "name": "유심 배송요청",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "DELIVERY_STARTED",
    "name": "유심 배송중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_WAITING",
    "name": "개통진행 중 오류",
    "details": [
      "번호이동 인증수단 오류",
      "이전 통신사 오류",
      "기타 정보 오류",
      "번호이동 3개월 제한",
      "회선 수 초과",
      "미납/연체",
      "가입 제한",
      "신용불량",
      "기타"
    ],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_REQUESTED",
    "name": "개통요청",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_COMPLETE",
    "name": "개통완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_CANCELED",
    "name": "개통 후 취소",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_INFO_CHECKED",
    "name": "서류확인완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_SELF_COMPLETE",
    "name": "셀프개통완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "CREDIT_CHECK_WAITING",
    "name": "신용조회 대기중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "HAPPY_CALL_WAITING",
    "name": "해피콜대기",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "CALL_OPEN_REQUESTED",
    "name": "[전화 상담] 미처리",
    "details": [],
    "orderType": "call"
  },
  {
    "key": "CALL_IN_PROGRESS",
    "name": "[전화 상담] 처리중",
    "details": [],
    "orderType": "call"
  },
  {
    "key": "CALL_RE_REQUEST",
    "name": "[전화 상담] 재연락 요청",
    "details": [],
    "orderType": "call"
  },
  {
    "key": "CALL_FAILURE",
    "name": "[전화 상담] 실패",
    "details": [
      "기타",
      "위약금",
      "사은품",
      "무선결합",
      "CS상담",
      "무응답",
      "통화거부",
      "단순 문의",
      "중복"
    ],
    "orderType": "call"
  },
  {
    "key": "CALL_OPEN_RECEPTION_COMPLETED",
    "name": "접수 완료",
    "details": [],
    "orderType": "call"
  },
  {
    "key": "SELF_REQUESTED",
    "name": "셀프개통 시작",
    "details": [],
    "orderType": "outlink_self"
  },
  {
    "key": "SELF_IN_PROGRESS",
    "name": "셀프개통 진행중",
    "details": [],
    "orderType": "outlink_self"
  },
  {
    "key": "SELF_FAILED",
    "name": "셀프개통 실패",
    "details": [],
    "orderType": "outlink_self"
  },
  {
    "key": "CREDIT_CHECK_IN_PROGRESS",
    "name": "신용조회중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "CERTIFICATION_WAITING",
    "name": "인증대기",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "CERTIFICATION_FAILED",
    "name": "(서류확인중) 기타",
    "details": [
      "번호이동 인증수단 오류",
      "이전 통신사 오류",
      "기타 정보 오류",
      "번호이동 3개월 제한",
      "회선 수 초과",
      "미납/연체",
      "가입 제한",
      "신용불량",
      "기타"
    ],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_HOLD",
    "name": "보류",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "SELF_COMPLETE",
    "name": "셀프개통 완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "INFORMED_CONSENT_STARTED",
    "name": "사전동의 시작",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "INFORMED_CONSENT_IN_PROGRESS",
    "name": "사전동의 ARS 진행중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "INFORMED_CONSENT_COMPLETE",
    "name": "사전동의 완료",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "APPLICATION_CHECK_IN_PROGRESS",
    "name": "서류확인중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_REQUEST_DELAYED",
    "name": "개통요청 지연",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "ACTIVATION_IN_PROGRESS",
    "name": "개통 진행중",
    "details": [],
    "orderType": "register"
  },
  {
    "key": "CALL_ACTIVATION_COMPLETED",
    "name": "[전화 상담] 개통 완료",
    "details": [],
    "orderType": "call"
  },
  {
    "key": "APPLICATION_CANCELED",
    "name": "개통 전 취소",
    "details": [],
    "orderType": "self"
  }
];