import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Mvnos } from '../../../entity/mvno';

type MvnoSelectProps = {
  carrier: string;
};

export const MvnoSelect: FC<MvnoSelectProps> = ({ carrier }) => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <div>
      <Typography variant="h6" my={2} mt={'30px'}>
        MVNO
      </Typography>
      <FormControl sx={{ mt: 1, mb: 1, minWidth: 150 }}>
        <InputLabel>mvno 목록</InputLabel>
        <Controller
          control={control}
          defaultValue={carrier}
          name="carrier"
          render={({ field }) => (
            <Select required {...field}>
              {Mvnos.map((group, index) => (
                <MenuItem key={index} value={group.value}>
                  {group.value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};
