import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ApiResponse } from '../../../entity/api';
import { AxiosError, AxiosResponse } from 'axios';
import { createMoyoOnlyPlan } from '../../../apis/service/moyo-only';

export interface MoyoOnlyParameterType {
  requestId: number;
}

export const useCreateMoyoOnlyPlan = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<AxiosResponse<void>>,
    MoyoOnlyParameterType
  >({
    mutationKey: undefined,
    mutationFn: (parameter) =>
      createMoyoOnlyPlan({ requestId: parameter.requestId }),
  });
};
