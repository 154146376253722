import * as excelJs from 'exceljs';
import { ChangeEvent } from 'react';

class ExcelReader {
  readArrayBuffer = async (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolved, rejected) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (!(reader.result instanceof ArrayBuffer)) throw new Error();
        resolved(reader.result as ArrayBuffer);
      }
      reader.onerror = rejected;

      reader.readAsArrayBuffer(file);
    });
  }

  getWorkbook = async (file?: File) => {
    if (!file) throw new Error('not found file');

    const fileArrayBuffer = await this.readArrayBuffer(file);
    const workbook = new excelJs.Workbook();
    await workbook.xlsx.load(fileArrayBuffer);

    return workbook;
  }

  onChangeFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e;
    try {
      if (!e.currentTarget.files) throw new Error('not found files');
      const file = e.currentTarget.files[0];
      if (file.name.split('.').pop()?.toLocaleLowerCase() !== 'xlsx') {
        throw new Error('not allow extension');
      };
      return await this.getWorkbook(file);
    } catch (err: any) {
      currentTarget.value = '';
      throw new Error('not allow extension');
    }
  }
}

export default ExcelReader;
