import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CreateMoyoPlanTypes } from '../../../layout/create-plan/type';
import { createMoyoPlan } from '../../../apis/service/moyo-plan';

export const useCreateMoyoOnlyPlan = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<AxiosResponse<void>>,
    CreateMoyoPlanTypes
  >({
    mutationKey: undefined,
    mutationFn: (parameter) => createMoyoPlan(parameter),
  });
};
