import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';

import apiService from '../../service/api.service';

const PlanReserveEditDataTable = () => {
  const planReserveEditColumns = [
    {
      name: '변경 예약 날짜',
      selector: (row) => row.reserveTime,
      sortable: true,
      minWidth: '200px',
      maxWidth: '200px',
    },
    {
      name: '적용 완료 여부',
      selector: (row) => (row.isApplied ? '적용완료' : '미적용'),
      sortable: true,
      maxWidth: '90px',
    },
    {
      name: '할인 전 가격',
      selector: (row) => row.updatedValue.fee ?? '변경없음',
    },
    {
      name: '할인 개월 수',
      selector: (row) => row.updatedValue.할인적용_개월수 ?? '변경없음',
    },
    {
      name: '할인 후 가격',
      selector: (row) => row.updatedValue.할인끝난후_금액 ?? '변경없음',
    },
    {
      name: '번호이동 링크',
      selector: (row) => row.updatedValue.번호이동_파트너링크 ?? '변경없음',
    },
    {
      name: '신규가입 링크',
      selector: (row) => row.updatedValue.신규가입_파트너링크 ?? '변경없음',
    },
    {
      name: '삭제',
      cell: (row) => (
        <Button
          disabled={row.isApplied}
          variant="danger"
          size="sm"
          onClick={() => removeReserveEdit(row.id)}
        >
          삭제
        </Button>
      ),
      center: true,
    },
  ];

  const { planId } = useParams();
  const [planReserveEditList, setPlanReserveEditList] = useState([]);

  useEffect(() => {
    fetchPlanReserveEditHistory();
  }, []);

  const fetchPlanReserveEditHistory = async () => {
    if (!planId) return;

    try {
      const res = await apiService.getPlanReserveEditHistoryById(planId);
      if (res && res.data && res.data.result) {
        setPlanReserveEditList(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeReserveEdit = async (reservedEditId) => {
    if (window.confirm('삭제하시겠습니까?')) {
      await apiService.deletePlanReserveEdit(reservedEditId);
      await fetchPlanReserveEditHistory();
      window.alert('삭제되었습니다.');
    }
  };

  return (
    <DataTable
      title="요금제 변경 예약"
      columns={planReserveEditColumns}
      data={planReserveEditList}
      defaultSortAsc={false}
      defaultSortFieldId={1}
      pagination
      paginationPerPage={5}
    />
  );
};

export default PlanReserveEditDataTable;
