import { Button, Chip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function getStatus(state: string): {
  text: string;
  color: 'default' | 'success';
} {
  switch (state) {
    case 'STABLE':
      return {
        text: '처리완료',
        color: 'success',
      };
    case 'STANDBY':
      return {
        text: '생성 대기',
        color: 'default',
      };
    case 'UPDATE':
      return {
        text: '수정 대기',
        color: 'default',
      };
    default:
      return {
        text: '생성 대기',
        color: 'default',
      };
  }
}
