import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiResponse } from '../entity/api';
import { NavigateFunction } from 'react-router-dom';

export default axios.create({
  baseURL: new URL('/api/v1/moyo/', process.env.REACT_APP_API_BASE_URL).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const moyoOnlyApi = axios.create({
  baseURL: new URL(
    'api-admin/mobile-plan-meta/moyo-only',
    process.env.REACT_APP_API_BASE_URL,
  ).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const internalPlanApi = axios.create({
  baseURL: new URL(
    '/api/v1/moyo/internal/plan',
    process.env.REACT_APP_API_BASE_URL,
  ).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});

export const internalAdminApi = axios.create({
  baseURL: new URL('/api-admin/', process.env.REACT_APP_API_BASE_URL).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const adminApi = axios.create({
  baseURL: new URL('/api-admin/', process.env.REACT_APP_API_BASE_URL).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const authApi = axios.create({
  baseURL: new URL('/', process.env.REACT_APP_ADMIN_API_BASE_URL).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const downloadApi = axios.create({
  baseURL: new URL('/api/v1/moyo/', process.env.REACT_APP_API_BASE_URL).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  responseType: 'arraybuffer',
});

export const alarmApi = axios.create({
  baseURL: new URL('/api/v1/alarm', process.env.REACT_APP_ALARM_API_URL).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export async function handleApi<T>(
  apiCall: () => Promise<AxiosResponse<ApiResponse<T>>>,
  navigate: NavigateFunction,
  onError?: (error: string) => void,
): Promise<T | undefined> {
  return apiCall()
    .then((res: AxiosResponse) => {
      const response = res.data;

      switch (response.resultType) {
        case 'SUCCESS':
          return response.result;
        case 'UNAUTHORIZED':
          onAuthFailed(navigate);
          break;
        default:
          throw new Error(response.error);
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        onAuthFailed(navigate);
        return;
      }

      if (onError) {
        onError(error.message);
      }
    });
}

export const orderApi = axios.create({
  baseURL: new URL('/api/v1/admin/order', process.env.REACT_APP_ORDER_API_URL)
    .href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});

export const orderInternalApi = axios.create({
  baseURL: new URL(
    '/api/v1/internal-admin/order',
    process.env.REACT_APP_ORDER_API_URL,
  ).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
const onAuthFailed = (navigate: NavigateFunction) => {
  if (window && window.location) {
    navigate(window.location.pathname, {
      replace: true,
      state: {
        authErrorMessage: '인증에 실패했습니다. 로그인해주세요.',
      },
    });
  }
};
