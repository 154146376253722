import { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import apiService from '../../service/api.service';

export default function BannerDetail() {
  const { bannerId } = useParams();
  const [banner, setBanner] = useState(null);
  const [pcImgPreview, setPcImgPreview] = useState('');
  const [mobileImgPreview, setMobileImgPreview] = useState('');

  const navigate = useNavigate();

  const { register, getValues, reset, watch, handleSubmit } = useForm({});
  const watchPcImgFiles = watch('pcImgFiles');
  const watchMobileImgFiles = watch('mobileImgFiles');
  const getValueImg = () => {
    console.log(getValues());
  };
  const fetchBanner = async () => {
    if (!bannerId) return;

    try {
      const res = await apiService.getAdBanner(bannerId);

      if (res && res.data && res.data.result) {
        setBanner(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  useEffect(() => {
    if (!banner) return;

    reset({
      title: banner.title,
      description: banner.description,
      campaign: banner.campaign,
      pcImgUrl: banner.pcImgUrl,
      mobileImgUrl: banner.mobileImgUrl,
      action: banner.action,
    });
  }, [banner]);
  useEffect(() => {
    if (watchPcImgFiles && watchPcImgFiles[0]) {
      setPcImgPreview(URL.createObjectURL(watchPcImgFiles[0]));
    } else if (banner) {
      setPcImgPreview(banner.pcImgUrl);
    }
  }, [banner, watchPcImgFiles]);

  useEffect(() => {
    if (watchMobileImgFiles && watchMobileImgFiles[0]) {
      setMobileImgPreview(URL.createObjectURL(watchMobileImgFiles[0]));
    } else if (banner) {
      setMobileImgPreview(banner.mobileImgUrl);
    }
  }, [banner, watchMobileImgFiles]);

  const onSubmit = async () => {
    const { pcImgFiles, mobileImgFiles, ...values } = {
      ...getValues(),
    };

    const formData = new FormData();

    for (const [key, value] of Object.entries(values)) {
      console.log(`${key}: ${value}`);
      if (value === null) continue;
      formData.append(key, value);
    }

    if (pcImgFiles && pcImgFiles[0]) {
      formData.append('pcImgFile', pcImgFiles[0]);
    }
    if (mobileImgFiles && mobileImgFiles[0]) {
      formData.append('mobileImgFile', mobileImgFiles[0]);
    }

    try {
      if (bannerId) {
        const res = await apiService.editAdBanner(bannerId, formData);
        setBanner(res.data.result);
        alert('저장되었습니다');
      } else {
        const res = await apiService.createAdBanner(formData);
        const newBanner = res.data.result;
      }

      navigate(`/ads?tab=banner`);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBanner = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      try {
        await apiService.deleteAdBanner(bannerId);
        alert('삭제되었습니다');
        navigate('/ads');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <Container className="w-75">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>배너 상세</h3>
              <div style={{ display: 'inline' }}>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  onClick={onSubmit}
                >
                  저장
                </Button>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  onClick={getValueImg}
                >
                  asd
                </Button>
                {bannerId && (
                  <Button variant="outline-danger" onClick={deleteBanner}>
                    삭제
                  </Button>
                )}
              </div>
            </div>
            <hr />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>배너 Id</Form.Label>
                <Form.Control defaultValue={bannerId} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>제목</Form.Label>
                <Form.Control
                  {...register('title', {
                    required: false,
                  })}
                  placeholder="배너 제목을 입력해주세요(이벤트 제목으로 노출)"
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>설명</Form.Label>
                <Form.Control
                  {...register('description', {
                    required: false,
                  })}
                  placeholder="배너 설명을 입력해주세요(이벤트 설명으로 노출)"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>캠페인</Form.Label>
                <Form.Control
                  {...register('campaign', {
                    required: false,
                  })}
                  placeholder="배너 캠페인을 입력해주세요(이벤트 로깅 용)"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  {...register('action', {
                    required: false,
                  })}
                  placeholder="배너 클릭 시 이동할 페이지"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>PC용 이미지</Form.Label>
                <Form.Control
                  {...register('pcImgFiles')}
                  type="file"
                  size="sm"
                />
                {pcImgPreview && (
                  <img className="mt-2 w-100" src={pcImgPreview} alt="" />
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>모바일용 이미지</Form.Label>
                <Form.Control
                  {...register('mobileImgFiles')}
                  type="file"
                  size="sm"
                />
                {mobileImgPreview && (
                  <img className="mt-2 w-100" src={mobileImgPreview} alt="" />
                )}
              </Form.Group>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
}
