import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Paper } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadFile } from '../../../common/file-utils';
import { PageTitle } from '../../../component/PageTitle';
import { settlementApiService } from '../../../service/settlementSevice';
import { formatNumber, formatPhoneNumber } from '../data-grid-utils';
import { useEventParameter } from '../hooks/useEventParameter';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';
import { SettlementSubRoutes } from '../routes';
import { Section } from './Section';

export const bizMColumns: GridColDef[] = [
  {
    field: 'eventName',
    headerName: '이벤트명',
    width: 200,
    sortable: false,
  },
  {
    field: 'userName',
    headerName: '유저명',
    width: 124,
    sortable: false,
  },
  {
    field: 'userPhoneNumber',
    headerName: '전화번호',
    width: 124,
    sortable: false,
    valueFormatter: formatPhoneNumber,
  },
  {
    field: 'moyoRewardValue',
    headerName: '지급 금액',
    type: 'number',
    width: 124,
    sortable: true,
    valueFormatter: formatNumber,
  },
];

export function BizmSection({
  subCategory,
}: {
  subCategory: SettlementSubRoutes;
}) {
  const { endDate, startDate } = useSettlementPeriod();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'moyoRewardValue', sort: 'desc' },
  ]);
  const eventParameter = useEventParameter(subCategory);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data, isLoading } = useQuery(
    [
      'settlement-bizm-rewards',
      endDate,
      startDate,
      eventParameter,
      page,
      pageSize,
      sortModel[0],
    ],
    () =>
      settlementApiService.getBizM({
        ...eventParameter,
        endDate,
        startDate,
        page,
        size: pageSize,
        sort:
          sortModel[0] != null
            ? `${sortModel[0].field},${sortModel[0].sort}`
            : undefined,
      }),
    { suspense: true },
  );

  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  const rowCount = data?.data.result?.totalSize ?? 0;
  const rows = data?.data.result?.list ?? [];

  return (
    <Section>
      <PageTitle>비즈엠 정산 엑셀 다운로드</PageTitle>
      <Paper sx={{ height: 700, width: '100%', padding: '16px' }}>
        <Box sx={{ display: 'flex', gap: '16px', padding: '16px' }}>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              setIsDownloading(true);
              // @ts-ignore
              const response = await settlementApiService.getBizMSheet({
                ...eventParameter,
                endDate,
                startDate,
                rewardUserIds: selectedIds,
              });
              downloadFile(response);
              setIsDownloading(false);
            }}
            disabled={selectedIds.length === 0 || isLoading || isDownloading}
          >
            선택한 이벤트 엑셀 다운로드
          </Button>
          <Button
            size="medium"
            variant="contained"
            startIcon={<FileDownloadIcon />}
            disabled={isLoading || isDownloading}
            onClick={async () => {
              setIsDownloading(true);
              const response = await settlementApiService.getBizMSheet({
                ...eventParameter,
                endDate,
                startDate,
              });
              downloadFile(response);
              setIsDownloading(false);
            }}
          >
            전체 이벤트 엑셀 다운로드
          </Button>
        </Box>
        <DataGrid
          rowCount={rowCount}
          columns={bizMColumns}
          rows={rows}
          checkboxSelection
          sx={{ border: 'none' }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isDownloading}
          pagination
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        />
      </Paper>
    </Section>
  );
}
