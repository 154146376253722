import { Chip, Link } from '@mui/material';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router';

import { MvnosWithAll } from '../../common/constants';
import { useDebounce } from '../../common/hooks';
import isPastDate from '../../common/time-util';
import { FilterComponent } from '../../component/FilterComponent';
import FilterSelectComponent from '../../component/FilterSelectComponent';
import apiService from '../../service/api.service';
import EventStatus, { EventStatusValue } from '../../type/event/EventStatus';

export default function EventList() {
  const eventColumns = [
    {
      name: 'id',
      selector: 'id',
      sortable: true,
      sortField: 'event.id',
      maxWidth: '50px',
    },
    {
      name: '상태',
      cell: (row) => {
        let color;
        switch (row.status) {
          case EventStatus.STANDBY:
            color = 'warning';
            break;
          case EventStatus.PROCEED:
            color = 'primary';
            break;
        }
        console.log(row.status, color);

        return <Chip color={color} label={EventStatusValue[row.status]} />;
      },

      maxWidth: '120px',
      center: true,
    },
    {
      name: '제목',
      selector: 'title',
      sortable: true,
      sortField: 'event.title',
      minWidth: '330px',
      cell: (row) => {
        return <Link href={`/events/${row.id}`}>{row.title}</Link>;
      },
    },
    {
      name: '통신사',
      selector: 'mvno',
      sortable: true,
      sortField: 'mvno',
      maxWidth: '130px',
    },
    {
      name: '노출 여부',
      selector: (row) => row.isExposed,
      cell: (row, index, column, id) => {
        return (
          <Form>
            <Form.Check
              type="switch"
              disabled={isPastDate(row.endDate)}
              defaultChecked={row.isExposed}
              onChange={(e) => {
                if (
                  window.confirm(
                    e.target.checked
                      ? '이벤트를 노출 시키겠습니까?'
                      : '이벤트를 미노출 시키겠습니까?',
                  )
                ) {
                  onActiveCheckChanged(index, row, e.target.checked);
                } else {
                  e.target.checked = !e.target.checked;
                }
              }}
            />
          </Form>
        );
      },
      maxWidth: '100px',
      center: true,
    },
    {
      name: '시작일',
      selector: (row) => {
        const date = row.startDate;
        return date && date.length === 3
          ? `${date[0]}-${date[1]}-${date[2]}`
          : date;
      },
      sortable: true,
      sortField: 'event.start_date',
      maxWidth: '160px',
      center: true,
    },
    {
      name: '종료일',
      selector: (row) => {
        const date = row.endDate;
        return date && date.length === 3
          ? `${date[0]}-${date[1]}-${date[2]}`
          : date;
      },
      sortable: true,
      sortField: 'event.end_date',
      maxWidth: '160px',
      center: true,
    },
    {
      name: '사은품 갯수',
      selector: 'detailCount',
      sortable: true,
      sortField: 'detail.count',
      maxWidth: '150px',
      center: true,
    },
    {
      name: '이미지',
      selector: 'thumbnailImageUrl',
      cell: (row, index, column, id) => (
        <img
          alt="이벤트 이미지"
          src={row.thumbnailImageUrl}
          style={{ maxWidth: '200px', maxHeight: '60px' }}
        />
      ),
    },
  ];

  const DEFAULT_PER_PAGE = 50;

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const [scrapingBtnDisable, setScrapingBtnDisable] = useState(true);
  const [scrapingBtnText, setScrapingBtnText] = useState('스크래핑 요청');
  const page = query.get('page') ?? 0;
  const perPage = query.get('perPage') ?? DEFAULT_PER_PAGE;
  const sortField = query.get('sortField') ?? '';
  const sortDirection = query.get('sortDirection') ?? '';
  const mvnoValue = query.get('mvno') ?? '';
  const eventStatus = query.get('eventStatus') ?? EventStatus.PROCEED;
  const q = query.get('q') ?? '';

  const [events, setEvents] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [activeEventStatus, setActiveEventStatus] = useState(eventStatus);
  const [mvno, setMvno] = useState(MvnosWithAll.ALL);
  const [filterText, setFilterText] = useState(q);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const debouncedFilterText = useDebounce(filterText, 500);

  useEffect(() => {
    //window.history.pushState({ page: page, size: perPage, q: q }, '/');
    getEvents();
  }, [
    page,
    perPage,
    sortField,
    sortDirection,
    activeEventStatus,
    mvno,
    debouncedFilterText,
  ]);

  useEffect(() => {
    console.log('call');

    reload({
      q: debouncedFilterText,
    });
  }, [debouncedFilterText]);

  const onActiveCheckChanged = async (index, event, checked) => {
    console.log(event, checked);

    try {
      const res = await apiService.editEventExposed(event.id, {
        isManualExpose: checked,
      });

      // const updatedEvent = res.data.result;
      // setEvents([
      //   ...events.slice(0, index),
      //   updatedEvent,
      //   ...events.slice(index + 1),
      // ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setMvno(Object.values(MvnosWithAll).find((m) => m.value === mvnoValue));
  }, [mvnoValue]);

  useEffect(() => {
    setActiveEventStatus(eventStatus);
  }, [eventStatus]);

  useEffect(() => {
    if (mvno.value !== '') {
      setScrapingBtnText(mvno.value + ' 스크래핑 요청');
      setScrapingBtnDisable(false);
    }
  }, [mvno]);

  const requestScraping = async () => {
    setIsLoading(true);
    try {
      let response = await apiService.requestEventScraping(mvno.value);
      if (response.status === 200) {
        alert(`${mvno.name} 스크래핑 완료되었습니다`);
      } else {
        alert(`${mvno.name} 스크래핑이 실패하였습니다! (${response.status})`);
      }
    } catch (e) {
      alert(`${mvno.name} 스크래핑이 실패하였습니다!`);
    } finally {
      setIsLoading(false);
    }
  };

  const reload = (updatedObject) => {
    console.log('query :', search);
    console.log(pathname, qs.parse(search), updatedObject);
    console.log({
      ...qs.parse(search),
      ...updatedObject,
    });
    navigate({
      pathname,
      search: qs.stringify({
        ...qs.parse(search),
        ...updatedObject,
      }),
    });
  };

  const getEvents = async () => {
    setLoading(true);

    try {
      let data = {};

      if (filterText === '') {
        data = {
          page,
          size: perPage,
        };
      } else {
        data = {
          page,
          size: perPage,
          q: filterText,
        };
      }

      if (sortField) {
        data['sort'] = `${sortField},${sortDirection}`;
      }

      if (mvnoValue != null && mvnoValue !== '') {
        data['mvno'] = mvnoValue;
      }

      console.log('activeEventStatus :', activeEventStatus);
      data.status = activeEventStatus;

      const response = await apiService.getEventList(data);
      if (response && response.data) {
        const listEntity = response.data;
        setEvents(listEntity.events);
        console.log(listEntity.events);
        setTotalSize(listEntity.totalSize);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    reload({
      page: page - 1,
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    reload({
      perPage: newPerPage,
    });
  };

  const handleSort = (column, sortDirection) => {
    console.log(column, column.sortField, sortDirection);
    reload({
      sortField: column.sortField,
      sortDirection: sortDirection,
    });
  };

  const updateActiveStatus = (eventStatus) => {
    reload({
      eventStatus,
    });
  };

  const isActiveRadio = useMemo(() => {
    return (
      <FilterSelectComponent
        className={'ms-2'}
        style={{ width: 100, display: 'inline-block' }}
        selectList={Object.entries(EventStatus).map(([status]) => ({
          name: EventStatusValue[status],
          value: status,
        }))}
        value={activeEventStatus}
        onChange={(e) => updateActiveStatus(e.target.value)}
      />
    );
  }, [activeEventStatus, search]);

  const updateMvno = (itemValue) => {
    reload({
      mvno: itemValue,
    });
  };

  const mvnoFilter = useMemo(() => {
    return (
      <FilterSelectComponent
        className={'ms-2'}
        style={{ width: 160, display: 'inline-block' }}
        selectList={Object.entries(MvnosWithAll).map(([key, value]) => ({
          name: value.name,
          value: value.value,
        }))}
        value={mvnoValue}
        onChange={(e) => updateMvno(e.target.value)}
      />
    );
  }, [mvnoValue, search]);

  const createButton = (
    <Button size="sm" onClick={() => navigate('/events/add')}>
      이벤트 만들기
    </Button>
  );

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <h4>이벤트</h4>
        {isLoading === false ? (
          <>
            <Button
              variant="primary"
              onClick={requestScraping}
              disabled={scrapingBtnDisable}
            >
              {scrapingBtnText}
            </Button>
          </>
        ) : (
          <>
            <Button variant="outline-info" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          </>
        )}
        <DataTable
          title="이벤트 목록"
          columns={eventColumns}
          data={events}
          pagination
          paginationServer
          paginationTotalRows={totalSize}
          paginationPerPage={DEFAULT_PER_PAGE}
          resetPaginationToggle={resetPaginationToggle}
          progressPending={loading}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          sortServer
          onSort={handleSort}
          subHeader
          subHeaderComponent={[isActiveRadio, mvnoFilter, searchBox]}
          persistTableHead
          actions={[createButton]}
        />
      </div>
    </>
  );
}
