import { Button, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Main } from '../../../../component/Main';
import { npsService, SurveyTemplate } from '../../../../service/npsService';

const NpsTemplateTableColumns: TableColumn<SurveyTemplate>[] = [
  {
    name: '순번',
    selector: 'id',
    maxWidth: '30px',
  },
  {
    name: '제목',
    selector: 'name',
    cell: (row) => <Link to={`/nps/template/${row.id}`}>{row.name}</Link>,
  },
  {
    name: '생성일',
    selector: 'regTs',
    maxWidth: '180px',
  },
  {
    name: '수정일',
    selector: 'updTs',
    maxWidth: '180px',
  },
];

const NpsTemplateListPage: FC = () => {
  const [templateList, setTemplateList] = useState<SurveyTemplate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    npsService
      .getSurveyTemplateList()
      .then((rs) => {
        setTemplateList(rs.data.result ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Main>
        <DataTable
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>NPS 템플릿 리스트</div>
              <Button href="/nps/template/create" variant="outlined">
                생성하기
              </Button>
            </Grid>
          }
          columns={NpsTemplateTableColumns}
          data={templateList}
          pagination
          paginationRowsPerPageOptions={[10, 30, 50, 100]}
          progressPending={loading}
        />
      </Main>
    </>
  );
};

export default NpsTemplateListPage;
