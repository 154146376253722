import { AxiosResponse } from 'axios';
import { orderApi, orderInternalApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import {
  MobilePlanOrder,
  MobilePlanOrderListDto,
  OrderStatusOptions,
} from '../entity/order';
import qs from 'query-string';

class OrderApiService {
  getList(
    token: string,
    data: any,
    page: number,
    size: number,
    sortField?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrderListDto>>> {
    let queryParams: any = {
      page,
      size,
    };

    if (sortField && sortDirection) {
      queryParams = {
        ...queryParams,
        sort: `${sortField},${sortDirection}`,
      };
    }

    return orderApi.post(`list?${qs.stringify(queryParams)}`, data, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getListForCalculate(
    data: any,
    page: number,
    size: number,
    sortField?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrderListDto>>> {
    let queryParams: any = {
      page,
      size,
    };

    if (sortField && sortDirection) {
      queryParams = {
        ...queryParams,
        sort: `${sortField},${sortDirection}`,
      };
    }

    return orderInternalApi.post(`list?${qs.stringify(queryParams)}`, data, {
      withCredentials: true,
    });
  }

  getOrder(
    token: string,
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.get(`/detail/${orderId}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getOrderStatusOptions(
    token: string,
  ): Promise<AxiosResponse<ApiResponse<OrderStatusOptions>>> {
    return orderApi.get('/status', {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateOrderStatus(
    token: string,
    orderId: string,
    status: string,
    statusDetail?: string,
    memo?: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put(
      '/status',
      {
        id: orderId,
        status,
        statusDetail,
        memo,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  updateOrder(
    token: string,
    orderId: string,
    planId: number,
    planName: string,
    memo?: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put(
      '/',
      {
        id: orderId,
        planId,
        planName,
        memo,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  editOrder(
    token: string,
    orderId: string,
    data: any,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put(`/detail/${orderId}`, data, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const orderApiService = new OrderApiService();
