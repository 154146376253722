import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EventType } from '../../../entity/settlement';

export const EVENT_TYPE_QUERY_KEY = 'event-type';
const 전체 = {
  label: '전체',
  value: 'all',
};

export function useSettlementEventType() {
  const eventTypes = useMemo(() => {
    const types = Object.entries(EventType).map(([key, value]) => ({
      label: key,
      value,
    }));

    return [전체, ...types];
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const eventType =
    searchParams.get(EVENT_TYPE_QUERY_KEY) ?? eventTypes[0].value;

  const setEventType = useCallback(
    (eventType: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(EVENT_TYPE_QUERY_KEY, eventType);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    eventType,
    eventTypes,
    setEventType,
  };
}
