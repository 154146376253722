import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

type FeeInformationInputsProps = {
  name: string;
  fee: number | null;
  feeAfterDiscount: number | null;
  promotionPeriod: number | null;
};

export const FeeInformationInputs: FC<FeeInformationInputsProps> = ({
  name,
  fee,
  feeAfterDiscount,
  promotionPeriod,
}) => {
  const [discountType, setDiscountType] = useState<string>('할인 요금제');
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <>
      <Grid container>
        <RadioGroup
          value={discountType}
          row
          onChange={(event) => {
            setDiscountType(event.target.value);
          }}
        >
          <FormControlLabel
            value="할인 요금제"
            control={<Radio />}
            label="할인 요금제"
          />
          <FormControlLabel
            value="평생 할인 요금제"
            control={<Radio />}
            onChange={() => {
              setValue('feeAfterDiscount', null);
              setValue('promotionPeriod', null);
            }}
            label="평생 할인 요금제"
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ gap: '0.5rem' }} fullWidth>
          <Controller
            control={control}
            defaultValue={name}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                required
                type="string"
                fullWidth
                label="요금제 이름"
              />
            )}
          />
          {discountType === '할인 요금제' ? (
            <>
              <Controller
                control={control}
                defaultValue={fee}
                name="fee"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) =>
                      field.onChange(parseInt(event.target.value))
                    }
                    required
                    type="number"
                    fullWidth
                    label="할인시 가격"
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={feeAfterDiscount}
                name="feeAfterDiscount"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) =>
                      field.onChange(parseInt(event.target.value))
                    }
                    required
                    type="number"
                    fullWidth
                    label="할인종료 후 가격"
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={promotionPeriod}
                name="promotionPeriod"
                rules={{
                  min: 1,
                  max: 36,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) => {
                      const enteredValue = parseInt(event.target.value);
                      if (enteredValue > 36) {
                        alert(
                          '36개월 이상은 설정하지 못해요. \n개발자에게 문의해주세요',
                        );
                        field.onChange(36);
                      } else if (enteredValue <= 0) {
                        alert(
                          '할인기간이 0인 요금제는 평생 할인 요금제로 생성해주세요',
                        );
                      } else {
                        field.onChange(enteredValue);
                      }
                    }}
                    required
                    type="number"
                    fullWidth
                    label="할인기간"
                  />
                )}
              />
            </>
          ) : (
            <Controller
              control={control}
              defaultValue={fee}
              name="fee"
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) =>
                    field.onChange(parseInt(event.target.value))
                  }
                  required
                  type="number"
                  fullWidth
                  label="요금제 가격"
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
    </>
  );
};
