import { FC } from 'react';
import { Grid, Switch, Typography } from '@mui/material';

import { CreateMoyoPlanTypes } from '../type';
import { Controller, useForm } from 'react-hook-form';

interface KtNetworkRegisteredProps {
  isKtNetworkRegistered: boolean;
}

export const KtNetworkRegistered: FC<KtNetworkRegisteredProps> = ({
  isKtNetworkRegistered,
}) => {
  const { control } = useForm<CreateMoyoPlanTypes>();
  return (
    <Grid spacing={0}>
      <Grid container>
        <Typography sx={{ m: 'auto 0' }} fontWeight={'bold'}>
          KT에 승인된 요금제인가요? (필수)
        </Typography>
        <Controller
          control={control}
          name="isKtNetworkRegistered"
          defaultValue={isKtNetworkRegistered}
          render={({ field }) => <Switch {...field} checked={field.value} />}
        />
      </Grid>
      <Typography fontWeight={'bold'}>
        KT 통신망 요금제는 승인된 요금제만 요청 가능합니다.
      </Typography>
    </Grid>
  );
};
