import { Spacing } from '../../../component/Spacing';
import { SearchSection } from '../components/SearchSection';
import { BizmSection } from './BizmSection';
import { EventRewardsSection } from './EventRewardsSection';
import { TaxAccountSection } from './TaxAccountSection';
import { TotalAmountsSection } from './TotalAmountsSection';
import { UserRewardSection } from './UserRewardsSection';

export function SettlementSummary() {
  return (
    <>
      <Spacing size={32} />
      <SearchSection showEventType />
      <Spacing size={24} />
      <TotalAmountsSection />
      <Spacing size={40} />
      <EventRewardsSection />
      <Spacing size={40} />
      <TaxAccountSection />
      <Spacing size={40} />
      <UserRewardSection />
      <Spacing size={40} />
      <BizmSection />
    </>
  );
}
