import { DatePicker } from '@mui/x-date-pickers';
import { Grid, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQueries, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Main } from '../../../component/Main';
import {
  NpsResult,
  npsService,
  NpsSurveyResult,
  NpsTemplateItem,
  NpsTemplateItemType,
  SurveyTemplate,
} from '../../../service/npsService';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import NpsCalculateTable from './NpsCalculateTable';

const DATA_GRID_SX = {
  mt: 3,
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
};

interface GridData {
  id: number;
  createdAt: string;
  userId: number;
  orderId: number;
  [key: string]: string | number;
}

const NpsResultPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = useMemo(
    () => Number(searchParams.get('page') ?? 0),
    [searchParams],
  );
  const pageSize = useMemo(
    () => Number(searchParams.get('pageSize') ?? 25),
    [searchParams],
  );
  const [totalCount, setTotalCount] = useState(0);
  const { id } = useParams();
  const [title, setTitle] = useState<string>('');
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD'),
  );
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<GridData>>([]);
  const [result, setResult] = useState<NpsResult>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const _id = Number(id);
    const _surveyTemplateId = Number(searchParams.get('surveyTemplateId'));
    if (isNaN(Number(id))) return;
    void loadSurveyTemplate(_surveyTemplateId);
    void loadSurveyResult(_id, startDate, endDate);
    return () => {
      setColumns([]);
    };
  }, [id, startDate, endDate]);

  const loadSurveyTemplate = async (id: number) => {
    const targetRs = await npsService.getSurveyTemplate(id);
    const target = targetRs.data.result;
    if (!target) return;
    generateColumns(target);
  };

  const loadSurveyResult = async (
    surveyId: number,
    startDate: string,
    endDate: string,
  ) => {
    setLoading(true);
    try {
      const resultRs = await npsService.getSurveyResult(
        surveyId,
        startDate,
        endDate,
      );
      const result = resultRs.data.result;
      if (!result) return;
      setResult(result);
      setTotalCount(result.totalCount);
      generateData(result.surveys);
    } finally {
      setLoading(false);
    }
  };

  const generateColumns = (surveyTemplate: SurveyTemplate) => {
    setTitle(surveyTemplate.name);
    const _columns = surveyTemplate.content.map(
      (item): GridColDef => ({
        field: item.template.title,
        headerName: item.template.title,
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      }),
    );
    setColumns([
      {
        field: 'id',
        headerName: 'id',
        headerAlign: 'center',
        align: 'center',
        width: 80,
      },
      {
        field: 'userId',
        headerName: '유저정보',
        headerAlign: 'center',
        align: 'center',
        width: 80,
        renderCell: ({ row }) => {
          return (
            <a
              href={`https://boyo.moyoplan.dev?search=${row.userId}`}
              target="_blank_"
            >
              {row.userId}
            </a>
          );
        },
      },
      {
        field: 'orderId',
        headerName: '주문정보',
        headerAlign: 'center',
        align: 'center',
        width: 80,
        renderCell: ({ row }) => {
          return (
            <a
              href={`https://partners.moyoplan.com/orders/${row.orderId}`}
              target="_blank_"
            >
              {row.orderId}
            </a>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: '생성일',
        headerAlign: 'center',
        align: 'center',
        width: 120,
      },
      ..._columns,
    ]);
  };

  const generateData = (results: NpsSurveyResult[]) => {
    const _data: GridData[] = results
      .filter((result) => result.contentMeta)
      .map((result) => {
        const surveyResult = JSON.parse(result.contentMeta!) as SurveyTemplate;
        const defaultData = {
          id: result.id,
          createdAt: result.createdAt,
          userId: result.userId,
          orderId: result.orderId,
        };
        return surveyResult.content
          .filter((item) => item.type !== NpsTemplateItemType.IMAGE)
          .reduce((value, item) => {
            const _value = getSurveyItemValue(item);
            return {
              ...value,
              [item.template.title]: _value,
            };
          }, defaultData);
      });

    setData(_data);
  };

  const getSurveyItemValue = (item: NpsTemplateItem) => {
    switch (item.type) {
      case NpsTemplateItemType.LINEAR:
        return Number(item.template.value);
      case NpsTemplateItemType.MULTIPLE_CHOICE:
        return (
          item.template.options.find(
            (option) => option.value === item.template.value,
          )?.text ?? ''
        );
      default:
        return item.template.value;
    }
  };

  return (
    <>
      <Main>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">템플릿 : {title}</Typography>
          </Grid>
          <Grid item rowSpacing={1}>
            <DatePicker
              label="시작일"
              value={startDate}
              onChange={(value) => {
                setStartDate(value?.toString().split('T')[0] ?? '');
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <DatePicker
              label="종료일"
              value={endDate}
              onChange={(value) => {
                setEndDate(value?.toString().split('T')[0] ?? '');
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Grid>
        </Grid>
        <Grid mt={2}>
          <NpsCalculateTable result={result} />
        </Grid>
        <DataGrid
          sx={DATA_GRID_SX}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          rows={data}
          rowCount={totalCount}
          columns={columns}
          loading={loading}
          pagination
          // paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            searchParams.set('page', page.toString());
            searchParams.set('pageSize', pageSize.toString());
            setSearchParams(searchParams);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick={true}
          autoHeight
        />
      </Main>
    </>
  );
};

export default NpsResultPage;
