import { MoyoOnlyListTypes, PlanDetailItem } from '../type';

export const createPlanDetails = (
  plan: MoyoOnlyListTypes,
  krCurrencyFormatter: Intl.NumberFormat,
): PlanDetailItem[] => {
  return [
    {
      title: 'mvno',
      value: plan.mvno,
      category: 'default',
    },
    {
      title: '요금제 코드',
      value: plan.planMetaJson?.code,
      category: 'default',
    },
    {
      title: '요금제 이름',
      value: plan.planMetaJson?.name,
      category: 'default',
    },
    {
      title: '파트너사 링크',
      value: plan.planMetaJson?.partnerSignupUrl,
      category: 'default',
    },
    {
      title: '할인 정보',
      value: krCurrencyFormatter.format(plan.planMetaJson?.fee),
      content: '할인시 가격',
      category: 'default',
    },
    {
      title: '할인 정보',
      value: krCurrencyFormatter.format(plan.planMetaJson?.feeAfterDiscount),
      content: '할인종료 후 가격',
      category: 'default',
    },
    {
      title: '할인 정보',
      value: krCurrencyFormatter.format(plan.planMetaJson?.promotionPeriod),
      content: '할인기간(개월 수)',
      category: 'default',
    },
    {
      title: '요금제 데이터',
      value: plan.planMetaJson?.call,
      content: '통화(분)',
      category: 'create',
    },
    {
      title: '요금제 데이터',
      value: plan.planMetaJson?.textMessage,
      content: '문자(건)',
      category: 'create',
    },
    {
      title: '요금제 데이터',
      value: plan.planMetaJson?.dataSpeed,
      content: '데이터(GB)',
      category: 'create',
    },
    {
      title: '요금제 데이터',
      value: plan.planMetaJson?.dataSpeed2,
      content: '일 추가 데이터(GB)',
      category: 'create',
    },
    {
      title: '요금제 데이터',
      value: plan.planMetaJson?.throttledSpeed / 1000,
      content: '소진시 속도(Mbps)',
      category: 'create',
    },
    {
      title: '부가 정보',
      value: plan.planMetaJson?.additionalCall,
      content: '부가 통화',
      category: 'create',
    },
    {
      title: '부가 정보',
      value: plan.planMetaJson?.tetheringAmount,
      content: '월 핫스팟 제공량(GB)',
      category: 'create',
    },
    {
      title: '부가 정보',
      value: plan.planMetaJson?.tetheringDailyAmount,
      content: '일 핫스팟 제공량(GB)',
      category: 'create',
    },
    {
      title: '부가 정보',
      value: plan.planMetaJson?.isSpecialCase
        ? plan.planMetaJson?.specialGroupName
        : '',
      content: '특수요금제',
      category: 'create',
    },
  ];
};

export const groupByTitle = (
  items: PlanDetailItem[],
): Record<string, PlanDetailItem[]> =>
  items.reduce<Record<string, PlanDetailItem[]>>((grouped, item) => {
    if (!grouped[item.title]) {
      grouped[item.title] = [];
    }
    grouped[item.title].push(item);
    return grouped;
  }, {});
