import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { MvnosWithAll } from '../../common/constants';
import { useDebounce } from '../../common/hooks';
import { FilterComponent } from '../../component/FilterComponent';
import FilterSelectComponent from '../../component/FilterSelectComponent';
import apiService from '../../service/api.service';
import EventStatus from '../../type/event/EventStatus';
const eventColumns = [
  {
    name: 'id',
    selector: 'id',
    sortable: true,
    sortField: 'id',
    maxWidth: '50px',
  },
  {
    name: '제목',
    selector: 'title',
    sortable: true,
    sortField: 'title',
  },
  {
    name: '종료일',
    selector: (row: any) => {
      const date = row.endDate;
      return date && date.length === 3
        ? `${date[0]}-${date[1]}-${date[2]}`
        : date;
    },
    sortable: true,
    sortField: 'endDay',
    maxWidth: '160px',
    center: true,
  },
  {
    name: '사은품 갯수',
    selector: 'detailCount',
    sortable: true,
    sortField: 'detailCount',
    maxWidth: '150px',
    center: true,
  },
  {
    name: '',
    selector: 'copy',
    width: '120px',
    // @ts-ignore
    cell: (row: any) => {
      const onClickCopy = async () => {
        if (
          !window.confirm(`(${row.id}) ${row.title} 정보를 가져오시겠습니까?`)
        )
          return;
        await apiService.copyEvent(row.eventId, row.id);
        window.location.reload();
      };
      return (
        <Button variant="outline-primary" onClick={onClickCopy}>
          가져오기
        </Button>
      );
    },
  },
];

interface IEventCopyModalProps {
  eventId: number;
  show: boolean;
  onHide: () => void;
}

const EventCopyModal: FC<IEventCopyModalProps> = ({
  eventId,
  show,
  onHide,
}) => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(30);
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState([]);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [filterText, setFilterText] = useState<string>('');
  const debouncedFilterText = useDebounce(filterText, 500);
  const [mvno, setMvno] = useState(MvnosWithAll.ALL.value);

  useEffect(() => {
    getEvents(page, perPage, mvno, debouncedFilterText);
  }, [page, perPage, mvno, debouncedFilterText]);

  const getEvents = async (
    pageIndex: number,
    perPageSize: number,
    mvno: string,
    text: string,
  ) => {
    setLoading(true);

    try {
      let data = {
        mvno: mvno || undefined,
        status: EventStatus.ALL,
        page: pageIndex,
        size: perPageSize,
        q: text,
      };

      const response = await apiService.getEventList(data);
      if (response && response.data) {
        const listEntity = response.data;
        setEvents(
          listEntity.events.map((event: any) => ({
            ...event,
            eventId,
          })),
        );
        console.log(listEntity.events);
        setTotalSize(listEntity.totalSize);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setPerPage(newPerPage);
  };

  const mvnoFilter = useMemo(() => {
    return (
      <FilterSelectComponent
        className={'ms-2'}
        style={{ width: 160, display: 'inline-block' }}
        selectList={Object.entries(MvnosWithAll).map(([key, value]) => ({
          name: value.name,
          value: value.value,
        }))}
        value={mvno}
        onChange={(e: any) => setMvno(e.target.value)}
      />
    );
  }, [mvno]);

  const searchBox = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        // setResetPaginationToggle(resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>이벤트 상품 가져오기</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          title="이벤트 목록"
          columns={eventColumns}
          data={events}
          pagination
          paginationServer
          paginationTotalRows={totalSize}
          paginationPerPage={30}
          progressPending={loading}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          subHeaderComponent={[mvnoFilter, searchBox]}
          sortServer
          subHeader
          persistTableHead
        />
      </Modal.Body>
    </Modal>
  );
};

export default EventCopyModal;
