import React, { FC, useEffect, useState } from 'react';
import apiService from '../../service/api.service';
import SeoKeywordRankingLineChart from './SeoKeywordRankingLineChart';
import { Button, Col, DropdownButton, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';

export interface Keyword {
  id: number;
  keyword: string;
}

export interface History {
  domain: string;
  historyList: {
    date: string;
    ranking: number;
    rankingStr: string;
  }[];
}

export interface RankingKeyword {
  keywordId: number;
  keyword: string;
  history: History[];
  selected?: boolean;
}

interface SearchKeywordForm {
  keyword: string;
}

const SeoKeywordSection: FC = () => {
  const [autoKeywords, setAutoKeywords] = useState<string[]>([]);
  const [summaryKeywords, setSummaryKeywords] = useState<RankingKeyword[]>([]);
  const [noResultKeywords, setNoResultKeywords] = useState<string[]>([]);
  const { register, handleSubmit } = useForm<SearchKeywordForm>({});
  const {
    register: register2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({});
  const [rangeKeywords, setRangeKeywords] = useState<RankingKeyword[]>([]);
  const [rangeStart, setRangeStart] = useState<string>('');
  const [rangeEnd, setRangeEnd] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    getKeywordsWithRanking();
  }, []);

  useEffect(() => {
    updateRangeKeywords(rangeStart || '1970-01-01', rangeEnd || '2999-12-31');
  }, [summaryKeywords, rangeStart, rangeEnd]);

  const getKeywordsWithRanking = async () => {
    //const response = await apiService.getAllKeywordWithRanking();
    // const summaryResponse = await apiService.getAllKeyword();
    const keywordResponse = await apiService.getAllKeywordWithRanking();
    const noKeywordResponse = await apiService.getNoResultKeyword();

    //setKeywords(response.data.result);
    setSummaryKeywords(keywordResponse.data.result);
    setAutoKeywords(
      (keywordResponse.data.result as RankingKeyword[]).map(
        (keyword) => keyword.keyword,
      ),
    );
    setNoResultKeywords(noKeywordResponse.data.result.keywords);
  };

  const onSubmit = async (data: SearchKeywordForm) => {
    if (summaryKeywords.find((keyword) => keyword.keyword === data.keyword)) {
      alert('이미 등록된 키워드입니다');
    } else {
      const requestBody = {
        ...data,
      };

      await apiService.addKeyword(requestBody);

      const response = await apiService.getAllKeyword();
      setSummaryKeywords(response.data.result.list);
      setAutoKeywords(
        (response.data.result as RankingKeyword[]).map(
          (keyword) => keyword.keyword,
        ),
      );
    }
  };

  const updateRangeKeywords = (start: string, end: string) => {
    const result = summaryKeywords
      .filter((keyword) => keyword.selected)
      .map((resultItem) => {
        return {
          keywordId: resultItem.keywordId,
          keyword: resultItem.keyword,
          history: resultItem.history.map((historyItem) => {
            return {
              domain: historyItem.domain,
              historyList: historyItem.historyList.filter((historyListItem) => {
                return (
                  start <= historyListItem.date && historyListItem.date <= end
                );
              }),
            };
          }),
        };
      });
    console.log('result :', result);
    setRangeKeywords(result);
  };

  const onClickKeywordHandler = (keyword: RankingKeyword) => {
    keyword.selected = !keyword.selected;
    setSummaryKeywords((rs) => [...rs]);
  };

  return (
    <>
      <p>
        네이버 = 페이지당 15개 노출 ( ex : 2페이지 2번째 노출 = 17 ) / 구글 =
        페이지당 10개
      </p>
      <Box position="relative">
        <Accordion
          defaultExpanded
          style={{ position: 'sticky', top: 0, zIndex: 1 }}
        >
          <AccordionSummary>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
            >
              <Typography>키워드 관리</Typography>
              <Box
                display="flex"
                gap={1}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Box>
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    onChange={(e) => {
                      setRangeStart(e.currentTarget.value);
                    }}
                  />
                  <input
                    className="mx-3"
                    type="date"
                    id="end"
                    name="trip-end"
                    onChange={(e) => {
                      setRangeEnd(e.currentTarget.value);
                    }}
                  />
                </Box>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: 'flex', gap: 8 }}
                >
                  <Form.Group controlId="formName">
                    <Form.Control
                      {...register('keyword', {
                        required: true,
                      })}
                      style={{ fontSize: '12px', width: 188 }}
                      placeholder="SEO 확인을 위한 키워드"
                    />
                  </Form.Group>
                  <div className="d-flex mt-150">
                    <Button type="submit" variant="outline-primary" size={'sm'}>
                      저장하기
                    </Button>
                  </div>
                </Form>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container gap={1}>
              <Autocomplete
                options={autoKeywords}
                freeSolo
                onChange={(_, __, reason) => {
                  if (reason === 'clear') {
                    setSearchKeyword('');
                    setValue2('searchText', '');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    label="검색"
                    style={{ width: 200 }}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') return;
                      setSearchKeyword(watch2('searchText'));
                    }}
                    {...params}
                    {...register2('searchText')}
                    size="small"
                  />
                )}
              />
              <Button
                onClick={() => {
                  setSearchKeyword(watch2('searchText'));
                }}
              >
                검색
              </Button>
            </Grid>
            <Box maxHeight={120} overflow="auto" marginTop={1}>
              <Grid container gap={1}>
                {summaryKeywords
                  .filter((keyword) =>
                    `${keyword.keyword}`
                      .toLocaleLowerCase()
                      .replaceAll(' ', '')
                      .includes(
                        searchKeyword.toLocaleLowerCase().replaceAll(' ', ''),
                      ),
                  )
                  .sort()
                  .map((keyword) => {
                    const noResult = noResultKeywords.includes(keyword.keyword);
                    return (
                      <Chip
                        variant={noResult ? undefined : 'outlined'}
                        onClick={() => {
                          if (noResult) return;
                          onClickKeywordHandler(keyword);
                        }}
                        label={keyword.keyword}
                        color={keyword.selected ? 'success' : undefined}
                      />
                    );
                  })}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <p style={{ fontSize: '10px' }}>
          (아직 순위가 기록되지 않은 키워드는 아래 그래프에 나타나지 않습니다)
        </p>

        <hr />

        <Box marginTop={1}>
          <Grid container gap={1} overflow="auto">
            {rangeKeywords.map((keyword) => {
              return (
                <Box key={keyword.keywordId}>
                  <hr />
                  {keyword.keyword}
                  <SeoKeywordRankingLineChart
                    key={keyword.keywordId}
                    data={keyword}
                  />
                </Box>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SeoKeywordSection;
