import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import apiService from '../../service/api.service';
import Spinner from '../../component/Spinner';

export default function EventDetailPlan({ eventPlans, eventId }) {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPlans(eventPlans);
  }, [eventPlans]);

  const planColumns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '60px',
    },
    {
      name: 'MVNO',
      selector: (row) => row.mvno,
      sortable: true,
    },
    {
      name: '요금제 이름',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '350px',
    },
    {
      name: 'MNO',
      selector: (row) => row.mno,
      sortable: true,
    },
    {
      name: '망',
      selector: (row) => row.net,
      sortable: true,
    },
    {
      name: '가격',
      selector: (row) => row.fee,
      sortable: true,
    },
  ];

  const savePlans = async () => {
    try {
      setIsLoading(true);
      await apiService.saveScrapEventPlansRelation(
        eventId,
        plans.map((plan) => plan.id),
      );
      setIsLoading(false);
      window.location.reload(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const delPlans = async () => {
    try {
      setIsLoading(true);
      await apiService.delScrapEventPlansRelation(eventId);

      setIsLoading(false);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
      window.location.reload(false);
    }
  };

  const addPlansButton = (
    <Button
      key="addPlans"
      variant="outline-primary"
      size="sm"
      onClick={() => savePlans()}
    >
      요금제 저장
    </Button>
  );

  const delPlansButton = (
    <Button
      key="addPlans"
      variant="outline-danger"
      size="sm"
      onClick={() => delPlans()}
    >
      요금제 전체삭제
    </Button>
  );

  return (
    <>
      <DataTable
        title={'이벤트 기간 요금제'}
        columns={planColumns}
        data={plans}
        pagination
        actions={[addPlansButton, delPlansButton]}
      />
      <Spinner isShow={isLoading} />
    </>
  );
}
