import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ConstraintApiInterface } from '../../../apis/service/constraints/types';
import { CreatePlanConstraint } from '../../../apis/service/constraints';

export const useCreateConstraint = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<AxiosResponse<void>>,
    ConstraintApiInterface
  >({
    mutationKey: ['create', 'constraint'],
    mutationFn: (parameter) => CreatePlanConstraint(parameter),
  });
};
