import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import apiService from '../../service/api.service';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { ConstraintMvnoNameFilter } from './ConstraintMvnoNameFilter';
import { Input } from '@mui/material';

export default function ConstraintList() {
  const constraintColumns = [
    {
      field: 'id',
      // selector: (row) => row.id,
      // sortable: true,
      // sortField: 'id',
      // maxWidth: '30px',
      headerAlign: 'center',
      headerName: 'id',
      align: 'center',
      width: 100,
    },
    {
      field: 'mvno',
      headerAlign: 'center',
      headerName: '통신사',
      align: 'center',
      width: 100,
    },
    {
      field: 'mno',
      headerAlign: 'center',
      headerName: '통신망',
      align: 'center',
      width: 100,
    },
    {
      field: 'type',
      headerAlign: 'center',
      headerName: '타입',
      align: 'center',
      width: 120,
    },
    {
      field: 'detailType',
      headerAlign: 'center',
      headerName: '상세타입',
      align: 'center',
      width: 100,
    },
    {
      field: 'typeId',
      headerAlign: 'center',
      headerName: '상세 타입 id',
      align: 'center',
      width: 120,
    },
    {
      field: 'regTs',
      headerAlign: 'center',
      headerName: '등록일자',
      align: 'center',
      width: 140,
      renderCell: (row) => {
        return moment(row.regTs).format('YYYY년 MM월 DD일');
      },
    },

    {
      headerName: '삭제',
      renderCell: (row, index, column, id) => (
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => removeConstraint(row.id)}
        >
          삭제
        </Button>
      ),
      headerAlign: 'center',
      width: 60,
    },
    {
      field: 'description',
      selector: (row) => row.description,
      sortable: true,
      headerName: '설명',
    },
  ];

  const navigate = useNavigate();
  const [constraints, setConstraints] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [nameData, setNameData] = useState('');
  const [searchPlan, setSearchPlan] = useState();

  const fetchConstraint = async () => {
    try {
      const res = await apiService.getConstraints();
      console.log(res);
      if (res && res.data) {
        setConstraints(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  async function removeConstraint(id) {
    if (window.confirm('삭제하시겠습니까?')) {
      try {
        await apiService.deleteConstraint(id);
        setConstraints(constraints.filter((el) => el.id !== id));
        alert('삭제되었습니다');
      } catch (err) {
        console.log(err);
      }
    }
  }
  useEffect(() => {
    fetchConstraint();
  }, []);

  const onRowClick = (row, event) => {
    window.open(`/constraint/${row.id}`);
  };

  const createButton = (
    <Button
      size="sm"
      style={{
        marginLeft: 'auto',
        marginRight: '0',
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
      onClick={() => navigate('/constraint/add')}
    >
      제약조건 만들기
    </Button>
  );

  const filteredData = constraints.filter((data) => {
    if (searchPlan !== undefined && searchPlan !== 0) {
      const searchById = String(data.typeId).includes(searchPlan);
      return searchById;
    }
    if (nameData) {
      const isNameMatch =
        data.mvno === undefined
          ? null
          : !nameData || data.mvno.includes(nameData);
      return isNameMatch;
    } else {
      return constraints;
    }
  });
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: '20px',
          marginTop: '20px',
        }}
      >
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div>
            <ConstraintMvnoNameFilter
              nameData={nameData}
              setNameData={setNameData}
            />
          </div>
          <Input
            placeholder="요금제 id 검색"
            value={searchPlan}
            onChange={(event) => setSearchPlan(event.target.value)}
          />
        </div>
        <div style={{ display: 'flex', width: '100%' }}>{createButton}</div>
      </div>
      <DataGrid
        columns={constraintColumns}
        rows={filteredData}
        pagination
        paginationPerPage={30}
        checkboxSelection
        onRowClick={onRowClick}
        onRowSelectionModelChange={(id) => {
          setSelectedIds(id);
        }}
        rowSelectionModel={selectedIds}
      />
    </>
  );
}
