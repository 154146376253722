import axios, { AxiosResponse } from "axios";
import { AutoCompletePostTitle, GetPostListResponse, GetPostSummaryResponse, GetRelatedPostsResponse, SearchPostResponse } from "../entity/communityEntity";

class CommunityApiService {
  private readonly http;
  private readonly token;
  constructor(token?: string) {
    this.token = token;
    this.http = axios.create({
      baseURL: new URL('/api/v1', process.env.REACT_APP_COMMUNITY_API_BASE_URL).href,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  searchPosts(title: string): Promise<AxiosResponse<SearchPostResponse>> {
    return this.http.get(encodeURI(`/search/post?query=${title}`));
  }

  getPostList(
    page: number,
    sort: 'VIEW_COUNT_DESC' | 'REG_TS_DESC',
  ): Promise<AxiosResponse<GetPostListResponse>> {
    return this.http.get(`/post?page=${page}&sort=${sort}`);
  }

  getPostAutoComplete(
    searchText: string,
  ): Promise<AxiosResponse<AutoCompletePostTitle[]>> {
    return this.http.get(`/search/post/auto-complete?query=${searchText}`);
  }

  getPost(
    id: string,
    increaseViewcount?: boolean,
  ): Promise<AxiosResponse<GetPostSummaryResponse>> {
    return this.http.get(
      `/post/${id}?increaseViewCount=${increaseViewcount ?? true}`,
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  putPostTitle(
    uuid: string,
    title: string
  ): Promise<AxiosResponse<boolean>> {
    console.log('this.createAuthHeader() :', this.createAuthHeader());
    return this.http.put(
      `/post/${uuid}/title`,
      {
        title,
      },
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  putPostContent(
    uuid: string,
    content: string
  ): Promise<AxiosResponse<boolean>> {
    return this.http.put(
      `/post/${uuid}/content`,
      {
        content,
      },
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  deletePost(
    uuid: string,
  ): Promise<AxiosResponse<boolean>> {
    return this.http.delete(
      `/post/${uuid}`,
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  putComment(
    uuid: string,
    content: string,
  ): Promise<AxiosResponse<boolean>> {
    return this.http.put(
      `/post/${uuid}/comment`,
      {
        content,
      },
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  deleteComment(
    uuid: string,
  ): Promise<AxiosResponse<boolean>> {
    return this.http.delete(
      `/post/${uuid}/comment`,
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  getRelatedPosts(
    id: string,
    mode: 'randomized',
  ): Promise<AxiosResponse<GetRelatedPostsResponse>> {
    return this.http.get(`/post/${id}/related?queryMode=${mode.toUpperCase()}`);
  }

  createComment(
    parentId: string,
    content: string,
  ): Promise<
    AxiosResponse<{
      uuid: string;
      createdAt: string;
      authorName: string;
      isVerifiedAuthor: boolean;
    }>
  > {
    return this.http.post(
      `/post/${parentId}/comment`,
      {
        content,
      },
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  createAnswer(
    parentId: string,
    content: string,
  ): Promise<AxiosResponse<{ uuid: string; createdAt: string }>> {
    return this.http.post(
      `/post/${parentId}`,
      {
        content,
      },
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  createPost(
    title: string,
    content: string,
  ): Promise<
    AxiosResponse<{ uuid: 'string'; slug: 'string'; createdAt: 'string' }>
  > {
    return this.http.post(
      '/post',
      {
        title,
        content,
      },
      {
        headers: {
          ...this.createAuthHeader(),
        },
      },
    );
  }

  likePost(id: string): Promise<AxiosResponse<null>> {
    return this.http.post(`/post/${id}/reaction`, null, {
      headers: {
        ...this.createAuthHeader(),
      },
    });
  }

  cancelPostLike(id: string): Promise<AxiosResponse<null>> {
    return this.http.delete(`/post/${id}/reaction`, {
      headers: {
        ...this.createAuthHeader(),
      },
    });
  }

  getMeta(): Promise<
    AxiosResponse<{
      totalPostCount: number;
      childPostCount: number;
      totalCommentCount: number;
    }>
  > {
    return this.http.get('/meta');
  }

  private createAuthHeader() {
    const token = this.token || '';
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    }
    return {};
  }
}

export const communityApiService = new CommunityApiService();
export const createCommunityApiService = (token: string) =>
  new CommunityApiService(token);