import React from 'react';
import { Line } from '@nivo/line';

/**
 * Seo 키워드 날짜별 노출 순서를 보여주는 Line 차트
 */
const SeoKeywordRankingLineChart = (keyword) => {
  return (
    <Line
        width={(window.innerWidth - 48)}
        height={300}
        margin={{top: 50, right: 30, bottom: 50, left: 60}}
        data={keyword.data.history.map((h) => {
          return {
            id: h.domain,
            data: h.historyList.map((item) => {
              return {
                x: item.date,
                y: item.ranking,
              };
            }),
          };
        })}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          stacked: false,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '순위',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '날짜',
          legendOffset: 36,
          format: '%b %d',
          tickValues: 'every 2 days',
          legendPosition: 'middle',
        }}
        enablePointLabel={true}
        pointSize={10}
        pointColor={{from: 'color', modifiers: []}}
        pointBorderWidth={2}
        pointBorderColor={{from: 'serieColor'}}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices={false}
        tooltip={'test'}
        colors={{scheme: 'category10'}}
        legends={[
          {
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateX: 50,
            translateY: -50,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
    />
  );
};

export default SeoKeywordRankingLineChart;
