import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import apiService from '../service/api.service';
import { FilterComponent } from '../component/FilterComponent';
import { Button, Form } from 'react-bootstrap';
import { useCallback } from 'react';

const planColumns = [
  {
    name: 'id',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'MVNO',
    selector: 'mvno',
    sortable: true,
  },
  {
    name: '요금제 이름',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'MNO',
    selector: 'mno',
    sortable: true,
  },
  {
    name: '망',
    selector: 'net',
    sortable: true,
  },
  {
    name: '가격',
    selector: 'fee',
    sortable: true,
  },
];

const eventColumns = [
  {
    name: 'id',
    selector: 'id',
  },
  {
    name: '제목',
    selector: 'title',
  },
  {
    name: '이미지',
    selector: 'thumbnailImageUrl',
  },
];

const differenceBy = (array1, array2, key) => {
  return array1.filter((a) => !array2.some((b) => b[key] === a[key]));
};

var num = 0;
const incrementalInt = () => {
  return num++;
};

export default function PlanGift() {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [events, setEvents] = useState([]);
  const [gifts, setGifts] = useState([]);

  const conditionalRowStyles = [
    {
      when: (plan) => plan === selectedPlan,
      style: {
        backgroundColor: 'gray',
      },
    },
  ];

  const onChangeGiftEventId = (e, gift) => {
    gift.eventId = e.target.value;
  };

  const onChangeGiftType = (e, gift) => {
    gift.type = e.target.value;
  };

  const onChangeGiftDesc = (e, gift) => {
    gift.description = e.target.value;
  };

  const giftColumns = [
    {
      name: 'id',
      selector: 'id',
    },
    {
      name: '요금제 id',
      selector: 'planId',
    },
    {
      name: '이벤트 id',
      selector: 'eventId',
      cell: (row) => (
        <Form.Control
          type="text"
          size="sm"
          placeholder="이벤트 id"
          defaultValue={row.eventId}
          onChange={(e) => onChangeGiftEventId(e, row)}
        />
      ),
    },
    {
      name: '타입',
      selector: 'type',
      cell: (row) => (
        <Form.Control
          type="text"
          size="sm"
          placeholder="타입"
          defaultValue={row.type}
          onChange={(e) => onChangeGiftType(e, row)}
        />
      ),
    },
    {
      name: '설명',
      selector: 'description',
      cell: (row) => (
        <Form.Control
          type="text"
          size="sm"
          placeholder="설명"
          defaultValue={row.description}
          onChange={(e) => onChangeGiftDesc(e, row)}
        />
      ),
    },
  ];

  useEffect(() => {
    async function getPlans() {
      try {
        const response = await apiService.getPlans();
        setPlans(response.data.result);
      } catch (err) {
        console.debug(err);
      }
    }

    getPlans();
  }, []);

  useEffect(() => {
    async function getEvents() {
      try {
        const response = await apiService.getEvents({
          mvno: selectedPlan.mvno,
        });

        if (response && response.data) {
          setEvents(response.data.events);
        }
      } catch (err) {
        console.debug(err);
      }
    }

    async function getGifts() {
      try {
        const response = await apiService.getGifts({
          planId: selectedPlan.id,
        });

        if (response && response.data) {
          const giftResponse = response.data.result.map((gift) => {
            return { ...gift, uid: incrementalInt() };
          });

          setGifts(
            giftResponse.length > 0
              ? giftResponse
              : [{ planId: selectedPlan.id, uid: incrementalInt() }],
          );
        }
      } catch (err) {
        console.debug(err);
      }
    }

    if (selectedPlan) {
      setToggleCleared((t) => !t);
      getEvents();
      getGifts();
    }
  }, [selectedPlan]);

  const onPlanClicked = (plan) => {
    setSelectedPlan(plan);
  };

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = plans.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const onClickGiftAdd = () => {
    if (!selectedPlan) return;
    setGifts([...gifts, { planId: selectedPlan.id, uid: incrementalInt() }]);
  };

  const onClickSave = async () => {
    console.log(gifts);
    if (!selectedPlan) return;
    const response = await apiService.updateGifts({
      planId: selectedPlan.id,
      gifts: gifts,
    });

    if (response.data.resultType === 'SUCCESS') {
      alert('저장되었습니다');
    }
  };

  const addGiftButton = (
    <Button size="sm" onClick={onClickGiftAdd}>
      추가
    </Button>
  );

  const saveGiftButton = (
    <Button size="sm" onClick={onClickSave}>
      저장
    </Button>
  );

  const [selectedGifts, setSelectedGifts] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = useCallback((state) => {
    console.log(state.selectedRows);
    setSelectedGifts(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      setToggleCleared(!toggleCleared);
      setGifts(differenceBy(gifts, selectedGifts, 'uid'));
    };

    return (
      <Button size="sm" key="delete" onClick={handleDelete} variant="danger">
        삭제
      </Button>
    );
  }, [gifts, selectedGifts, toggleCleared]);

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <DataTable
          title="요금제"
          columns={planColumns}
          data={filteredItems}
          conditionalRowStyles={conditionalRowStyles}
          onRowClicked={onPlanClicked}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          highlightOnHover
          pointerOnHover
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        ></DataTable>
        {selectedPlan && (
          <div className="pb-5">
            <DataTable
              title="이벤트"
              columns={eventColumns}
              data={events}
              pagination
            ></DataTable>
            <DataTable
              title="사은품"
              columns={giftColumns}
              data={gifts}
              actions={[addGiftButton, saveGiftButton]}
              contextActions={contextActions}
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
            ></DataTable>
          </div>
        )}
      </div>
    </>
  );
}
