import React from 'react';
import PlanDataTable from '../../component/PlanDataTable';

const Disclaimer = () => {
  const onRowClick = (row, event) => {
    window.open(`/disclaimer/${row.id}`);
  };

  return (
    <>
      <div
        className=" mt-3 pb-12"
        style={{ marginLeft: '1rem', marginRight: '1rem' }}
      >
        <h4>Disclaimer 수정하기</h4>
        <PlanDataTable onRowClick={onRowClick} urlPath={'disclaimer'} />
      </div>
    </>
  );
};

export default Disclaimer;
