import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getMonthsBetween } from '../../../common/date-utils';

export const YEAR_MONTH_QUERY_KEY = 'year-month';

export function useSettlementPeriod() {
  const availableYearMonths = useMemo(
    () => getMonthsBetween(new Date('2023-07-01'), moment(new Date()).subtract(1, 'month')).reverse(),
    [],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const yearMonth =
    searchParams.get(YEAR_MONTH_QUERY_KEY) ?? availableYearMonths[0];

  const { startDate, endDate } = useMemo(() => {
    const yearMonthMoment = moment(yearMonth);
    const startDate = yearMonthMoment.startOf('month').format('yyyy-MM-DD');
    // 정산 서버에서 endDat <= yyyy-MM-DD 를 사용하는데 해당 쿼리를 사용하면 yyyy-MM-DD 
    const endDate = yearMonthMoment.endOf('month').add(1, 'days').format('yyyy-MM-DD');
    return { startDate, endDate };
  }, [yearMonth]);

  const setYearMonth = useCallback(
    (yearMonth: string, extraParams?: Array<[string, string]>) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(YEAR_MONTH_QUERY_KEY, yearMonth);
      if (extraParams != null) {
        extraParams.forEach(([key, value]) => newSearchParams.set(key, value));
      }
      setSearchParams(newSearchParams);
    },
    [setSearchParams, searchParams],
  );

  return {
    availableYearMonths,
    yearMonth,
    setYearMonth,
    startDate,
    endDate,
  };
}
