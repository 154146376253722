import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetPostSummaryChildCommentEntry,
  GetPostSummaryChildPostEntry,
  GetPostSummaryResponse,
} from '../../../entity/communityEntity';
import { communityApiService } from '../../../service/communityService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { gptApiService } from '../../../service/gptService';
import { useForm } from 'react-hook-form';

const CommunityCommnet: FC<{ comment: GetPostSummaryChildCommentEntry }> = ({
  comment,
}) => {
  const { register, watch } = useForm({});

  const onDelete = async () => {
    try {
      if (!window.confirm('정말로 삭제할까요?')) return;
      await communityApiService.deleteComment(comment.uuid);
      alert('삭제 완료');
      window.location.reload();
    } catch (e: any) {
      alert(`삭제 에러 : ${e.toString()}`);
    }
  };

  const onSave = async () => {
    try {
      await communityApiService.putComment(comment.uuid, watch('content'));
      alert('저장 완료');
      window.location.reload();
    } catch (e: any) {
      alert(`저장 에러 : ${e.toString()}`);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {comment.authorName}
        </Typography>
        <Typography variant="body2">
          <TextField
            label="내용"
            multiline
            fullWidth
            defaultValue={comment.content}
            {...register('content')}
          />
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container gap={1} justifyContent="flex-end">
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={onSave}
          >
            저장
          </Button>
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={onDelete}
          >
            삭제
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const ChildPost: FC<{ post: GetPostSummaryChildPostEntry }> = ({ post }) => {
  const { register, watch } = useForm({});

  const onSave = async () => {
    try {
      await communityApiService.putPostContent(post.uuid, watch('content'));
      alert('저장 완료');
      window.location.reload();
    } catch (e: any) {
      alert(`저장 에러 : ${e.toString()}`);
    }
  };

  const onDelete = async () => {
    try {
      if (!window.confirm('정말로 삭제할까요?')) return;
      await communityApiService.deletePost(post.uuid);
      alert('삭제 완료');
      window.location.reload();
    } catch (e: any) {
      alert(`삭제 에러 : ${e.toString()}`);
    }
  };

  return (
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" marginBottom={2}>
        {post.authorName}
      </Typography>
      <Typography variant="body2">
        <TextField
          label="내용"
          multiline
          fullWidth
          defaultValue={post.content}
          {...register('content')}
        />
      </Typography>
      <CardActions>
        <Grid container gap={1} justifyContent="flex-end">
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={onSave}
          >
            저장
          </Button>
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={onDelete}
          >
            삭제
          </Button>
        </Grid>
      </CardActions>
      {!!post.childComments.length && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">댓글</Typography>
          </AccordionSummary>
          {post.childComments.map((childComment) => (
            <AccordionDetails>
              <CommunityCommnet comment={childComment} />
            </AccordionDetails>
          ))}
        </Accordion>
      )}
    </CardContent>
  );
};
const CommunityDetailPage: FC = () => {
  const { uuid } = useParams();
  const { register, watch } = useForm({});
  const [postData, setPostData] = useState<GetPostSummaryResponse>();
  const [metaTitle, setMetaTitle] = useState<string>();
  const [recommendAnswerFromUser, setRecommendAnswerFromUser] =
    useState<string>();
  const [recommendAnswerFromAssistant, setRecommendAnswerFromAssistance] =
    useState<string>();

  useEffect(() => {
    (async () => {
      if (!uuid) return;
      const post = await (await communityApiService.getPost(uuid)).data;
      setPostData(post);
    })();
  }, []);

  const metaTitleAccordionExpanded = async (_: any, expanded: boolean) => {
    if (!expanded) return;
    if (metaTitle) return;
    const rs = await gptApiService.getMetaTitle(
      `${postData?.title}\n${postData?.content}`,
    );
    setMetaTitle(rs.data.response);
  };

  const recommendAnswerExpanded = async (_: any, expanded: boolean) => {
    if (!expanded) return;
    (async () => {
      if (recommendAnswerFromUser) return;
      const rs = await gptApiService.getUserAnswerQuery(
        `${postData?.title}\n${postData?.content}`,
      );
      setRecommendAnswerFromUser(rs.data.response);
    })();
    (async () => {
      if (recommendAnswerFromAssistant) return;
      const rs = await gptApiService.getQuery(
        `${postData?.title}\n${postData?.content}`,
      );
      setRecommendAnswerFromAssistance(rs.data.response);
    })();
  };

  const onSavePost = async () => {
    if (!uuid) return;
    try {
      await communityApiService.putPostTitle(uuid, watch('title'));
      await communityApiService.putPostContent(uuid, watch('content'));
      alert('저장 완료');
      window.location.reload();
    } catch (e: any) {
      alert(`저장 에러 : ${e.toString()}`);
    }
  };

  const onDeletePost = async () => {
    if (!uuid) return;
    try {
      if (!window.confirm('정말로 삭제할까요?')) return;
      await communityApiService.deletePost(uuid);
      alert('삭제 완료');
      window.location.href = '/community';
    } catch (e: any) {
      alert(`삭제 에러 : ${e.toString()}`);
    }
  };

  return (
    <div>
      <div className="container-md mt-3 pb-5" key={postData?.uuid}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container gap={1} justifyContent="flex-end">
              <Button
                size="large"
                color="primary"
                variant="outlined"
                onClick={onSavePost}
              >
                저장
              </Button>
              <Button
                size="large"
                color="error"
                variant="outlined"
                onClick={onDeletePost}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              label="제목"
              variant="outlined"
              key={postData?.uuid}
              defaultValue={postData?.title}
              fullWidth
              {...(postData ? register('title') : {})}
            />
            <Accordion onChange={metaTitleAccordionExpanded}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">추천 제목</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {metaTitle ? (
                  <pre style={{ whiteSpace: 'pre-wrap' }}>{metaTitle}</pre>
                ) : (
                  <Box my={2}>
                    <LinearProgress />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <TextField
              label="내용"
              multiline
              fullWidth
              key={postData?.uuid}
              defaultValue={postData?.content}
              {...(postData ? register('content') : {})}
            />
          </Grid>
        </Grid>
        <Accordion onChange={recommendAnswerExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">추천 답변</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>유저향</Typography>
            {recommendAnswerFromUser ? (
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {recommendAnswerFromUser}
              </pre>
            ) : (
              <Box my={2}>
                <LinearProgress />
              </Box>
            )}
            <Typography>상담사향</Typography>
            {recommendAnswerFromAssistant ? (
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {recommendAnswerFromAssistant}
              </pre>
            ) : (
              <Box my={2}>
                <LinearProgress />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        {!!postData?.childComments.length && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">댓글</Typography>
            </AccordionSummary>
            {postData?.childComments.map((childComment) => (
              <CommunityCommnet comment={childComment} />
            ))}
          </Accordion>
        )}
        {!!postData?.childPosts.length && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">답변</Typography>
            </AccordionSummary>

            {postData?.childPosts.map((childPost) => (
              <div>
                <ChildPost post={childPost} />
              </div>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default CommunityDetailPage;
