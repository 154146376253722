import axios, { AxiosResponse, CancelToken } from "axios";
import { GenerateCommunityManual, GenerateCommunityPost } from "../entity/generateCommunityEntity";

interface GenerateCommunityPostRs {
  page: number;
  result: GenerateCommunityPost[];
  size: number;
  totalSize: number;
}

class GenerateCommunityApiService {
  private readonly http;
  private readonly token;
  constructor(token?: string) {
    this.token = token;
    this.http = axios.create({
      baseURL: new URL('/api/v1', process.env.REACT_APP_COMMUNITY_API_BASE_URL).href,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getPostList(
    page: number,
    size: number = 10,
  ): Promise<AxiosResponse<GenerateCommunityPostRs>> {
    return this.http.get(`/dummy-post?page=${page}&size=${size}`);
  }

  getAskGPT(
    generateCommunityPostList: GenerateCommunityPost[],
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<{ result: GenerateCommunityPost[]; }>> {
    return this.http.post(`/dummy-post/ask-gpt`, generateCommunityPostList, { cancelToken });
  }

  postManual(
    generateCommunityManual: GenerateCommunityManual,
  ): Promise<AxiosResponse<{ result: GenerateCommunityPost[]; }>> {
    return this.http.post(`/dummy-post/manual`, [generateCommunityManual]);
  }

  getGPTPost(postId: number): Promise<AxiosResponse<GenerateCommunityPost>> {
    return this.http.get(`/dummy-post/gpt?dummyPostId=${postId}`);
  }

  private createAuthHeader() {
    const token = this.token || '';
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    }
    return {};
  }
}

export const generateCommunityApiService = new GenerateCommunityApiService();