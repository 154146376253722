const MailContentConvertor = {
  replaceKeywordMap: (keywords: Record<string, string>, content: string) => {
    let _content: string = content;
    Object.entries(keywords).forEach(([key, value]) => {
      _content = MailContentConvertor.replaceKeyword(_content, key, value as string);
    })
    return _content;
  },
  replaceKeyword: (content: string, keyword: string, value: string) => {
    return content?.replaceAll(`#{${keyword}}`, value);
  }
  
}

export default MailContentConvertor;
