import React, { createContext, useContext, useEffect, useState } from 'react';
import apiService from '../../service/api.service';
import SeoKeywordRankingLineChart from './SeoKeywordRankingLineChart';
import { Button, Col, DropdownButton, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import SeoKeywordSection, { RankingKeyword } from './SeoKeywordSection';
import { DatePicker } from '@mui/lab';

interface UrlForm {
  searchUrl: string;
}

interface SearchKeywordForm {
  searchText: string;
}

const SeoUrlReport = () => {
  const [autoKeywords, setAutoKeywords] = useState<string[]>([]);
  const [reportKeywords, setReportKeywords] = useState<RankingKeyword[]>([]);
  const {
    register,
    setValue,
    handleSubmit: urlHandleSubmit,
  } = useForm<UrlForm>({});

  const {
    register: register3,
    watch: watch3,
    setValue: setValue3,
  } = useForm<SearchKeywordForm>({});
  const [rangeReportKeywords, setRangeReportKeywords] = useState<
    RankingKeyword[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [rangeStart, setRangeStart] = useState<string>('');
  const [rangeEnd, setRangeEnd] = useState<string>('');

  useEffect(() => {
    updateRangeReportKeywords(
      rangeStart || '1970-01-01',
      rangeEnd || '2999-12-31',
    );
  }, [reportKeywords, rangeStart, rangeEnd]);

  async function fetchReport(url: string) {
    let replaceUrl = url.replaceAll('/', '*');

    const response = await apiService.getKeywordReport(replaceUrl);
    setReportKeywords(response.data.result);
    setAutoKeywords(
      (response.data.result as RankingKeyword[]).map(
        (keyword) => keyword.keyword,
      ),
    );
  }

  const onSubmitUrl = async (data: UrlForm) => {
    fetchReport(data.searchUrl);
  };

  const onClickKeywordHandler = (keyword: RankingKeyword) => {
    keyword.selected = !keyword.selected;
    setReportKeywords((rs) => [...rs]);
  };

  const updateRangeReportKeywords = (start: string, end: string) => {
    const result = reportKeywords
      .filter((keyword) => keyword.selected)
      .map((resultItem) => {
        return {
          keywordId: resultItem.keywordId,
          keyword: resultItem.keyword,
          history: resultItem.history.map((historyItem) => {
            return {
              domain: historyItem.domain,
              historyList: historyItem.historyList.filter((historyListItem) => {
                return (
                  start <= historyListItem.date && historyListItem.date <= end
                );
              }),
            };
          }),
        };
      });
    setRangeReportKeywords(result);
  };

  return (
    <>
      <Box position="relative">
        <Accordion
          defaultExpanded
          style={{ position: 'sticky', top: 0, zIndex: 1 }}
        >
          <AccordionSummary>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
            >
              <Typography>url 리포트</Typography>
              <Box
                display="flex"
                gap={1}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Box>
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    onChange={(e) => {
                      setRangeStart(e.currentTarget.value);
                    }}
                  />
                  <input
                    className="mx-3"
                    type="date"
                    id="end"
                    name="trip-end"
                    onChange={(e) => {
                      setRangeEnd(e.currentTarget.value);
                    }}
                  />
                </Box>
                <Form
                  onSubmit={urlHandleSubmit(onSubmitUrl)}
                  style={{ display: 'flex', gap: 8 }}
                >
                  <Form.Group controlId="formName">
                    <Form.Control
                      {...register('searchUrl', {
                        required: true,
                      })}
                      style={{ fontSize: '12px', width: 150 }}
                      placeholder="url"
                    />
                  </Form.Group>
                  <Button type="submit" variant="outline-primary" size="sm">
                    검색
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('searchUrl', '');
                      setReportKeywords([]);
                      setAutoKeywords([]);
                    }}
                    variant="outline-primary"
                    size="sm"
                  >
                    초기화
                  </Button>
                </Form>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container gap={1}>
              <Autocomplete
                options={autoKeywords}
                freeSolo
                onChange={(_, __, reason) => {
                  if (reason === 'clear') {
                    setSearchKeyword('');
                    setValue3('searchText', '');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    label="검색"
                    style={{ width: 200 }}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') return;
                      setSearchKeyword(watch3('searchText'));
                    }}
                    {...params}
                    {...register3('searchText')}
                    size="small"
                  />
                )}
              />

              <Button
                onClick={() => {
                  setSearchKeyword(watch3('searchText'));
                }}
              >
                검색
              </Button>
            </Grid>
            <Box maxHeight={120} overflow="auto" marginTop={1}>
              <Grid container gap={1}>
                {reportKeywords
                  .filter((keyword) =>
                    `${keyword.keyword}`
                      .toLocaleLowerCase()
                      .replaceAll(' ', '')
                      .includes(
                        searchKeyword.toLocaleLowerCase().replaceAll(' ', ''),
                      ),
                  )
                  .sort()
                  .map((keyword) => {
                    return (
                      <Chip
                        variant="outlined"
                        onClick={() => {
                          onClickKeywordHandler(keyword);
                        }}
                        label={keyword.keyword}
                        color={keyword.selected ? 'success' : undefined}
                      />
                    );
                  })}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box marginTop={1}>
          <Grid container gap={1} overflow="auto">
            {rangeReportKeywords.map((keyword) => {
              return (
                <Box key={keyword.keywordId}>
                  <hr />
                  {keyword.keyword}
                  <SeoKeywordRankingLineChart
                    key={keyword.keywordId * 12}
                    data={keyword}
                  />
                </Box>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SeoUrlReport;
