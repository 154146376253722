import { Grid } from '@mui/material';
import { SettlementCard } from './SettlementCard';
import { Section } from './Section';
import { useSettlementPeriod } from '../hooks/useSettlementPeriod';
import { useQuery } from '@tanstack/react-query';
import { settlementApiService } from '../../../service/settlementSevice';
import { SettlementSubRoutes } from '../routes';
import { eventParameterTypeBySubCategories } from '../../../entity/settlement';

interface SummarySectionProps {
  subCategory: string;
  subCategoryName: string;
}

export function SummarySection({
  subCategory,
  subCategoryName,
}: SummarySectionProps) {
  const { endDate, startDate } = useSettlementPeriod();

  const { data } = useQuery(
    ['settlement-event-rewards', endDate, startDate],
    () =>
      settlementApiService.getRewardsByEvent({
        endDate,
        startDate,
      }),
  );

  const currentCategoryNameData =
    data?.data.result?.filter((item: any) => {
      const { key } = eventParameterTypeBySubCategories[subCategory as SettlementSubRoutes];
      return key === item.eventName || key === item.eventType;
    }) ?? [];

  const paymentAmount = currentCategoryNameData.reduce(
    (prev: number, item: any) => prev + item['totalMoyoRewardValue'],
    0,
  );
  const paymentCount = currentCategoryNameData.reduce(
    (prev: number, item: any) => prev + item['totalCount'],
    0,
  );

  return (
    <Section>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SettlementCard
            title={`${subCategoryName} 총 지급 금액`}
            amount={paymentAmount}
          />
        </Grid>
        <Grid item xs={6}>
          <SettlementCard
            title={`${subCategoryName} 총 개통 건수`}
            description="정산 건수 = 개통 건수"
            amount={paymentCount}
            unit="건"
          />
        </Grid>
      </Grid>
    </Section>
  );
}
