import { forwardRef, useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import DetailRow from '../../component/DetailRow';
import apiService from '../../service/api.service';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EventDetailGift from './EventDetailGift';
import AddDetailsPopup from './AddDetailsPopup';
import MaterialTable from 'material-table';
import Spinner from '../../component/Spinner';
import EventDetailPlan from './EventDetailPlan';
import EventCopyModal from './EventCopyModal';
import { EventExposedArea } from '../../common/constants';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function EventDetail() {
  const detailColumns2 = [
    {
      title: '노출순서',
      field: 'order',
      width: '10%',
      cellStyle: {
        whiteSpace: 'nowrap',
        cellWidth: '15%',
      },
    },
    {
      title: 'id',
      field: 'id',
      editable: 'never',
      width: '10%',
      cellStyle: {
        whiteSpace: 'nowrap',
        cellWidth: '15%',
      },
    },
    {
      title: '상세설명',
      field: 'description',
    },
    {
      title: '카테고리',
      field: 'category',
      lookup: {
        emart: '이마트',
        naver: 'naver',
        ssg: '신세계',
        gs: 'GS',
        youtube: '유튜브',
        cu: 'cu',
        genie: '지니',
        starbucks: '스타벅스',
        homeplus: '홈플러스',
        melon: '멜론',
        gift: '기타',
      },
    },
    {
      title: '받는 조건',
      field: 'condition',
    },
    {
      title: '수령 일자',
      field: 'receiptDate',
    },
    {
      title: '링크 사용',
      field: 'useLink',
      type: 'boolean',
    },
  ];

  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [details, setDetails] = useState([]);
  const [type, setType] = useState('gift');
  const [isLoading, setIsLoading] = useState(false);
  const [eventPlans, setEventPlans] = useState([]);
  const [showEventCopyModal, setShowEventCopyModal] = useState(false);
  const fetchEvent = async () => {
    const response = await apiService.getEvent(eventId);

    if (response && response.data) {
      setEvent(response.data);

      if (response.data.details.length > 0) {
        if (response.data.details[0].type === 'plan') {
          setEventPlans(response.data.details[0].plans);
          setType('plan');
        } else {
          setDetails(response.data.details);
        }
      }
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  const [showAddDetailsPopup, setShowAddDetailsPopup] = useState(false);

  const openAddDetailsPopup = () => {
    setShowAddDetailsPopup(true);
  };

  const closeAddDetailsPopup = () => {
    setShowAddDetailsPopup(false);
  };

  const onDetailsSaved = () => {
    fetchEvent();
    closeAddDetailsPopup();
  };

  const addDetailsButton = (
    <Button key="addDetails" size="sm" onClick={openAddDetailsPopup}>
      사은품 추가
    </Button>
  );

  const editDetail = async (detail) => {
    console.log(detail);
    try {
      const response = await apiService.editEventDetail(event.id, detail);
      if (response.data.result) {
        alert('수정되었습니다');
        // fetchEvent();
      }
    } catch (err) {
      console.debug(err);
    }
  };

  const removeDetail = async (id) => {
    try {
      const response = await apiService.removeEventDetail(event.id, id);
      if (response.data.result) {
        alert('삭제되었습니다');
        fetchEvent();
      }
    } catch (err) {
      console.debug(err);
    }
  };

  async function deleteEventCache() {
    if (window.confirm('적용하시겠습니까? ')) {
      try {
        const response = await apiService.removeEventCache();
      } catch (err) {
        console.debug(err);
      }
    }
  }

  const deleteEvent = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      try {
        const response = await apiService.deleteEvent(event.id);
        if (response.data.result) {
          alert('삭제되었습니다');
          navigate('/events');
        }
      } catch (err) {
        console.debug(err);
      }
    }
  };

  if (!event) return null;

  function setTypeValue(e) {
    setType(e.target.value);
  }

  async function getDiscountSpecificPlans() {
    setIsLoading(true);
    const response = await apiService.getEventPlanList(event.id);

    console.log(response.data.result);
    setEventPlans(response.data.result);
    setIsLoading(false);
  }

  async function onCopyEventHandler(_, rowData) {
    console.log(rowData);
    if (!window.confirm('복사하시겠습니까?')) return;
    setIsLoading(true);
    const param = {
      description: `${rowData.description} (복사)`,
      condition: rowData.condition,
      category: rowData.category,
      receiptDate: rowData.receiptDate,
      order: rowData.order,
      plans: rowData.plans,
    };
    try {
      const response = await apiService.addEventDetail(event.id, param);
      if (response.data.result) {
        await apiService.saveEventPlansRelation(
          response.data.result.id,
          rowData.plans.map((plan) => plan.id),
        );
        onDetailsSaved();
      }
    } catch (err) {
      console.debug(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="container-md mt-3 pb-5">
        <Container className="w-75">
          <div className="mb-3">
            <div>
              <h3>이벤트 상세</h3>
              <div style={{ display: 'inline' }}>
                <Button
                  className="me-2"
                  variant="outline-primary"
                  onClick={() => setShowEventCopyModal(true)}
                >
                  이벤트 상품 가져오기
                </Button>
                <Link
                  to={`/events/${event.id}/edit`}
                  className="text-secondary me-2"
                  style={{ textDecoration: 'none' }}
                >
                  <Button variant="outline-secondary">편집</Button>
                </Link>
                <Button variant="outline-danger" onClick={deleteEvent}>
                  삭제
                </Button>
              </div>
            </div>
            <hr />
            <DetailRow label="id" data={event.id} />
            <DetailRow label="제목" data={event.title} />
            <DetailRow label="MVNO" data={event.mvno} />
            <DetailRow
              label="노출영역"
              data={EventExposedArea[event.exposedArea]?.name}
            />
            <DetailRow label="링크">
              <a
                href={event.link}
                target="_blank"
                style={{ wordWrap: 'break-word' }}
              >
                {event.link}
              </a>
            </DetailRow>
            <DetailRow label="기간" data={event.duration} />
            <DetailRow label="이미지">
              <img src={event.thumbnailImageUrl} alt="" />
            </DetailRow>
            {(
              <DetailRow label="수동 등록 이미지(우선노출)">
                <img src={event.manualThumbnailImageUrl} alt="이미지" />
              </DetailRow>
            ) && event.manualThumbnailImageUrl}
          </div>

          <div className="d-inline-block mb-5">
            <Form.Select onChange={setTypeValue}>
              <option value={'gift'} selected={type === 'gift'}>
                사은품
              </option>
              <option value={'plan'} selected={type === 'plan'}>
                요금제
              </option>
            </Form.Select>
          </div>

          {type === 'gift' && (
            <>
              {/* 사은품 상세 추가 */}
              <AddDetailsPopup
                show={true}
                onHide={closeAddDetailsPopup}
                event={event}
                onSaved={onDetailsSaved}
              />
              {/* 사은품 상세 테이블 */}
              <MaterialTable
                icons={tableIcons}
                columns={detailColumns2}
                data={details}
                title={''}
                localization={{
                  header: {
                    actions: (
                      <>
                        <span style={{ width: 48 }}>요금제 복제</span>
                        <span style={{ width: 48 }}>수정</span>
                        <span style={{ width: 48 }}>삭제</span>
                      </>
                    ),
                  },
                }}
                options={{
                  showTitle: false,
                  search: false,
                  tableLayout: ' auto',
                  actionsColumnIndex: -1,
                  sorting: true,
                }}
                actions={[
                  {
                    icon: 'library_add',
                    tooltip: 'Duplicate Plan',
                    onClick: onCopyEventHandler,
                  },
                ]}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...details];
                        const index = oldData.tableData.id;
                        if (!window.confirm('수정하시겠습니까?')) {
                          resolve();
                          return;
                        }

                        editDetail({
                          id: newData.id,
                          description: newData.description,
                          order: newData.order,
                          category: newData.category,
                          condition: newData.condition,
                          receiptDate: newData.receiptDate,
                          useLink: newData.useLink,
                        });

                        dataUpdate[index] = newData;
                        setDetails([...dataUpdate]);
                        resolve();
                      }, 500);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...details];
                        const index = oldData.tableData.id;
                        removeDetail(oldData.id).then((r) => {
                          dataDelete.splice(index, 1);
                          setDetails([...dataDelete]);
                        });
                        resolve();
                      }, 1000);
                    }),
                }}
              />
              <hr />
              {/* 사은품 별 요금제 테이블*/}
              <h4>사은품 별 요금제 설정</h4>
              {details.map((detail) => (
                <EventDetailGift detail={detail} mvno={event.mvno} />
              ))}
              <h4 className={'mt-3 text-center'}>사은품 적용</h4>
              <div className={'pb-5 text-center'}>
                <p>
                  해당 버튼을 누르면 해당 이벤트가 바로 적용됩니다. ( 캐시 삭제
                  )
                </p>
                <button onClick={deleteEventCache}>적용(캐시 삭제)</button>
              </div>
            </>
          )}

          {type === 'plan' && (
            <>
              <h4>할인기간 있는 요금제 설정</h4>
              <button onClick={getDiscountSpecificPlans}>
                요금제 가져오기
              </button>
              <EventDetailPlan eventPlans={eventPlans} eventId={event.id} />
            </>
          )}
        </Container>
      </div>
      {showEventCopyModal && (
        <EventCopyModal
          show={showEventCopyModal}
          onHide={() => setShowEventCopyModal(false)}
          eventId={event.id}
        />
      )}
      {showAddDetailsPopup && (
        <AddDetailsPopup
          show={showAddDetailsPopup}
          onHide={closeAddDetailsPopup}
          event={event}
          onSaved={onDetailsSaved}
        />
      )}
      <Spinner isShow={isLoading} />
    </>
  );
}
