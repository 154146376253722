enum EventStatus {
  ALL = 'ALL',
  PROCEED = 'PROCEED',
  STANDBY = 'STANDBY',
  CLOSED = 'CLOSED',
};

const EventStatusValue = {
  PROCEED: '진행중',
  CLOSED: '종료됨',
  STANDBY: '진행대기',
  ALL: '전체'
}

export {
  EventStatusValue
}

export default EventStatus;
